/*
*
* Autosize
*
* Autosize plugin styles.
*
*/

#chatInput {
  max-height: 80px;
}
