@media only screen and (max-width: 1279px){
    .navbarhead {
        padding: 20px 0px;
    }
    .bgcol {
        padding: 30px !important;
        margin: 0;
    }
}
@media (min-width: 1023px) and (max-width: 1080px){
    .list-color-bg h4{
        font-size: 19px;
    }
    .book-icon-1 {
        left: 76% !important;
    }
    .book-icon-2 {
        left: 83% !important;
    }
    .book-icon-3 {
        left: 76% !important;
    }
    .seemore-btn {
        right: 28% !important;
    }


    .card-ads .ad-2 {
        padding: 33px 20px;
    }
    .card-ads .ad-1 {
        padding: 20px;
    }
    .ad-1 h3 {
        font-size: 20px;
    }
    .ad-1 p {
        color: white;
        padding: 15px 0;
    }
    .carousel-content{
        left: -3px;
    }
}
@media (min-width: 768px) and (max-width: 991px){
    .column-reverse{
        flex-direction: column-reverse;
        width: 100%;
    }
    .column-reverse .col-md-6{
        width: 100%;
    }
    .navbarhead h1 {
        font-size: 50px !important;
    }
    .service-list-sec .col-md-3{
        width: 50%;
    }
    .service-list-card{
        margin-bottom: 55px;
    }
    .seemore-btn {
        right: 52%;
    }
    .search_area h1{
        line-height: 65px;
    }
    .bgcol {
        padding: 30px !important;
        margin: 0;
    }



    .side-menu{
        display: table;
        margin: 0 0 0 auto;
    }
    .side-menu svg{
        text-align: right;
    }
    .navbar-list{
        display: none;
    }
    .bus-btn{
        display: none;
    }
    .menu-dropdown{
        min-width: 240px;
    }
    .soon{
        margin-top: 0;
    }
    .search_section{
        margin-top: 30px;
    }
    .search_section .search_area .search_form {
        width: 75%;
    }
    .card-ads{
        display: flex;
        margin: 30px 0;
    }
    .card-ads .ad-1{
        width: 50%;
        margin-right: 20px;
        margin-top: 0;
    }
    .card-ads .ad-2{
        width: 50%;
        margin-top: 0;
    }
    .categories_section .category .list {
        width: 20%;
    }
    .ad-2 .free {
        bottom: 26%;
        right: 28%;
    }
    .book-icon-1{
        left: 36%;
    }
    .carousel-img img {
        width: 100%;
    }
    .carousel-content{
        width: 100%;
        left: 0;
    }
    .gap{
        width: 50%;
    }
}
@media only screen and (max-width:767px){
    .navbar-list{
        display: none;
    }
    .menuicon{
        display: block;
        display: table;
        margin: 0 0 0 auto;
    }
    .menu{
        display: block;
    }
    .header-width-responsive{
        width: 50%;
    }
    .footer-h1 h1{
        text-align: center;
      }
    .footer-li {
        display: table;
        margin: 0 auto;
    }
    .footer-sec .footer-li ul li{
        display: block !important;
    }
    .profile-listing-card{
        margin-bottom: 20px;
    }
    .margin-bottom{
        margin-bottom: 20px;
      }
    .width-10{
        width: 12%;
    }
    .search_area h1 {
        font-size: 36px !important;
        line-height: 45px;
    }
    .categories_section .category .list {
        width: 33%;
    }
    .category{
        margin-top: 40px;
    }
    .navbar-list {
        margin: 0 25px 0 auto !important;
    }
    .gradeint-color{
        margin-bottom: 15px;
    }
    .gradeint-color-2{
        margin-bottom: 15px;
    }
    .service-list-card {
        width: 100% !important;
    }
    .service-list-card{
        margin-bottom: 80px;
    }
    .nmargin{
        margin-bottom: 0 !important;
    }
    .search_section {
        padding: 40px 0px;
    }
    .book-icon-1 {
        left: 35%;
    }
    .book-icon-2 {
        left: 26% !important;
    }
    .book-icon-3 {
        left: 24% !important;
    }
    .seemore-btn {
        bottom: 21.9% !important;
        right: 56% !important;
    }
    .column-reverse{
        flex-direction: column-reverse;
    }
    .categories_section .category .list .cat_info h3{
        margin: 0;
        line-height: 25px;
    }
    .service-img{
        width: 100%;
    }
    .details-card-padding {
        padding: 0 18px 0 18px !important;
    }
    .button-bg {
        padding: 8.5px 18px 8.5px 18px !important;
    }
    .button-bg Button {
        margin-right: 15px !important;
    }
    .listing-search-filter .nice-select {
        margin-left: 0 !important;
    }
    .filter-nav{
        text-align: left;
    }
    .pb-90 {
        padding-bottom: 50px !important;
    }
    .pt-120 {
        padding-top: 50px !important;
    }
    .gallery{
        display: block !important;
    }
    .left-banner{
        width: 100% !important;
    }
    .left-banner img{
        width: 100% !important;
    }
    .right-banner{
        width: 100% !important;
    }
    .right-banner img{
        width: 100% !important;
    }
    .ti-upload {
        top: 360% !important;
        right: 41% !important;
    }
    .gallery h3 {
        top: 370% !important;
        right: 26.5% !important;
    }
    .ti-gallery {
        top: 332% !important;
        right: 40% !important;
    }
    .gallery-name h3 {
        top: 341% !important;
        right: 34% !important;
    }
    .listing-play-box .width {
        width: 50% !important;
    }
    .listing-gallery-box .image {
        width: 75px !important;
        height: 75px !important;
    }
    .releted-listing-area .slick-track{
        width: 100% !important;
    }
    .releted-listing-area .slick-slide{
        width: 100% !important;
    }
    .bgcol {
        padding: 30px !important;
        margin: 0;
    }
    .widres{
        width: 85%;
    }
    .add-listing-form.timing-listing-form .timeing-list {
        padding: 15px !important;
    }



    .bus-btn{
        display: none;
    }
    .search_form{
        width: 100% !important;
        margin: 20px 0 !important;
    }
    .download{
        text-align-last: center;
    }
    .soon{
        margin-top: 0;
    }
    .categories_section{
        padding: 0;
    }
    .ad-2 .free {
        bottom: 15%;
        right: 34%;
    }
    .box{
        display: block !important;
    }
    .service-list-sec {
        padding-bottom: 0;
        padding: 40px 0 0 0;
    }
    .carousel-img img{
        width: 100%;
    }
    .carousel-content{
        width: 100%;
        left: 0;
    }
    .side-menu{
        display: block;
    }
    .rw-img{
        width: 50%;
    }
    .rw-bar{
        width: 50%;
        justify-content: right;
        display: flex;
        align-items: center;
    }
}
@media (min-width: 340px) and (max-width: 740px){
    .react-tabs__tab-list{
        width: 102%;
    }
}