@media only screen and (max-width: 1279px){
    .navbarhead {
        padding: 20px 0px;
    }
    .bgcol {
        padding: 30px !important;
        margin: 0;
    }
}
@media (min-width: 1023px) and (max-width: 1080px){
    .list-color-bg h4{
        font-size: 19px;
    }
    .seemore-btn {
        right: 28% !important;
    }


    .ad-1 h3 {
        font-size: 20px;
    }
    .ad-1 p {
        color: white;
        padding: 15px 0;
    }
    .carousel-content{
        left: -3px;
    }
    .rtl .card-ads .ad-1 {
        padding: 44px 20px;
    }
    .rtl .card-ads .ad-2 {
        padding: 35px 20px;
    }
    .rtl .book-icon-1{
        right: 50%;
    }
    .rtl .carousel-content {
        left: 0;
        right: 8px;
        width: 200px;
    }
}
@media (min-width: 768px) and (max-width: 991px){
    .column-reverse{
        flex-direction: column-reverse;
        width: 100%;
    }
    .column-reverse .col-md-6{
        width: 100%;
    }
    .navbarhead h1 {
        font-size: 50px !important;
    }
    .service-list-sec .col-md-3{
        width: 50%;
    }
    .service-list-card{
        margin-bottom: 55px;
    }
    .seemore-btn {
        right: 52%;
    }
    .search_area h1{
        line-height: 65px;
    }
    .bgcol {
        padding: 30px !important;
        margin: 0;
    }
    .navbar-list{
        display: none;
    }
    .bus-btn{
        display: none;
    }
    .rtl .menu-dropdown{
        min-width: 200px;
        width: 55%;
        text-align: right;
        left: 0;
        right: auto;
    }
    .soon{
        margin-top: 0;
    }
    .search_section{
        margin-top: 30px;
    }
    .search_section .search_area .search_form {
        width: 75%;
    }
    .card-ads{
        display: flex;
        margin: 30px 0;
    }
    .rtl .card-ads .ad-1{
        width: 50%;
        margin-top: 0;
    }
    .rtl .card-ads .ad-2{
        width: 50%;
        margin-right: 20px;
        margin-top: 0;
    }
    .categories_section .category .list {
        width: 20%;
    }
    .ad-2 .free {
        bottom: 26%;
        right: 28%;
    }
    .rtl .carousel-img img {
        width: 100%;
    }
    .rtl .carousel-content{
        width: 100%;
        left: 0;
    }
    .gap{
        width: 50%;
    }
    .rtl .side-menu{
        margin: 0;
        display: block;
        text-align: left;
    }
    .rtl .book-icon-1 {
        right: 25%;
    }
}
@media only screen and (max-width:767px){
    .rtl .rw-bar {
        justify-content: left;
    }
    .rtl .menu-dropdown {
        left: 0;
        right: auto;
    }
    .rtl .book-icon-1 {
        right: 21%;
    }
    .rtl .carousel-content {
        left: 0px;
        right: 20px;
    }
    .rtl .carousel-content{
        width: 90%;
    }
}