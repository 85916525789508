/*
*
* Texts
*
* Bootstrap text styles.
*
*/

.text-primary {
  color: var(--primary) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.text-tertiary {
  color: var(--tertiary) !important;
}

.text-quaternary {
  color: var(--quaternary) !important;
}

.text-success {
  color: var(--success) !important;
}

.text-danger {
  color: var(--danger) !important;
}

.text-warning {
  color: var(--warning) !important;
}

.text-info {
  color: var(--info) !important;
}

.text-light {
  color: var(--light) !important;
}

.text-dark {
  color: var(--dark) !important;
}

.text-body {
  color: var(--body) !important;
}

.text-alternate {
  color: var(--alternate) !important;
}

.text-muted {
  color: var(--muted) !important;
}

.text-white {
  color: var(--light-text) !important;
}

.text-black {
  color: var(--dark-text) !important;
}

.text-separator {
  color: var(--separator) !important;
}

.text-separator-light {
  color: var(--separator-light) !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}
