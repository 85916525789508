/*
*
* Steps
*
* Styles for steps ui elements.
*
*/

.line-w-1 {
  width: 1px;
}

.line-h-1 {
  height: 1px;
}
