/*
*
* Glide
*
* Glide carousel plugin styles.
*
*/

.glide__track {
  .glide__slide {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

.gx-2 .glide__track {
  .glide__slide {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
}

.glide__bullets {
  display: inline-block;
  position: initial;
  text-align: center;
  transform: initial;
  left: initial;
  margin-left: 0.75rem;
  margin-right: 0.75rem;

  .glide__bullet {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    background: var(--separator);
    outline: initial !important;
    border: initial;
    margin: 0 3px;
    padding: 0;
    cursor: pointer;
    box-shadow: initial;

    &.glide__bullet:hover,
    &.glide__bullet:focus {
      background: var(--separator);
      border: initial;
    }

    &.glide__bullet--active {
      background: var(--primary) !important;
    }
  }
}

.glide-thumb,
.glide-large {
  .glide__slide {
    cursor: pointer;
  }
}

.glide-thumb {
  li {
    text-align: center;
    opacity: 0.5;
    object-fit: cover;
    &.active,
    &:hover {
      opacity: 1;
    }

    img {
      width: 60px;
      margin-bottom: 0;
    }
  }

  margin: 0 auto;

  .glide__arrows {
    .btn {
      position: absolute;
      top: 5px;

      &.left-arrow {
        left: -50px;
      }

      &.right-arrow {
        right: -50px;
      }
    }
  }
}
