/*
*
* Print
*
* Print styles.
*
*/

@media print {
  * {
    transition: initial !important;
  }

  body,
  html,
  main {
    padding: 0 !important;
    margin: 0 !important;
  }

  .settings-buttons-container,
  #settingsButton,
  #nichesButton,
  #purchaseButton,
  #nav,
  footer {
    display: none !important;
  }

  a {
    text-decoration: initial !important;
  }

  .card.card-print {
    margin: initial !important;
    box-shadow: initial !important;
    .card-body {
      padding: 0 !important;
    }
  }

  main.print-restricted .container {
    padding-right: 0 !important;
    padding-left: 0 !important;
    & > *:not(.print-me) {
      display: none;
    }
  }

  html[data-placement="horizontal"],
  html[data-placement="vertical"] {
    main {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}
