@charset "UTF-8";
/*

Acorn - Bootstrap 5 Html Laravel 8 .Net 5 Admin Template

Table of Contents
|
+-- utils
|   |
|   \-- mixins
|   \-- sizes
|   \-- positions
|   \-- shared
|   
+-- themes
|   |
|   \-- rest
|   \-- dark.blue
|   \-- dark.green
|   \-- dark.red
|   \-- dark.purple
|   \-- dark.pink
|   \-- light.blue
|   \-- light.green
|   \-- light.red
|   \-- light.purple
|   \-- light.pink
|   
+-- bootstrap
|   |
|   \-- accordion
|   \-- alert
|   \-- background
|   \-- badge
|   \-- border
|   \-- button
|   \-- card
|   \-- dropdown
|   \-- form
|   \-- grid
|   \-- inputgroup
|   \-- modal
|   \-- nav
|   \-- offcanvas
|   \-- progress
|   \-- popover
|   \-- spinner
|   \-- texts
|   \-- tables
|   \-- toast
|   \-- tooltip
|   
+-- plugins
|   |
|   \-- autosize
|   \-- autosuggest
|   \-- calendar
|   \-- contextmenu
|   \-- datatable
|   \-- datepicker
|   \-- dropzone
|   \-- editor
|   \-- glide
|   \-- inputspinner
|   \-- lightbox
|   \-- list
|   \-- player
|   \-- notification
|   \-- progressbar
|   \-- rating
|   \-- scrollbar
|   \-- search
|   \-- select
|   \-- slider
|   \-- steps
|   \-- tags
|   \-- tour
|   \-- validation
|   \-- wizard
|   
+-- layout
    |
    \-- base
    \-- typography
    \-- main
    \-- nav.primary
    \-- nav.side
    \-- footer
    \-- print
    \-- settings

*/
/*
*
* Mixins
*
* Root breakpoints and Sass functions.
*
*/
:root {
  --sm: 576px;
  --md: 768px;
  --lg: 992px;
  --xl: 1200px;
  --xxl: 1400px;
}

/*
*
* Sizes
*
* Static width and height utils.
*
*/
.h-100-card {
  height: calc(100% - var(--small-title-height)) !important;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.w-75 {
  width: 75% !important;
}

.w-70 {
  width: 70% !important;
}

.w-60 {
  width: 60% !important;
}

.w-50 {
  width: 50% !important;
}

.w-40 {
  width: 40% !important;
}

.w-33 {
  width: 33.3% !important;
}

.w-30 {
  width: 30% !important;
}

.w-25 {
  width: 25% !important;
}

.w-20 {
  width: 20% !important;
}

.w-10 {
  width: 10% !important;
}

.h-auto {
  height: auto !important;
}

.w-auto {
  width: auto !important;
}

.sh-0 {
  height: 0 !important;
}

.sw-0 {
  width: 0 !important;
}

.sh-1 {
  height: 0.5rem !important;
}

.sw-1 {
  width: 0.5rem !important;
}

.sh-2 {
  height: 1rem !important;
}

.sw-2 {
  width: 1rem !important;
}

.sh-3 {
  height: 1.5rem !important;
}

.sw-3 {
  width: 1.5rem !important;
}

.sh-4 {
  height: 2rem !important;
}

.sw-4 {
  width: 2rem !important;
}

.sh-5 {
  height: 2.5rem !important;
}

.sw-5 {
  width: 2.5rem !important;
}

.sh-6 {
  height: 3rem !important;
}

.sw-6 {
  width: 3rem !important;
}

.sh-7 {
  height: 3.5rem !important;
}

.sw-7 {
  width: 3.5rem !important;
}

.sh-8 {
  height: 4rem !important;
}

.sw-8 {
  width: 4rem !important;
}

.sh-9 {
  height: 4.5rem !important;
}

.sw-9 {
  width: 4.5rem !important;
}

.sh-10 {
  height: 5rem !important;
}

.sw-10 {
  width: 5rem !important;
}

.sh-11 {
  height: 5.5rem !important;
}

.sw-11 {
  width: 5.5rem !important;
}

.sh-12 {
  height: 6rem !important;
}

.sw-12 {
  width: 6rem !important;
}

.sh-13 {
  height: 6.5rem !important;
}

.sw-13 {
  width: 6.5rem !important;
}

.sh-14 {
  height: 7rem !important;
}

.sw-14 {
  width: 7rem !important;
}

.sh-15 {
  height: 7.5rem !important;
}

.sw-15 {
  width: 7.5rem !important;
}

.sh-16 {
  height: 8rem !important;
}

.sw-16 {
  width: 8rem !important;
}

.sh-17 {
  height: 8.5rem !important;
}

.sw-17 {
  width: 8.5rem !important;
}

.sh-18 {
  height: 9rem !important;
}

.sw-18 {
  width: 9rem !important;
}

.sh-19 {
  height: 9.5rem !important;
}

.sw-19 {
  width: 9.5rem !important;
}

.sh-20 {
  height: 10rem !important;
}

.sw-20 {
  width: 10rem !important;
}

.sh-21 {
  height: 10.5rem !important;
}

.sw-21 {
  width: 10.5rem !important;
}

.sh-22 {
  height: 11rem !important;
}

.sw-22 {
  width: 11rem !important;
}

.sh-23 {
  height: 11.5rem !important;
}

.sw-23 {
  width: 11.5rem !important;
}

.sh-24 {
  height: 12rem !important;
}

.sw-24 {
  width: 12rem !important;
}

.sh-25 {
  height: 12.5rem !important;
}

.sw-25 {
  width: 12.5rem !important;
}

.sh-30 {
  height: 15rem !important;
}

.sw-30 {
  width: 15rem !important;
}

.sh-35 {
  height: 17.5rem !important;
}

.sw-35 {
  width: 17.5rem !important;
}

.sh-40 {
  height: 20rem !important;
}

.sw-40 {
  width: 20rem !important;
}

.sh-45 {
  height: 22.5rem !important;
}

.sw-45 {
  width: 22.5rem !important;
}

.sh-50 {
  height: 25rem !important;
}

.sw-50 {
  width: 25rem !important;
}

.sh-60 {
  height: 30rem !important;
}

.sw-60 {
  width: 30rem !important;
}

.sh-70 {
  height: 35rem !important;
}

.sw-70 {
  width: 35rem !important;
}

.sh-80 {
  height: 40rem !important;
}

.sw-80 {
  width: 40rem !important;
}

@media (min-width: 576px) {
  .sh-sm-0 {
    height: 0 !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-0 {
    width: 0 !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-1 {
    height: 0.5rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-1 {
    width: 0.5rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-2 {
    height: 1rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-2 {
    width: 1rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-3 {
    height: 1.5rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-3 {
    width: 1.5rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-4 {
    height: 2rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-4 {
    width: 2rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-5 {
    height: 2.5rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-5 {
    width: 2.5rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-6 {
    height: 3rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-6 {
    width: 3rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-7 {
    height: 3.5rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-7 {
    width: 3.5rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-8 {
    height: 4rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-8 {
    width: 4rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-9 {
    height: 4.5rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-9 {
    width: 4.5rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-10 {
    height: 5rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-10 {
    width: 5rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-11 {
    height: 5.5rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-11 {
    width: 5.5rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-12 {
    height: 6rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-12 {
    width: 6rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-13 {
    height: 6.5rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-13 {
    width: 6.5rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-14 {
    height: 7rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-14 {
    width: 7rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-15 {
    height: 7.5rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-15 {
    width: 7.5rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-16 {
    height: 8rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-16 {
    width: 8rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-17 {
    height: 8.5rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-17 {
    width: 8.5rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-18 {
    height: 9rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-18 {
    width: 9rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-19 {
    height: 9.5rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-19 {
    width: 9.5rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-20 {
    height: 10rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-20 {
    width: 10rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-21 {
    height: 10.5rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-21 {
    width: 10.5rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-22 {
    height: 11rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-22 {
    width: 11rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-23 {
    height: 11.5rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-23 {
    width: 11.5rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-24 {
    height: 12rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-24 {
    width: 12rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-25 {
    height: 12.5rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-25 {
    width: 12.5rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-30 {
    height: 15rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-30 {
    width: 15rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-35 {
    height: 17.5rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-35 {
    width: 17.5rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-40 {
    height: 20rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-40 {
    width: 20rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-45 {
    height: 22.5rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-45 {
    width: 22.5rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-50 {
    height: 25rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-50 {
    width: 25rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-60 {
    height: 30rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-60 {
    width: 30rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-70 {
    height: 35rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-70 {
    width: 35rem !important;
  }
}

@media (min-width: 576px) {
  .sh-sm-80 {
    height: 40rem !important;
  }
}

@media (min-width: 576px) {
  .sw-sm-80 {
    width: 40rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-0 {
    height: 0 !important;
  }
}

@media (min-width: 768px) {
  .sw-md-0 {
    width: 0 !important;
  }
}

@media (min-width: 768px) {
  .sh-md-1 {
    height: 0.5rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-1 {
    width: 0.5rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-2 {
    height: 1rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-2 {
    width: 1rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-3 {
    height: 1.5rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-3 {
    width: 1.5rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-4 {
    height: 2rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-4 {
    width: 2rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-5 {
    height: 2.5rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-5 {
    width: 2.5rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-6 {
    height: 3rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-6 {
    width: 3rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-7 {
    height: 3.5rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-7 {
    width: 3.5rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-8 {
    height: 4rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-8 {
    width: 4rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-9 {
    height: 4.5rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-9 {
    width: 4.5rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-10 {
    height: 5rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-10 {
    width: 5rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-11 {
    height: 5.5rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-11 {
    width: 5.5rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-12 {
    height: 6rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-12 {
    width: 6rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-13 {
    height: 6.5rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-13 {
    width: 6.5rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-14 {
    height: 7rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-14 {
    width: 7rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-15 {
    height: 7.5rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-15 {
    width: 7.5rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-16 {
    height: 8rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-16 {
    width: 8rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-17 {
    height: 8.5rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-17 {
    width: 8.5rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-18 {
    height: 9rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-18 {
    width: 9rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-19 {
    height: 9.5rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-19 {
    width: 9.5rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-20 {
    height: 10rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-20 {
    width: 10rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-21 {
    height: 10.5rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-21 {
    width: 10.5rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-22 {
    height: 11rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-22 {
    width: 11rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-23 {
    height: 11.5rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-23 {
    width: 11.5rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-24 {
    height: 12rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-24 {
    width: 12rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-25 {
    height: 12.5rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-25 {
    width: 12.5rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-30 {
    height: 15rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-30 {
    width: 15rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-35 {
    height: 17.5rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-35 {
    width: 17.5rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-40 {
    height: 20rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-40 {
    width: 20rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-45 {
    height: 22.5rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-45 {
    width: 22.5rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-50 {
    height: 25rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-50 {
    width: 25rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-60 {
    height: 30rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-60 {
    width: 30rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-70 {
    height: 35rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-70 {
    width: 35rem !important;
  }
}

@media (min-width: 768px) {
  .sh-md-80 {
    height: 40rem !important;
  }
}

@media (min-width: 768px) {
  .sw-md-80 {
    width: 40rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-0 {
    height: 0 !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-0 {
    width: 0 !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-1 {
    height: 0.5rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-1 {
    width: 0.5rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-2 {
    height: 1rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-2 {
    width: 1rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-3 {
    height: 1.5rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-3 {
    width: 1.5rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-4 {
    height: 2rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-4 {
    width: 2rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-5 {
    height: 2.5rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-5 {
    width: 2.5rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-6 {
    height: 3rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-6 {
    width: 3rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-7 {
    height: 3.5rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-7 {
    width: 3.5rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-8 {
    height: 4rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-8 {
    width: 4rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-9 {
    height: 4.5rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-9 {
    width: 4.5rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-10 {
    height: 5rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-10 {
    width: 5rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-11 {
    height: 5.5rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-11 {
    width: 5.5rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-12 {
    height: 6rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-12 {
    width: 6rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-13 {
    height: 6.5rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-13 {
    width: 6.5rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-14 {
    height: 7rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-14 {
    width: 7rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-15 {
    height: 7.5rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-15 {
    width: 7.5rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-16 {
    height: 8rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-16 {
    width: 8rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-17 {
    height: 8.5rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-17 {
    width: 8.5rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-18 {
    height: 9rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-18 {
    width: 9rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-19 {
    height: 9.5rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-19 {
    width: 9.5rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-20 {
    height: 10rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-20 {
    width: 10rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-21 {
    height: 10.5rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-21 {
    width: 10.5rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-22 {
    height: 11rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-22 {
    width: 11rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-23 {
    height: 11.5rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-23 {
    width: 11.5rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-24 {
    height: 12rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-24 {
    width: 12rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-25 {
    height: 12.5rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-25 {
    width: 12.5rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-30 {
    height: 15rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-30 {
    width: 15rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-35 {
    height: 17.5rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-35 {
    width: 17.5rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-40 {
    height: 20rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-40 {
    width: 20rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-45 {
    height: 22.5rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-45 {
    width: 22.5rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-50 {
    height: 25rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-50 {
    width: 25rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-60 {
    height: 30rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-60 {
    width: 30rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-70 {
    height: 35rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-70 {
    width: 35rem !important;
  }
}

@media (min-width: 992px) {
  .sh-lg-80 {
    height: 40rem !important;
  }
}

@media (min-width: 992px) {
  .sw-lg-80 {
    width: 40rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-0 {
    height: 0 !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-0 {
    width: 0 !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-1 {
    height: 0.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-1 {
    width: 0.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-2 {
    height: 1rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-2 {
    width: 1rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-3 {
    height: 1.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-3 {
    width: 1.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-4 {
    height: 2rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-4 {
    width: 2rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-5 {
    height: 2.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-5 {
    width: 2.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-6 {
    height: 3rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-6 {
    width: 3rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-7 {
    height: 3.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-7 {
    width: 3.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-8 {
    height: 4rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-8 {
    width: 4rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-9 {
    height: 4.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-9 {
    width: 4.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-10 {
    height: 5rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-10 {
    width: 5rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-11 {
    height: 5.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-11 {
    width: 5.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-12 {
    height: 6rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-12 {
    width: 6rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-13 {
    height: 6.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-13 {
    width: 6.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-14 {
    height: 7rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-14 {
    width: 7rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-15 {
    height: 7.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-15 {
    width: 7.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-16 {
    height: 8rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-16 {
    width: 8rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-17 {
    height: 8.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-17 {
    width: 8.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-18 {
    height: 9rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-18 {
    width: 9rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-19 {
    height: 9.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-19 {
    width: 9.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-20 {
    height: 10rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-20 {
    width: 10rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-21 {
    height: 10.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-21 {
    width: 10.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-22 {
    height: 11rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-22 {
    width: 11rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-23 {
    height: 11.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-23 {
    width: 11.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-24 {
    height: 12rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-24 {
    width: 12rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-25 {
    height: 12.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-25 {
    width: 12.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-30 {
    height: 15rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-30 {
    width: 15rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-35 {
    height: 17.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-35 {
    width: 17.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-40 {
    height: 20rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-40 {
    width: 20rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-45 {
    height: 22.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-45 {
    width: 22.5rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-50 {
    height: 25rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-50 {
    width: 25rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-60 {
    height: 30rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-60 {
    width: 30rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-70 {
    height: 35rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-70 {
    width: 35rem !important;
  }
}

@media (min-width: 1200px) {
  .sh-xl-80 {
    height: 40rem !important;
  }
}

@media (min-width: 1200px) {
  .sw-xl-80 {
    width: 40rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-0 {
    height: 0 !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-0 {
    width: 0 !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-1 {
    height: 0.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-1 {
    width: 0.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-2 {
    height: 1rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-2 {
    width: 1rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-3 {
    height: 1.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-3 {
    width: 1.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-4 {
    height: 2rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-4 {
    width: 2rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-5 {
    height: 2.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-5 {
    width: 2.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-6 {
    height: 3rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-6 {
    width: 3rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-7 {
    height: 3.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-7 {
    width: 3.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-8 {
    height: 4rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-8 {
    width: 4rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-9 {
    height: 4.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-9 {
    width: 4.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-10 {
    height: 5rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-10 {
    width: 5rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-11 {
    height: 5.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-11 {
    width: 5.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-12 {
    height: 6rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-12 {
    width: 6rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-13 {
    height: 6.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-13 {
    width: 6.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-14 {
    height: 7rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-14 {
    width: 7rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-15 {
    height: 7.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-15 {
    width: 7.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-16 {
    height: 8rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-16 {
    width: 8rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-17 {
    height: 8.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-17 {
    width: 8.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-18 {
    height: 9rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-18 {
    width: 9rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-19 {
    height: 9.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-19 {
    width: 9.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-20 {
    height: 10rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-20 {
    width: 10rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-21 {
    height: 10.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-21 {
    width: 10.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-22 {
    height: 11rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-22 {
    width: 11rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-23 {
    height: 11.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-23 {
    width: 11.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-24 {
    height: 12rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-24 {
    width: 12rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-25 {
    height: 12.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-25 {
    width: 12.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-30 {
    height: 15rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-30 {
    width: 15rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-35 {
    height: 17.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-35 {
    width: 17.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-40 {
    height: 20rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-40 {
    width: 20rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-45 {
    height: 22.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-45 {
    width: 22.5rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-50 {
    height: 25rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-50 {
    width: 25rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-60 {
    height: 30rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-60 {
    width: 30rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-70 {
    height: 35rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-70 {
    width: 35rem !important;
  }
}

@media (min-width: 1400px) {
  .sh-xxl-80 {
    height: 40rem !important;
  }
}

@media (min-width: 1400px) {
  .sw-xxl-80 {
    width: 40rem !important;
  }
}

@media (min-width: 576px) {
  .h-sm-100-card {
    height: calc(100% - var(--small-title-height)) !important;
  }
}

@media (min-width: 768px) {
  .h-md-100-card {
    height: calc(100% - var(--small-title-height)) !important;
  }
}

@media (min-width: 992px) {
  .h-lg-100-card {
    height: calc(100% - var(--small-title-height)) !important;
  }
}

@media (min-width: 1200px) {
  .h-xl-100-card {
    height: calc(100% - var(--small-title-height)) !important;
  }
}

@media (min-width: 1400px) {
  .h-xxl-100-card {
    height: calc(100% - var(--small-title-height)) !important;
  }
}

@media (min-width: 576px) {
  .h-sm-100 {
    height: 100% !important;
  }
}

@media (min-width: 768px) {
  .h-md-100 {
    height: 100% !important;
  }
}

@media (min-width: 992px) {
  .h-lg-100 {
    height: 100% !important;
  }
}

@media (min-width: 1200px) {
  .h-xl-100 {
    height: 100% !important;
  }
}

@media (min-width: 1400px) {
  .h-xxl-100 {
    height: 100% !important;
  }
}

@media (min-width: 576px) {
  .h-sm-auto {
    height: auto !important;
  }
}

@media (min-width: 768px) {
  .h-md-auto {
    height: auto !important;
  }
}

@media (min-width: 992px) {
  .h-lg-auto {
    height: auto !important;
  }
}

@media (min-width: 1200px) {
  .h-xl-auto {
    height: auto !important;
  }
}

@media (min-width: 1400px) {
  .h-xxl-auto {
    height: auto !important;
  }
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: auto !important;
  }
}

@media (min-width: 768px) {
  .w-md-auto {
    width: auto !important;
  }
}

@media (min-width: 992px) {
  .w-lg-auto {
    width: auto !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-auto {
    width: auto !important;
  }
}

@media (min-width: 1400px) {
  .w-xxl-auto {
    width: auto !important;
  }
}

@media (min-width: 576px) {
  .h-sm-25 {
    height: 25% !important;
  }
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }
}

@media (min-width: 576px) {
  .h-sm-50 {
    height: 50% !important;
  }
}

@media (min-width: 576px) {
  .w-sm-50 {
    width: 50% !important;
  }
}

@media (min-width: 576px) {
  .h-sm-75 {
    height: 75% !important;
  }
}

@media (min-width: 576px) {
  .w-sm-75 {
    width: 75% !important;
  }
}

@media (min-width: 576px) {
  .h-sm-100 {
    height: 100% !important;
  }
}

@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .h-md-25 {
    height: 25% !important;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
}

@media (min-width: 768px) {
  .h-md-50 {
    height: 50% !important;
  }
}

@media (min-width: 768px) {
  .w-md-50 {
    width: 50% !important;
  }
}

@media (min-width: 768px) {
  .h-md-75 {
    height: 75% !important;
  }
}

@media (min-width: 768px) {
  .w-md-75 {
    width: 75% !important;
  }
}

@media (min-width: 768px) {
  .h-md-100 {
    height: 100% !important;
  }
}

@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
}

@media (min-width: 992px) {
  .h-lg-25 {
    height: 25% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }
}

@media (min-width: 992px) {
  .h-lg-50 {
    height: 50% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-50 {
    width: 50% !important;
  }
}

@media (min-width: 992px) {
  .h-lg-75 {
    height: 75% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-75 {
    width: 75% !important;
  }
}

@media (min-width: 992px) {
  .h-lg-100 {
    height: 100% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
}

@media (min-width: 1200px) {
  .h-xl-25 {
    height: 25% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }
}

@media (min-width: 1200px) {
  .h-xl-50 {
    height: 50% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-50 {
    width: 50% !important;
  }
}

@media (min-width: 1200px) {
  .h-xl-75 {
    height: 75% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-75 {
    width: 75% !important;
  }
}

@media (min-width: 1200px) {
  .h-xl-100 {
    height: 100% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }
}

@media (min-width: 1400px) {
  .h-xxl-25 {
    height: 25% !important;
  }
}

@media (min-width: 1400px) {
  .w-xxl-25 {
    width: 25% !important;
  }
}

@media (min-width: 1400px) {
  .h-xxl-50 {
    height: 50% !important;
  }
}

@media (min-width: 1400px) {
  .w-xxl-50 {
    width: 50% !important;
  }
}

@media (min-width: 1400px) {
  .h-xxl-75 {
    height: 75% !important;
  }
}

@media (min-width: 1400px) {
  .w-xxl-75 {
    width: 75% !important;
  }
}

@media (min-width: 1400px) {
  .h-xxl-100 {
    height: 100% !important;
  }
}

@media (min-width: 1400px) {
  .w-xxl-100 {
    width: 100% !important;
  }
}

.min-h-100 {
  min-height: 100%;
}

.max-h-100 {
  max-height: 100%;
}

.min-w-100 {
  min-width: 100%;
}

.max-w-100 {
  max-width: 100%;
}

.min-w-0 {
  min-width: 0;
}

.min-h-0 {
  min-height: 0;
}

/*
*
* Positions
*
* Top, bottom, left and right position utils.
*
*/
.t-0 {
  top: 0 !important;
  z-index: 1;
}

.b-0 {
  bottom: 0 !important;
  z-index: 1;
}

.s-0 {
  left: 0 !important;
  z-index: 1;
}

.e-0 {
  right: 0 !important;
  z-index: 1;
}

.t-n0 {
  top: 0 !important;
  z-index: 1;
}

.b-n0 {
  bottom: 0 !important;
  z-index: 1;
}

.s-n0 {
  left: 0 !important;
  z-index: 1;
}

.e-n0 {
  right: 0 !important;
  z-index: 1;
}

.t-1 {
  top: 0.25rem !important;
  z-index: 1;
}

.b-1 {
  bottom: 0.25rem !important;
  z-index: 1;
}

.s-1 {
  left: 0.25rem !important;
  z-index: 1;
}

.e-1 {
  right: 0.25rem !important;
  z-index: 1;
}

.t-n1 {
  top: -0.25rem !important;
  z-index: 1;
}

.b-n1 {
  bottom: -0.25rem !important;
  z-index: 1;
}

.s-n1 {
  left: -0.25rem !important;
  z-index: 1;
}

.e-n1 {
  right: -0.25rem !important;
  z-index: 1;
}

.t-2 {
  top: 0.5rem !important;
  z-index: 1;
}

.b-2 {
  bottom: 0.5rem !important;
  z-index: 1;
}

.s-2 {
  left: 0.5rem !important;
  z-index: 1;
}

.e-2 {
  right: 0.5rem !important;
  z-index: 1;
}

.t-n2 {
  top: -0.5rem !important;
  z-index: 1;
}

.b-n2 {
  bottom: -0.5rem !important;
  z-index: 1;
}

.s-n2 {
  left: -0.5rem !important;
  z-index: 1;
}

.e-n2 {
  right: -0.5rem !important;
  z-index: 1;
}

.t-3 {
  top: 1rem !important;
  z-index: 1;
}

.b-3 {
  bottom: 1rem !important;
  z-index: 1;
}

.s-3 {
  left: 1rem !important;
  z-index: 1;
}

.e-3 {
  right: 1rem !important;
  z-index: 1;
}

.t-n3 {
  top: -1rem !important;
  z-index: 1;
}

.b-n3 {
  bottom: -1rem !important;
  z-index: 1;
}

.s-n3 {
  left: -1rem !important;
  z-index: 1;
}

.e-n3 {
  right: -1rem !important;
  z-index: 1;
}

.t-4 {
  top: 1.5rem !important;
  z-index: 1;
}

.b-4 {
  bottom: 1.5rem !important;
  z-index: 1;
}

.s-4 {
  left: 1.5rem !important;
  z-index: 1;
}

.e-4 {
  right: 1.5rem !important;
  z-index: 1;
}

.t-n4 {
  top: -1.5rem !important;
  z-index: 1;
}

.b-n4 {
  bottom: -1.5rem !important;
  z-index: 1;
}

.s-n4 {
  left: -1.5rem !important;
  z-index: 1;
}

.e-n4 {
  right: -1.5rem !important;
  z-index: 1;
}

.t-5 {
  top: 2rem !important;
  z-index: 1;
}

.b-5 {
  bottom: 2rem !important;
  z-index: 1;
}

.s-5 {
  left: 2rem !important;
  z-index: 1;
}

.e-5 {
  right: 2rem !important;
  z-index: 1;
}

.t-n5 {
  top: -2rem !important;
  z-index: 1;
}

.b-n5 {
  bottom: -2rem !important;
  z-index: 1;
}

.s-n5 {
  left: -2rem !important;
  z-index: 1;
}

.e-n5 {
  right: -2rem !important;
  z-index: 1;
}

/*
*
* Shared
*
* Styles for anchors, opacity, backgrounds and so on.
*
*/
a {
  transition: color var(--transition-time-short);
  text-decoration: initial;
  color: var(--primary);
  outline: initial !important;
}
a:hover, a:active {
  text-decoration: initial;
  color: var(--secondary);
}

a.body {
  color: var(--body);
}
a.body:hover, a.body:active {
  color: var(--primary);
}

.body-link {
  color: var(--body);
}
.body-link:hover {
  color: var(--primary);
}

.alternate-link {
  color: var(--alternate);
}
.alternate-link:hover {
  color: var(--primary);
}

.muted-link {
  color: var(--muted);
}
.muted-link:hover {
  color: var(--primary);
}

.underline-link {
  text-decoration: underline;
}

.underline-hover-link:hover {
  text-decoration: underline;
}

img {
  -o-object-fit: cover;
     object-fit: cover;
}

.absolute-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.absolute-center-vertical {
  top: 50%;
  transform: translateY(-50%);
}

.absolute-center-horizontal {
  left: 50%;
  transform: translateX(-50%);
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

.grayscale {
  filter: grayscale(1);
}

.img-fluid-width {
  max-width: 100%;
  height: auto;
}

.img-fluid-height {
  max-height: 100%;
  width: auto;
}

.shadow {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03) !important;
}

.shadow-deep {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1) !important;
}

.bg-cover-center {
  background-size: cover;
  background-position: center;
}

.z-index-1 {
  z-index: 1;
}

.z-index-1000 {
  z-index: 1000;
}

.basic-transform-transition {
  transition: all 0.1s ease;
  pointer-events: none;
  transform: translate(-50%, 0);
}

.fixed-background {
  background: url("../assets/img/answer-img.jpg") no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hover-trigger:hover .visible-hover {
  visibility: visible !important;
}

.login_pages .logo-default {
  width: 236px;
  min-height: 64px;
}
.login_pages .login_txt {
  color: var(--secondary);
  text-decoration: underline;
}

.menu-container .menu li span.label {
  width: 90%;
  padding-left: 10px;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.theme-filter {
  filter: var(--theme-image-filter);
}

.flex-grow-1 {
  flex: 1;
}

.achievement {
  width: 75px;
  height: 75px;
}

.achievement.bg svg {
  fill: var(--separator-light);
}

.achievement.level svg {
  fill: var(--primary);
}

/*
*
* Shared
*
* Rest of the theme variables.
*
*/
:root {
  --transition-time: 400ms;
  --transition-time-long: 1000ms;
  --transition-time-short: 200ms;
  --nav-size-slim: 5rem;
  --nav-size: 18rem;
  --footer-size: 4.5rem;
  --input-height: 2.25rem;
  --small-title-height: 2rem;
  --font: "Nunito Sans", sans-serif;
  --font-heading: "Montserrat", sans-serif;
}

html[data-radius=rounded] {
  --border-radius-lg: 16px;
  --border-radius-md: 10px;
  --border-radius-sm: 6px;
  --border-radius-xl: 50px;
}

html[data-radius=standard] {
  --border-radius-lg: 6px;
  --border-radius-md: 4px;
  --border-radius-sm: 3px;
  --border-radius-xl: 4px;
}

html[data-radius=flat] {
  --border-radius-lg: 0;
  --border-radius-sm: 0;
  --border-radius-md: 0;
  --border-radius-xl: 0;
}

:root {
  --card-spacing: 2rem;
  --card-spacing-sm: 1.25rem;
  --card-spacing-xs: 0.75rem;
  --card-spacing-sm-horizontal: 1.75rem 1.25rem;
  --card-spacing-sm-vertical: 1.25rem 1.75rem;
  --main-spacing-horizontal: 2.5rem;
  --main-spacing-vertical: 2rem;
  --title-spacing: 1.25rem;
}
@media (max-width: 1199.98px) {
  :root {
    --main-spacing-horizontal: 2rem;
  }
}
@media (max-width: 991.98px) {
  :root {
    --nav-size-slim: 4rem;
    --title-spacing: 1rem;
    --footer-size: 4rem;
  }
}
@media (max-width: 767.98px) {
  :root {
    --card-spacing: 1.75rem;
    --footer-size: 3rem;
    --main-spacing-horizontal: 1.25rem;
    --main-spacing-vertical: 1.25rem;
  }
}

/*
*
* Dark Blue
*
* Theme variables.
*
*/
html[data-color=dark-blue] {
  --primary: #1b98d0;
  --secondary: #61c6d8;
  --tertiary: #54cdb4;
  --quaternary: #4d7fdb;
  --primary-rgb: 27, 152, 208;
  --secondary-rgb: 97, 198, 216;
  --tertiary-rgb: 84, 205, 180;
  --quaternary-rgb: 77, 127, 219;
  --primary-darker: #1989bb;
  --secondary-darker: #56b1c1;
  --tertiary-darker: #46af99;
  --quaternary-darker: #426ebf;
  --body: #c1c1c1;
  --alternate: #999999;
  --muted: #727272;
  --separator: #474747;
  --separator-light: #2e2e2e;
  --body-rgb: 193, 193, 193;
  --alternate-rgb: 153, 153, 153;
  --muted-rgb: 114, 114, 114;
  --separator-rgb: 71, 71, 71;
  --separator-light-rgb: 46, 46, 46;
  --background: #1d1d1d;
  --foreground: #242424;
  --background-rgb: 29, 29, 29;
  --foreground-rgb: 36, 36, 36;
  --background-theme: #242424;
  --background-light: #292929;
  --gradient-1: #1b98d0;
  --gradient-2: #1d9dd7;
  --gradient-3: #1ea5e1;
  --gradient-1-lighter: #1ea5e1;
  --gradient-1-darker: #1a90c5;
  --light-text: #f0f0f0;
  --dark-text: #191c1f;
  --light-text-darker: #e9e9e9;
  --dark-text-darker: #08090a;
  --light-text-rgb: 240, 240, 240;
  --dark-text-rgb: 25, 28, 31;
  --danger: #b62836;
  --info: #298a99;
  --warning: #ebb71a;
  --success: #418b38;
  --light: #dadada;
  --dark: #4e4e4e;
  --danger-darker: #771a23;
  --info-darker: #19545d;
  --warning-darker: #aa830f;
  --success-darker: #285422;
  --light-darker: #c9c9c9;
  --dark-darker: #282828;
  --body-darker: #a0a0a0;
  --alternate-darker: #6e6e6e;
  --muted-darker: #4e4e4e;
  --separator-darker: #353535;
  --danger-rgb: 182, 40, 54;
  --info-rgb: 41, 138, 153;
  --warning-rgb: 235, 183, 26;
  --success-rgb: 65, 139, 56;
  --light-rgb: 218, 218, 218;
  --dark-rgb: 78, 78, 78;
  --menu-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  --menu-shadow-navcolor: 0px 3px 10px rgba(0, 0, 0, 0.4);
  --background-navcolor-light: #fff;
  --background-navcolor-dark: #242424;
  --theme-image-filter: hue-rotate(0deg) brightness(0.9);
}

/*
*
* Dark Green
*
* Theme variables.
*
*/
html[data-color=dark-green] {
  --primary: #6a9a33;
  --secondary: #a6c016;
  --tertiary: #2fa729;
  --quaternary: #5ab78f;
  --primary-rgb: 36, 175, 92;
  --secondary-rgb: 98, 201, 67;
  --tertiary-rgb: 12, 128, 51;
  --quaternary-rgb: 14, 133, 107;
  --primary-darker: #5e892d;
  --secondary-darker: #96ad14;
  --tertiary-darker: #299323;
  --quaternary-darker: #4e9f7c;
  --body: #c1c1c1;
  --alternate: #999999;
  --muted: #727272;
  --separator: #474747;
  --separator-light: #2e2e2e;
  --body-rgb: 193, 193, 193;
  --alternate-rgb: 153, 153, 153;
  --muted-rgb: 114, 114, 114;
  --separator-rgb: 71, 71, 71;
  --separator-light-rgb: 46, 46, 46;
  --background: #1d1d1d;
  --foreground: #242424;
  --background-rgb: 29, 29, 29;
  --foreground-rgb: 36, 36, 36;
  --background-theme: #242424;
  --background-light: #292929;
  --gradient-1: #6a9a33;
  --gradient-2: #6c9d34;
  --gradient-3: #70a336;
  --gradient-1-lighter: #72a537;
  --gradient-1-darker: #638f30;
  --light-text: #f0f0f0;
  --dark-text: #191c1f;
  --light-text-darker: #e9e9e9;
  --dark-text-darker: #08090a;
  --light-text-rgb: 240, 240, 240;
  --dark-text-rgb: 25, 28, 31;
  --danger: #b62836;
  --info: #298a99;
  --warning: #ebb71a;
  --success: #418b38;
  --light: #dadada;
  --dark: #4e4e4e;
  --danger-darker: #771a23;
  --info-darker: #19545d;
  --warning-darker: #aa830f;
  --success-darker: #285422;
  --light-darker: #c9c9c9;
  --dark-darker: #282828;
  --body-darker: #a0a0a0;
  --alternate-darker: #6e6e6e;
  --muted-darker: #4e4e4e;
  --separator-darker: #353535;
  --danger-rgb: 182, 40, 54;
  --info-rgb: 41, 138, 153;
  --warning-rgb: 235, 183, 26;
  --success-rgb: 65, 139, 56;
  --light-rgb: 218, 218, 218;
  --dark-rgb: 78, 78, 78;
  --menu-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  --menu-shadow-navcolor: 0px 3px 10px rgba(0, 0, 0, 0.4);
  --background-navcolor-light: #fff;
  --background-navcolor-dark: #242424;
  --theme-image-filter: hue-rotate(252deg) contrast(0.6) brightness(1);
}

/*
*
* Dark Red
*
* Theme variables.
*
*/
html[data-color=dark-red] {
  --primary: #d44f4f;
  --secondary: #e68868;
  --tertiary: #e19042;
  --quaternary: #d22958;
  --primary-rgb: 212, 79, 79;
  --secondary-rgb: 230, 136, 104;
  --tertiary-rgb: 225, 144, 66;
  --quaternary-rgb: 210, 41, 88;
  --primary-darker: #c54949;
  --secondary-darker: #d77f61;
  --tertiary-darker: #cb823c;
  --quaternary-darker: #bd244f;
  --body: #c1c1c1;
  --alternate: #999999;
  --muted: #727272;
  --separator: #474747;
  --separator-light: #2e2e2e;
  --body-rgb: 193, 193, 193;
  --alternate-rgb: 153, 153, 153;
  --muted-rgb: 114, 114, 114;
  --separator-rgb: 71, 71, 71;
  --separator-light-rgb: 46, 46, 46;
  --background: #1d1d1d;
  --foreground: #242424;
  --background-rgb: 29, 29, 29;
  --foreground-rgb: 36, 36, 36;
  --background-theme: #242424;
  --background-light: #292929;
  --gradient-1: #d44f4f;
  --gradient-2: #d95151;
  --gradient-3: #dd5252;
  --gradient-1-lighter: #db5252;
  --gradient-1-darker: #cb4c4c;
  --light-text: #f0f0f0;
  --dark-text: #191c1f;
  --light-text-darker: #e9e9e9;
  --dark-text-darker: #08090a;
  --light-text-rgb: 240, 240, 240;
  --dark-text-rgb: 25, 28, 31;
  --danger: #b62836;
  --info: #298a99;
  --warning: #ebb71a;
  --success: #418b38;
  --light: #dadada;
  --dark: #4e4e4e;
  --danger-darker: #771a23;
  --info-darker: #19545d;
  --warning-darker: #aa830f;
  --success-darker: #285422;
  --light-darker: #c9c9c9;
  --dark-darker: #282828;
  --body-darker: #a0a0a0;
  --alternate-darker: #6e6e6e;
  --muted-darker: #4e4e4e;
  --separator-darker: #353535;
  --danger-rgb: 182, 40, 54;
  --info-rgb: 41, 138, 153;
  --warning-rgb: 235, 183, 26;
  --success-rgb: 65, 139, 56;
  --light-rgb: 218, 218, 218;
  --dark-rgb: 78, 78, 78;
  --menu-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  --menu-shadow-navcolor: 0px 3px 10px rgba(0, 0, 0, 0.4);
  --background-navcolor-light: #fff;
  --background-navcolor-dark: #242424;
  --theme-image-filter: hue-rotate(158deg) contrast(1.5) brightness(0.8);
}

/*
*
* Dark Purple
*
* Theme variables.
*
*/
html[data-color=dark-purple] {
  --primary: #9767b7;
  --secondary: #d59fe4;
  --tertiary: #985398;
  --quaternary: #5d3ca7;
  --primary-rgb: 151, 103, 183;
  --secondary-rgb: 213, 159, 228;
  --tertiary-rgb: 152, 83, 152;
  --quaternary-rgb: 93, 60, 167;
  --primary-darker: #80579b;
  --secondary-darker: #bc8cc9;
  --tertiary-darker: #7d447d;
  --quaternary-darker: #4c3189;
  --body: #c1c1c1;
  --alternate: #999999;
  --muted: #727272;
  --separator: #474747;
  --separator-light: #2e2e2e;
  --body-rgb: 193, 193, 193;
  --alternate-rgb: 153, 153, 153;
  --muted-rgb: 114, 114, 114;
  --separator-rgb: 71, 71, 71;
  --separator-light-rgb: 46, 46, 46;
  --background: #1d1d1d;
  --foreground: #242424;
  --background-rgb: 29, 29, 29;
  --foreground-rgb: 36, 36, 36;
  --background-theme: #242424;
  --background-light: #292929;
  --gradient-1: #9767b7;
  --gradient-2: #ab7ccc;
  --gradient-3: #af7ecf;
  --gradient-1-lighter: #8048af;
  --gradient-1-darker: #613783;
  --light-text: #f0f0f0;
  --dark-text: #191c1f;
  --light-text-darker: #e9e9e9;
  --dark-text-darker: #08090a;
  --light-text-rgb: 240, 240, 240;
  --dark-text-rgb: 25, 28, 31;
  --danger: #b62836;
  --info: #298a99;
  --warning: #ebb71a;
  --success: #418b38;
  --light: #dadada;
  --dark: #4e4e4e;
  --danger-darker: #771a23;
  --info-darker: #19545d;
  --warning-darker: #aa830f;
  --success-darker: #285422;
  --light-darker: #c9c9c9;
  --dark-darker: #282828;
  --body-darker: #a0a0a0;
  --alternate-darker: #6e6e6e;
  --muted-darker: #4e4e4e;
  --separator-darker: #353535;
  --danger-rgb: 182, 40, 54;
  --info-rgb: 41, 138, 153;
  --warning-rgb: 235, 183, 26;
  --success-rgb: 65, 139, 56;
  --light-rgb: 218, 218, 218;
  --dark-rgb: 78, 78, 78;
  --menu-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  --menu-shadow-navcolor: 0px 3px 10px rgba(0, 0, 0, 0.4);
  --background-navcolor-light: #fff;
  --background-navcolor-dark: #242424;
  --theme-image-filter: hue-rotate(75deg) contrast(0.7) brightness(0.85);
}

/*
*
* Dark Pink
*
* Theme variables.
*
*/
html[data-color=dark-pink] {
  --primary: #d65d7c;
  --secondary: #e69790;
  --tertiary: #df4459;
  --quaternary: #e25b5b;
  --primary-rgb: 214, 93, 124;
  --secondary-rgb: 230, 151, 144;
  --tertiary-rgb: 223, 68, 89;
  --quaternary-rgb: 226, 91, 91;
  --primary-darker: #c55672;
  --secondary-darker: #d38a84;
  --tertiary-darker: #c93d50;
  --quaternary-darker: #cb5454;
  --body: #c1c1c1;
  --alternate: #999999;
  --muted: #727272;
  --separator: #474747;
  --separator-light: #2e2e2e;
  --body-rgb: 193, 193, 193;
  --alternate-rgb: 153, 153, 153;
  --muted-rgb: 114, 114, 114;
  --separator-rgb: 71, 71, 71;
  --separator-light-rgb: 46, 46, 46;
  --background: #1d1d1d;
  --foreground: #242424;
  --background-rgb: 29, 29, 29;
  --foreground-rgb: 36, 36, 36;
  --background-theme: #242424;
  --background-light: #292929;
  --gradient-1: #d65d7c;
  --gradient-2: #dd6080;
  --gradient-3: #e36585;
  --gradient-1-lighter: #df6383;
  --gradient-1-darker: #c75773;
  --light-text: #f0f0f0;
  --dark-text: #191c1f;
  --light-text-darker: #e9e9e9;
  --dark-text-darker: #08090a;
  --light-text-rgb: 240, 240, 240;
  --dark-text-rgb: 25, 28, 31;
  --danger: #b62836;
  --info: #298a99;
  --warning: #ebb71a;
  --success: #418b38;
  --light: #dadada;
  --dark: #4e4e4e;
  --danger-darker: #771a23;
  --info-darker: #19545d;
  --warning-darker: #aa830f;
  --success-darker: #285422;
  --light-darker: #c9c9c9;
  --dark-darker: #282828;
  --body-darker: #a0a0a0;
  --alternate-darker: #6e6e6e;
  --muted-darker: #4e4e4e;
  --separator-darker: #353535;
  --danger-rgb: 182, 40, 54;
  --info-rgb: 41, 138, 153;
  --warning-rgb: 235, 183, 26;
  --success-rgb: 65, 139, 56;
  --light-rgb: 218, 218, 218;
  --dark-rgb: 78, 78, 78;
  --menu-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  --menu-shadow-navcolor: 0px 3px 10px rgba(0, 0, 0, 0.4);
  --background-navcolor-light: #fff;
  --background-navcolor-dark: #242424;
  --theme-image-filter: hue-rotate(144deg) contrast(0.9) brightness(0.87);
}

/*
*
* Light Green
*
* Theme variables.
*
*/
html[data-color=light-green] {
  --primary: #75ab38;
  --secondary: #b8d518;
  --tertiary: #34b92e;
  --quaternary: #64cb9e;
  --primary-rgb: 117, 171, 56;
  --secondary-rgb: 184, 213, 24;
  --tertiary-rgb: 52, 185, 46;
  --quaternary-rgb: 100, 203, 158;
  --primary-darker: #689734;
  --secondary-darker: #a3bd15;
  --tertiary-darker: #2ea529;
  --quaternary-darker: #58b38b;
  --body: #4e4e4e;
  --alternate: #7c7c7c;
  --muted: #afafaf;
  --separator: #dddddd;
  --separator-light: #f1f1f1;
  --body-rgb: 59, 59, 59;
  --alternate-rgb: 124, 124, 124;
  --muted-rgb: 176, 176, 176;
  --separator-rgb: 221, 221, 221;
  --separator-light-rgb: 241, 241, 241;
  --background: #f9f9f9;
  --foreground: #ffffff;
  --background-rgb: 249, 249, 249;
  --foreground-rgb: 255, 255, 255;
  --background-theme: #eaf1ec;
  --background-light: #f8f8f8;
  --gradient-1: #75ab38;
  --gradient-2: #78af3a;
  --gradient-3: #7db53e;
  --gradient-1-lighter: #83bd42;
  --gradient-1-darker: #699932;
  --light-text: #fff;
  --dark-text: #343a40;
  --light-text-darker: #eeeeee;
  --dark-text-darker: #23272b;
  --light-text-rgb: 255, 255, 255;
  --dark-text-rgb: 52, 58, 64;
  --danger: #cf2637;
  --info: #279aac;
  --warning: #ebb71a;
  --success: #439b38;
  --light: #dadada;
  --dark: #4e4e4e;
  --danger-darker: #771a23;
  --info-darker: #19545d;
  --warning-darker: #aa830f;
  --success-darker: #285422;
  --light-darker: #c9c9c9;
  --dark-darker: #282828;
  --body-darker: #333333;
  --alternate-darker: #616161;
  --muted-darker: #888888;
  --separator-darker: #c0c0c0;
  --danger-rgb: 182, 40, 54;
  --info-rgb: 41, 138, 153;
  --warning-rgb: 235, 183, 26;
  --success-rgb: 65, 139, 56;
  --light-rgb: 218, 218, 218;
  --dark-rgb: 78, 78, 78;
  --menu-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
  --menu-shadow-navcolor: 0px 2px 6px rgba(0, 0, 0, 0.05);
  --background-navcolor-light: #fff;
  --background-navcolor-dark: #24352b;
  --theme-image-filter: hue-rotate(249deg) contrast(0.7) brightness(1.1);
}

/*
*
* Light Blue
*
* Theme variables.
*
*/
html[data-color=light-blue] {
  --primary: #2c2872;
  --secondary: #ec642b;
  --tertiary: #111111;
  --quaternary: #0d6efd;
  --primary-rgb: 44, 40, 114;
  --secondary-rgb: 236, 100, 43;
  --tertiary-rgb: 17, 17, 17;
  --quaternary-rgb: 13, 110, 253;
  --primary-darker: #201d52;
  --secondary-darker: #d84a0d;
  --tertiary-darker: #080808;
  --quaternary-darker: #0e49a1;
  --body: #4e4e4e;
  --alternate: #7c7c7c;
  --muted: #afafaf;
  --separator: #dddddd;
  --separator-light: #f1f1f1;
  --body-rgb: 59, 59, 59;
  --alternate-rgb: 124, 124, 124;
  --muted-rgb: 176, 176, 176;
  --separator-rgb: 221, 221, 221;
  --separator-light-rgb: 241, 241, 241;
  --background: #f9f9f9;
  --foreground: #ffffff;
  --background-rgb: 249, 249, 249;
  --foreground-rgb: 255, 255, 255;
  --background-theme: #eaf0f1;
  --background-light: #f8f8f8;
  --gradient-1: #2f29a5;
  --gradient-2: #423ad1;
  --gradient-3: #4137ff;
  --gradient-1-lighter: #170bff;
  --gradient-1-darker: #281fcc;
  --light-text: #fff;
  --dark-text: #343a40;
  --light-text-darker: #eeeeee;
  --dark-text-darker: #23272b;
  --light-text-rgb: 255, 255, 255;
  --dark-text-rgb: 52, 58, 64;
  --danger: #cf2637;
  --info: #279aac;
  --warning: #ebb71a;
  --success: #439b38;
  --light: #dadada;
  --dark: #4e4e4e;
  --danger-darker: #771a23;
  --info-darker: #19545d;
  --warning-darker: #aa830f;
  --success-darker: #285422;
  --light-darker: #c9c9c9;
  --dark-darker: #282828;
  --body-darker: #333333;
  --alternate-darker: #616161;
  --muted-darker: #888888;
  --separator-darker: #c0c0c0;
  --danger-rgb: 182, 40, 54;
  --info-rgb: 41, 138, 153;
  --warning-rgb: 235, 183, 26;
  --success-rgb: 65, 139, 56;
  --light-rgb: 218, 218, 218;
  --dark-rgb: 78, 78, 78;
  --menu-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
  --menu-shadow-navcolor: 0px 2px 6px rgba(0, 0, 0, 0.05);
  --background-navcolor-light: #fff;
  --background-navcolor-dark: #253a52;
  --theme-image-filter: hue-rotate(0deg);
}

/*
*
* Light Red
*
* Theme variables.
*
*/
html[data-color=light-red] {
  --primary: #eb5858;
  --secondary: #ff9773;
  --tertiary: #f9a049;
  --quaternary: #e92d62;
  --primary-rgb: 235, 88, 88;
  --secondary-rgb: 255, 151, 151;
  --tertiary-rgb: 249, 160, 73;
  --quaternary-rgb: 233, 45, 98;
  --primary-darker: #df5353;
  --secondary-darker: #eb8d6c;
  --tertiary-darker: #e99645;
  --quaternary-darker: #d32959;
  --body: #4e4e4e;
  --alternate: #7c7c7c;
  --muted: #afafaf;
  --separator: #dddddd;
  --separator-light: #f1f1f1;
  --body-rgb: 59, 59, 59;
  --alternate-rgb: 124, 124, 124;
  --muted-rgb: 176, 176, 176;
  --separator-rgb: 221, 221, 221;
  --separator-light-rgb: 241, 241, 241;
  --background: #f9f9f9;
  --foreground: #ffffff;
  --background-rgb: 249, 249, 249;
  --foreground-rgb: 255, 255, 255;
  --background-theme: #f1ecea;
  --background-light: #f8f8f8;
  --gradient-1: #eb5858;
  --gradient-2: #f35c5c;
  --gradient-3: #f35e5e;
  --gradient-1-lighter: #f96363;
  --gradient-1-darker: #d95151;
  --light-text: #fff;
  --dark-text: #343a40;
  --light-text-darker: #eeeeee;
  --dark-text-darker: #23272b;
  --light-text-rgb: 255, 255, 255;
  --dark-text-rgb: 52, 58, 64;
  --danger: #cf2637;
  --info: #279aac;
  --warning: #ebb71a;
  --success: #439b38;
  --light: #dadada;
  --dark: #4e4e4e;
  --danger-darker: #771a23;
  --info-darker: #19545d;
  --warning-darker: #aa830f;
  --success-darker: #285422;
  --light-darker: #c9c9c9;
  --dark-darker: #282828;
  --body-darker: #333333;
  --alternate-darker: #616161;
  --muted-darker: #888888;
  --separator-darker: #c0c0c0;
  --danger-rgb: 182, 40, 54;
  --info-rgb: 41, 138, 153;
  --warning-rgb: 235, 183, 26;
  --success-rgb: 65, 139, 56;
  --light-rgb: 218, 218, 218;
  --dark-rgb: 78, 78, 78;
  --menu-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
  --menu-shadow-navcolor: 0px 2px 6px rgba(0, 0, 0, 0.05);
  --background-navcolor-light: #fff;
  --background-navcolor-dark: #3b2b2f;
  --theme-image-filter: hue-rotate(158deg) contrast(1.5) brightness(0.9);
}

/*
*
* Light Purple
*
* Theme variables.
*
*/
html[data-color=light-purple] {
  --primary: #a772cb;
  --secondary: #ecb0fd;
  --tertiary: #a95ca9;
  --quaternary: #6743b9;
  --primary-rgb: 167, 114, 203;
  --secondary-rgb: 236, 176, 253;
  --tertiary-rgb: 169, 92, 169;
  --quaternary-rgb: 103, 67, 185;
  --primary-darker: #9062af;
  --secondary-darker: #ce98dd;
  --tertiary-darker: #894b89;
  --quaternary-darker: #553799;
  --body: #4e4e4e;
  --alternate: #7c7c7c;
  --muted: #afafaf;
  --separator: #dddddd;
  --separator-light: #f1f1f1;
  --body-rgb: 59, 59, 59;
  --alternate-rgb: 124, 124, 124;
  --muted-rgb: 176, 176, 176;
  --separator-rgb: 221, 221, 221;
  --separator-light-rgb: 241, 241, 241;
  --background: #f9f9f9;
  --foreground: #ffffff;
  --background-rgb: 249, 249, 249;
  --foreground-rgb: 255, 255, 255;
  --background-theme: #efeaf1;
  --background-light: #f8f8f8;
  --gradient-1: #a772cb;
  --gradient-2: #ae76d3;
  --gradient-3: #b67edb;
  --gradient-1-lighter: #c28ce6;
  --gradient-1-darker: #9868b9;
  --light-text: #fff;
  --dark-text: #343a40;
  --light-text-darker: #eeeeee;
  --dark-text-darker: #23272b;
  --light-text-rgb: 255, 255, 255;
  --dark-text-rgb: 52, 58, 64;
  --danger: #cf2637;
  --info: #279aac;
  --warning: #ebb71a;
  --success: #439b38;
  --light: #dadada;
  --dark: #4e4e4e;
  --danger-darker: #771a23;
  --info-darker: #19545d;
  --warning-darker: #aa830f;
  --success-darker: #285422;
  --light-darker: #c9c9c9;
  --dark-darker: #282828;
  --body-darker: #333333;
  --alternate-darker: #616161;
  --muted-darker: #888888;
  --separator-darker: #c0c0c0;
  --danger-rgb: 182, 40, 54;
  --info-rgb: 41, 138, 153;
  --warning-rgb: 235, 183, 26;
  --success-rgb: 65, 139, 56;
  --light-rgb: 218, 218, 218;
  --dark-rgb: 78, 78, 78;
  --menu-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
  --menu-shadow-navcolor: 0px 2px 6px rgba(0, 0, 0, 0.05);
  --background-navcolor-light: #fff;
  --background-navcolor-dark: #3d3246;
  --theme-image-filter: hue-rotate(74deg) contrast(0.7) brightness(0.95);
}

/*
*
* Light Pink
*
* Theme variables.
*
*/
html[data-color=light-pink] {
  --primary: #ed6789;
  --secondary: #ffa7a0;
  --tertiary: #f74b63;
  --quaternary: #fb6565;
  --primary-rgb: 237, 103, 137;
  --secondary-rgb: 255, 167, 160;
  --tertiary-rgb: 247, 75, 99;
  --quaternary-rgb: 251, 101, 101;
  --primary-darker: #d95e7e;
  --secondary-darker: #ed9c96;
  --tertiary-darker: #dd4358;
  --quaternary-darker: #e95e5e;
  --body: #4e4e4e;
  --alternate: #7c7c7c;
  --muted: #afafaf;
  --separator: #dddddd;
  --separator-light: #f1f1f1;
  --body-rgb: 59, 59, 59;
  --alternate-rgb: 124, 124, 124;
  --muted-rgb: 176, 176, 176;
  --separator-rgb: 221, 221, 221;
  --separator-light-rgb: 241, 241, 241;
  --background: #f9f9f9;
  --foreground: #ffffff;
  --background-rgb: 249, 249, 249;
  --foreground-rgb: 255, 255, 255;
  --background-theme: #f1eaee;
  --background-light: #f8f8f8;
  --gradient-1: #ed6789;
  --gradient-2: #f16b8d;
  --gradient-3: #f57092;
  --gradient-1-lighter: #f97697;
  --gradient-1-darker: #d9607e;
  --light-text: #fff;
  --dark-text: #343a40;
  --light-text-darker: #eeeeee;
  --dark-text-darker: #23272b;
  --light-text-rgb: 255, 255, 255;
  --dark-text-rgb: 52, 58, 64;
  --danger: #cf2637;
  --info: #279aac;
  --warning: #ebb71a;
  --success: #439b38;
  --light: #dadada;
  --dark: #4e4e4e;
  --danger-darker: #771a23;
  --info-darker: #19545d;
  --warning-darker: #aa830f;
  --success-darker: #285422;
  --light-darker: #c9c9c9;
  --dark-darker: #282828;
  --body-darker: #333333;
  --alternate-darker: #616161;
  --muted-darker: #888888;
  --separator-darker: #c0c0c0;
  --danger-rgb: 182, 40, 54;
  --info-rgb: 41, 138, 153;
  --warning-rgb: 235, 183, 26;
  --success-rgb: 65, 139, 56;
  --light-rgb: 218, 218, 218;
  --dark-rgb: 78, 78, 78;
  --menu-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
  --menu-shadow-navcolor: 0px 2px 6px rgba(0, 0, 0, 0.05);
  --background-navcolor-light: #fff;
  --background-navcolor-dark: #462e3a;
  --theme-image-filter: hue-rotate(145deg) contrast(0.9) brightness(1);
}

/*
*
* Accordion
*
* Bootstrap accordion styles.
*
*/
.accordion-button {
  border-color: var(--separator);
  color: var(--body);
  font-size: 1em;
}

.accordion-button:not(.collapsed) {
  color: var(--primary);
  background: initial;
}

.accordion-button:focus {
  border-color: var(--separator);
}

.accordion-collapse {
  border-color: var(--separator);
}

.accordion-button::after {
  background: initial !important;
  font-family: "CS-Interface" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e915";
  text-align: center;
  transform-origin: center;
}

.accordion-item {
  border: 1px solid var(--separator);
}

.accordion-item,
.accordion-button {
  background: initial;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--border-radius-md);
  border-bottom-left-radius: var(--border-radius-md);
}

/*
*
* Alert
*
* Bootstrap alert styles.
*
*/
.alert {
  border: initial;
  border-radius: var(--border-radius-md);
}

.alert-primary {
  color: var(--primary);
  background-color: rgba(var(--primary-rgb), 0.15);
}
.alert-primary .alert-link {
  color: var(--primary);
}

.alert-secondary {
  color: var(--secondary);
  background-color: rgba(var(--secondary-rgb), 0.15);
}
.alert-secondary .alert-link {
  color: var(--secondary);
}

.alert-tertiary {
  color: var(--tertiary);
  background-color: rgba(var(--tertiary-rgb), 0.15);
}
.alert-tertiary .alert-link {
  color: var(--tertiary);
}

.alert-quaternary {
  color: var(--quaternary);
  background-color: rgba(var(--quaternary-rgb), 0.15);
}
.alert-quaternary .alert-link {
  color: var(--quaternary);
}

.alert-success {
  color: var(--success);
  background-color: rgba(var(--success-rgb), 0.15);
}
.alert-success .alert-link {
  color: var(--success);
}

.alert-danger {
  color: var(--danger);
  background-color: rgba(var(--danger-rgb), 0.15);
}
.alert-danger .alert-link {
  color: var(--danger);
}

.alert-info {
  color: var(--info);
  background-color: rgba(var(--info-rgb), 0.15);
}
.alert-info .alert-link {
  color: var(--info);
}

.alert-warning {
  color: var(--warning);
  background-color: rgba(var(--warning-rgb), 0.15);
}
.alert-warning .alert-link {
  color: var(--warning);
}

.alert-light {
  color: var(--alternate);
  background-color: rgba(var(--dark-rgb), 0.05);
}
.alert-light .alert-link {
  color: var(--alternate);
}

.alert-dark {
  color: var(--alternate);
  background-color: rgba(var(--dark-rgb), 0.15);
}
.alert-dark .alert-link {
  color: var(--alternate);
}

.alert-heading {
  color: inherit !important;
}

.alert-dismissible .btn-close {
  top: 3px;
  right: 3px;
  padding: 0.5rem;
}

/*
*
* Background
*
* Bootstrap background utilities and additional ones.
*
*/
.bg-primary {
  background-color: var(--primary) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.bg-tertiary {
  color: var(--light-text) !important;
  background-color: var(--tertiary) !important;
}

.bg-quaternary {
  color: var(--light-text) !important;
  background-color: var(--quaternary) !important;
}

.bg-success {
  background-color: var(--success) !important;
  color: var(--light-text) !important;
}

.bg-danger {
  background-color: var(--danger) !important;
  color: var(--light-text) !important;
}

.bg-warning {
  background-color: var(--warning) !important;
  color: var(--light-text) !important;
}

.bg-info {
  background-color: var(--info) !important;
  color: var(--light-text) !important;
}

.bg-light {
  background-color: var(--background-light) !important;
  color: var(--dark-text) !important;
}

.bg-dark {
  background-color: var(--dark) !important;
  color: var(--light-text) !important;
}

.bg-separator {
  background-color: var(--separator) !important;
  color: var(--light-text) !important;
}

.bg-separator-light {
  background-color: var(--separator-light) !important;
  color: var(--light-text) !important;
}

.bg-theme {
  background-color: var(--background-theme) !important;
}

.bg-muted {
  background-color: var(--muted) !important;
  color: var(--light-text) !important;
}

.bg-alternate {
  background-color: var(--alternate) !important;
}

.bg-body {
  background-color: var(--body) !important;
}

.bg-foreground {
  background-color: var(--foreground) !important;
  color: var(--primary) !important;
}

.bg-background {
  background-color: var(--background) !important;
  color: var(--primary) !important;
}

.bg-outline-primary {
  box-shadow: inset 0 0 0 1px var(--primary) !important;
  color: var(--primary) !important;
}

.bg-outline-secondary {
  box-shadow: inset 0 0 0 1px var(--secondary) !important;
  color: var(--secondary) !important;
}

.bg-outline-tertiary {
  box-shadow: inset 0 0 0 1px var(--tertiary) !important;
  color: var(--tertiary) !important;
}

.bg-outline-quaternary {
  box-shadow: inset 0 0 0 1px var(--quaternary) !important;
  color: var(--quaternary) !important;
}

.bg-outline-success {
  box-shadow: inset 0 0 0 1px var(--success) !important;
  color: var(--success) !important;
}

.bg-outline-danger {
  box-shadow: inset 0 0 0 1px var(--danger) !important;
  color: var(--danger) !important;
}

.bg-outline-warning {
  box-shadow: inset 0 0 0 1px var(--warning) !important;
  color: var(--warning) !important;
}

.bg-outline-info {
  box-shadow: inset 0 0 0 1px var(--info) !important;
  color: var(--info) !important;
}

.bg-outline-light {
  box-shadow: inset 0 0 0 1px var(--light) !important;
  color: var(--light) !important;
}

.bg-outline-dark {
  box-shadow: inset 0 0 0 1px var(--dark) !important;
  color: var(--dark) !important;
}

.bg-outline-muted {
  box-shadow: inset 0 0 0 1px var(--muted) !important;
  color: var(--muted) !important;
}

.bg-outline-body {
  box-shadow: inset 0 0 0 1px var(--body) !important;
  color: var(--body) !important;
}

.bg-outline-alternate {
  box-shadow: inset 0 0 0 1px var(--alternate) !important;
  color: var(--alternate) !important;
}

.bg-outline-separator {
  box-shadow: inset 0 0 0 1px var(--separator) !important;
  color: var(--separator) !important;
}

.bg-outline-foreground {
  box-shadow: inset 0 0 0 1px var(--foreground) !important;
  color: var(--primary) !important;
}

.bg-outline-background {
  box-shadow: inset 0 0 0 1px var(--background) !important;
  color: var(--primary) !important;
}

.bg-gradient-single-1 {
  background-color: var(--gradient-1) !important;
}

.bg-gradient-single-2 {
  background-color: var(--gradient-2) !important;
}

.bg-gradient-single-3 {
  background-color: var(--gradient-3) !important;
}

.bg-gradient-light {
  background-image: linear-gradient(160deg, var(--gradient-1), var(--gradient-1), var(--gradient-1-lighter)) !important;
}

.bg-gradient-dark {
  background-image: linear-gradient(160deg, var(--gradient-1), var(--gradient-1), var(--gradient-1-darker)) !important;
}

a:focus.bg-primary,
a:hover.bg-primary {
  background-color: var(--primary-darker) !important;
  border-color: var(--primary-darker) !important;
  color: var(--light-text) !important;
}
a:focus.bg-secondary,
a:hover.bg-secondary {
  background-color: var(--secondary-darker) !important;
  border-color: var(--secondary-darker) !important;
  color: var(--light-text) !important;
}
a:focus.bg-tertiary,
a:hover.bg-tertiary {
  background-color: var(--tertiary-darker) !important;
  border-color: var(--tertiary-darker) !important;
  color: var(--light-text) !important;
}
a:focus.bg-quaternary,
a:hover.bg-quaternary {
  background-color: var(--quaternary-darker) !important;
  border-color: var(--quaternary-darker) !important;
  color: var(--light-text) !important;
}
a:focus.bg-warning,
a:hover.bg-warning {
  background-color: var(--warning-darker) !important;
  border-color: var(--warning-darker) !important;
  color: var(--light-text) !important;
}
a:focus.bg-danger,
a:hover.bg-danger {
  background-color: var(--danger-darker) !important;
  border-color: var(--danger-darker) !important;
  color: var(--light-text) !important;
}
a:focus.bg-success,
a:hover.bg-success {
  background-color: var(--success-darker) !important;
  border-color: var(--success-darker) !important;
  color: var(--light-text) !important;
}
a:focus.bg-info,
a:hover.bg-info {
  background-color: var(--info-darker) !important;
  border-color: var(--info-darker) !important;
  color: var(--light-text) !important;
}
a:focus.bg-light,
a:hover.bg-light {
  background-color: var(--light-darker) !important;
  border-color: var(--light-darker) !important;
  color: var(--dark-text) !important;
}
a:focus.bg-dark,
a:hover.bg-dark {
  background-color: var(--dark-darker) !important;
  border-color: var(--dark-darker) !important;
  color: var(--light-text) !important;
}
a:focus.bg-muted,
a:hover.bg-muted {
  background-color: var(--muted-darker) !important;
  border-color: var(--muted-darker) !important;
  color: var(--light-text) !important;
}
a:focus.bg-separator,
a:hover.bg-separator {
  background-color: var(--separator-darker) !important;
  border-color: var(--separator-darker) !important;
  color: var(--light-text) !important;
}

/*
*
* Badge
*
* Bootstrap badge styles.
*
*/
.badge {
  padding: 0.3em 0.75em;
  font-size: 0.7em;
  font-weight: 400;
  border-radius: var(--border-radius-md);
  color: var(--light-text);
  text-indent: -1px;
  line-height: 1.25;
}

.btn .badge {
  padding-top: 1px;
  padding-bottom: 1px;
}

a.badge,
.btn.badge {
  box-shadow: initial !important;
}

.badge {
  background: unset;
  border: initial !important;
}

/*
*
* Border
*
* Bootstrap border utilities and additional ones.
*
*/
.rounded-top {
  border-top-left-radius: var(--border-radius-lg) !important;
  border-top-right-radius: var(--border-radius-lg) !important;
}

.rounded-top-start {
  border-top-left-radius: var(--border-radius-lg) !important;
}

.rounded-top-end {
  border-top-right-radius: var(--border-radius-lg) !important;
}

.rounded-end {
  border-top-right-radius: var(--border-radius-lg) !important;
  border-bottom-right-radius: var(--border-radius-lg) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--border-radius-lg) !important;
  border-bottom-left-radius: var(--border-radius-lg) !important;
}

.rounded-bottom-start {
  border-bottom-left-radius: var(--border-radius-lg) !important;
}

.rounded-bottom-end {
  border-bottom-right-radius: var(--border-radius-lg) !important;
}

.rounded-start {
  border-top-left-radius: var(--border-radius-lg) !important;
  border-bottom-left-radius: var(--border-radius-lg) !important;
}

.rounded-pill {
  border-radius: 50px !important;
}

.rounded,
.rounded-lg {
  border-radius: var(--border-radius-lg) !important;
}

.rounded-xl {
  border-radius: var(--border-radius-xl) !important;
}

.rounded-xl-top {
  border-top-left-radius: var(--border-radius-lg) !important;
  border-top-right-radius: var(--border-radius-lg) !important;
}

.rounded-xl-top-start {
  border-top-left-radius: var(--border-radius-lg) !important;
}

.rounded-xl-top-end {
  border-top-right-radius: var(--border-radius-lg) !important;
}

.rounded-xl-end {
  border-top-right-radius: var(--border-radius-lg) !important;
  border-bottom-right-radius: var(--border-radius-lg) !important;
}

.rounded-xl-bottom {
  border-bottom-right-radius: var(--border-radius-lg) !important;
  border-bottom-left-radius: var(--border-radius-lg) !important;
}

.rounded-xl-bottom-start {
  border-bottom-left-radius: var(--border-radius-lg) !important;
}

.rounded-xl-bottom-end {
  border-bottom-right-radius: var(--border-radius-lg) !important;
}

.rounded-xl-start {
  border-top-left-radius: var(--border-radius-lg) !important;
  border-bottom-left-radius: var(--border-radius-lg) !important;
}

.rounded-sm {
  border-radius: var(--border-radius-sm) !important;
}

.rounded-sm-top {
  border-top-left-radius: var(--border-radius-sm) !important;
  border-top-right-radius: var(--border-radius-sm) !important;
}

.rounded-sm-top-start {
  border-top-left-radius: var(--border-radius-sm) !important;
}

.rounded-sm-top-end {
  border-top-right-radius: var(--border-radius-sm) !important;
}

.rounded-sm-end {
  border-top-right-radius: var(--border-radius-sm) !important;
  border-bottom-right-radius: var(--border-radius-sm) !important;
}

.rounded-sm-bottom {
  border-bottom-right-radius: var(--border-radius-sm) !important;
  border-bottom-left-radius: var(--border-radius-sm) !important;
}

.rounded-sm-bottom-start {
  border-bottom-left-radius: var(--border-radius-sm) !important;
}

.rounded-sm-bottom-end {
  border-bottom-right-radius: var(--border-radius-sm) !important;
}

.rounded-sm-start {
  border-top-left-radius: var(--border-radius-sm) !important;
  border-bottom-left-radius: var(--border-radius-sm) !important;
}

.rounded-md {
  border-radius: var(--border-radius-md) !important;
}

.rounded-md-top {
  border-top-left-radius: var(--border-radius-md) !important;
  border-top-right-radius: var(--border-radius-md) !important;
}

.rounded-md-top-start {
  border-top-left-radius: var(--border-radius-md) !important;
}

.rounded-md-top-end {
  border-top-right-radius: var(--border-radius-md) !important;
}

.rounded-md-end {
  border-top-right-radius: var(--border-radius-md) !important;
  border-bottom-right-radius: var(--border-radius-md) !important;
}

.rounded-md-bottom {
  border-bottom-right-radius: var(--border-radius-md) !important;
  border-bottom-left-radius: var(--border-radius-md) !important;
}

.rounded-md-bottom-start {
  border-bottom-left-radius: var(--border-radius-md) !important;
}

.rounded-md-bottom-end {
  border-bottom-right-radius: var(--border-radius-md) !important;
}

.rounded-md-start {
  border-top-left-radius: var(--border-radius-md) !important;
  border-bottom-left-radius: var(--border-radius-md) !important;
}

/* Colors */
.border {
  border-color: var(--separator) !important;
}

.border-bottom {
  border-bottom: 1px solid var(--separator) !important;
}

.border-top {
  border-top: 1px solid var(--separator) !important;
}

.border-start {
  border-left: 1px solid var(--separator) !important;
}

.border-end {
  border-right: 1px solid var(--separator) !important;
}

.border-primary {
  border-color: var(--primary) !important;
}

.border-secondary {
  border-color: var(--secondary) !important;
}

.border-tertiary {
  border-color: var(--tertiary) !important;
}

.border-quaternary {
  border-color: var(--quaternary) !important;
}

.border-separator {
  border-color: var(--separator) !important;
}

.border-separator-light {
  border-color: var(--separator-light) !important;
}

.border-muted {
  border-color: var(--muted) !important;
}

.border-alternate {
  border-color: var(--alternate) !important;
}

.border-body {
  border-color: var(--body) !important;
}

.border-success {
  border-color: var(--success) !important;
}

.border-danger {
  border-color: var(--danger) !important;
}

.border-warning {
  border-color: var(--warning) !important;
}

.border-info {
  border-color: var(--info) !important;
}

.border-light {
  border-color: var(--light) !important;
}

.border-dark {
  border-color: var(--dark) !important;
}

.border-white {
  border-color: var(--light-text) !important;
}

.border-foreground {
  border-color: var(--foreground) !important;
}

.border-background {
  border-color: var(--background) !important;
}

.border-transparent {
  border-color: transparent !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-last-none > *:last-child {
  border: initial !important;
}

.separator {
  border-bottom: 1px solid var(--separator);
}

.separator-light {
  border-bottom: 1px solid var(--separator-light);
}

.full-page-content-right-border {
  border-radius: initial !important;
  border-bottom-left-radius: var(--border-radius-lg) !important;
  border-bottom-right-radius: var(--border-radius-lg) !important;
}
@media (min-width: 992px) {
  .full-page-content-right-border {
    border-radius: initial !important;
    border-bottom-left-radius: var(--border-radius-lg) !important;
    border-top-left-radius: var(--border-radius-lg) !important;
  }
}

.full-page-content-single-border {
  border-radius: initial !important;
  border-bottom-left-radius: var(--border-radius-lg) !important;
  border-bottom-right-radius: var(--border-radius-lg) !important;
}

/*
*
* Button
*
* Bootstrap button styles.
*
*/
button {
  outline: initial !important;
  box-shadow: initial !important;
  text-decoration: initial !important;
}

.btn {
  font-family: var(--font);
  padding: 10px 20px;
  font-size: 1em;
  line-height: 1rem;
  border: initial;
  box-shadow: initial !important;
  transition: all var(--transition-time-short);
  transition-property: color, background-color, background-image, background;
  border-radius: var(--border-radius-md);
  color: var(--light-text) !important;
  white-space: nowrap;
}
.btn.shadow {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03) !important;
}

.btn.disabled,
.btn:disabled {
  cursor: initial;
}

.btn-icon {
  padding: 9px 20px;
  height: var(--input-height);
}
.btn-icon span {
  vertical-align: middle;
}
.btn-icon.btn-icon-only {
  padding: 9px 9px;
  flex-shrink: 0;
  width: var(--input-height);
}
.btn-icon.btn-sm.btn-icon-only {
  padding: 7px 7px;
  width: 30px;
}
.btn-icon svg {
  width: 15px;
  height: 15px;
}
.btn-icon.btn-xs {
  height: 28px;
}
.btn-icon.btn-xs svg {
  width: 12px;
  height: 12px;
  margin-top: -1px;
}

.btn-xl,
.btn-group-xl > .btn {
  padding: 15px 40px;
  line-height: 1;
  font-size: 1em;
  border-radius: var(--border-radius-md);
}
.btn-xl.btn-icon,
.btn-group-xl > .btn.btn-icon {
  padding: 13px 40px;
  height: 44px;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 11px 35px;
  line-height: 1;
  font-size: 1em;
  border-radius: var(--border-radius-md);
}
.btn-lg.btn-icon,
.btn-group-lg > .btn.btn-icon {
  padding: 9px 35px;
  height: var(--input-height);
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 8px 14px;
  font-size: 0.9em;
  border-radius: var(--border-radius-md);
  line-height: 1;
  height: 30px;
}
.btn-sm.btn-icon,
.btn-group-sm > .btn.btn-icon {
  height: 30px;
}
.btn-sm.btn-icon i,
.btn-group-sm > .btn.btn-icon i {
  font-size: 14px;
  width: 16px;
}

.btn-primary,
.btn-primary:not(:disabled):not(.disabled):focus,
.btn-primary:not(:disabled):not(.disabled).focus {
  background-color: var(--primary);
}
.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled):focus:hover,
.btn-primary:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--primary-darker);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-primary:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--primary-darker);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--primary);
}

.btn-secondary,
.btn-secondary:not(:disabled):not(.disabled):focus,
.btn-secondary:not(:disabled):not(.disabled).focus {
  background-color: var(--secondary);
}
.btn-secondary:hover,
.btn-secondary:not(:disabled):not(.disabled):focus:hover,
.btn-secondary:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--secondary-darker);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: var(--secondary);
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-secondary:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--secondary-darker);
}

.btn-tertiary,
.btn-tertiary:not(:disabled):not(.disabled):focus,
.btn-tertiary:not(:disabled):not(.disabled).focus {
  background-color: var(--tertiary);
}
.btn-tertiary:hover,
.btn-tertiary:not(:disabled):not(.disabled):focus:hover,
.btn-tertiary:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--tertiary-darker);
}

.btn-tertiary.disabled,
.btn-tertiary:disabled {
  background-color: var(--tertiary);
}

.btn-tertiary:not(:disabled):not(.disabled):active,
.btn-tertiary:not(:disabled):not(.disabled).active,
.show > .btn-tertiary:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-tertiary:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--tertiary-darker);
}

.btn-quaternary,
.btn-quaternary:not(:disabled):not(.disabled):focus,
.btn-quaternary:not(:disabled):not(.disabled).focus {
  background-color: var(--quaternary);
}
.btn-quaternary:hover,
.btn-quaternary:not(:disabled):not(.disabled):focus:hover,
.btn-quaternary:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--quaternary-darker);
}

.btn-quaternary.disabled,
.btn-quaternary:disabled {
  background-color: var(--quaternary);
}

.btn-quaternary:not(:disabled):not(.disabled):active,
.btn-quaternary:not(:disabled):not(.disabled).active,
.show > .btn-quaternary:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-quaternary:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--quaternary-darker);
}

.btn-success,
.btn-success:not(:disabled):not(.disabled):focus,
.btn-success:not(:disabled):not(.disabled).focus {
  background-color: var(--success);
}
.btn-success:hover,
.btn-success:not(:disabled):not(.disabled):focus:hover,
.btn-success:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--success-darker);
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: var(--success);
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-success:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--success-darker);
}

.btn-white,
.btn-white:not(:disabled):not(.disabled):focus,
.btn-white:not(:disabled):not(.disabled).focus {
  background-color: var(--light-text);
  color: var(--primary) !important;
}
.btn-white:hover,
.btn-white:not(:disabled):not(.disabled):focus:hover,
.btn-white:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--light-text-darker);
}

.btn-white.disabled,
.btn-white:disabled {
  background-color: var(--light-text);
}

.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-white:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--light-text-darker);
}

.btn-black,
.btn-black:not(:disabled):not(.disabled):focus,
.btn-black:not(:disabled):not(.disabled).focus {
  background-color: var(--dark-text);
  color: var(--primary) !important;
}
.btn-black:hover,
.btn-black:not(:disabled):not(.disabled):focus:hover,
.btn-black:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--dark-text-darker);
}

.btn-black.disabled,
.btn-black:disabled {
  background-color: var(--dark-text);
}

.btn-black:not(:disabled):not(.disabled):active,
.btn-black:not(:disabled):not(.disabled).active,
.show > .btn-black:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-black:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--dark-text-darker);
}

.btn-warning,
.btn-warning:not(:disabled):not(.disabled):focus,
.btn-warning:not(:disabled):not(.disabled).focus {
  background-color: var(--warning);
}
.btn-warning:hover,
.btn-warning:not(:disabled):not(.disabled):focus:hover,
.btn-warning:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--warning-darker);
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: var(--warning);
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-warning:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--warning-darker);
}

.btn-info,
.btn-info:not(:disabled):not(.disabled):focus,
.btn-info:not(:disabled):not(.disabled).focus {
  background-color: var(--info);
}
.btn-info:hover,
.btn-info:not(:disabled):not(.disabled):focus:hover,
.btn-info:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--info-darker);
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: var(--info);
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-info:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--info-darker);
}

.btn-danger,
.btn-danger:not(:disabled):not(.disabled):focus,
.btn-danger:not(:disabled):not(.disabled).focus {
  background-color: var(--danger);
}
.btn-danger:hover,
.btn-danger:not(:disabled):not(.disabled):focus:hover,
.btn-danger:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--danger-darker);
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: var(--danger);
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-danger:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--danger-darker);
}

.btn-light,
.btn-light:not(:disabled):not(.disabled):focus,
.btn-light:not(:disabled):not(.disabled).focus {
  background-color: var(--light);
  color: var(--dark-text) !important;
}
.btn-light:hover,
.btn-light:not(:disabled):not(.disabled):focus:hover,
.btn-light:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--light-darker);
}

.btn-light.disabled,
.btn-light:disabled {
  background-color: var(--light);
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-light:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--light-darker);
}

.btn-dark,
.btn-dark:not(:disabled):not(.disabled):focus,
.btn-dark:not(:disabled):not(.disabled).focus {
  background-color: var(--dark);
}
.btn-dark:hover,
.btn-dark:not(:disabled):not(.disabled):focus:hover,
.btn-dark:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--dark-darker);
}

.btn-dark.disabled,
.btn-dark:disabled {
  background-color: var(--light);
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-dark:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--dark-darker);
}

.btn-body,
.btn-body:not(:disabled):not(.disabled):focus,
.btn-body:not(:disabled):not(.disabled).focus {
  background-color: var(--body);
}
.btn-body:hover,
.btn-body:not(:disabled):not(.disabled):focus:hover,
.btn-body:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--body-darker);
}

.btn-body.disabled,
.btn-body:disabled {
  background-color: var(--light);
}

.btn-body:not(:disabled):not(.disabled):active,
.btn-body:not(:disabled):not(.disabled).active,
.show > .btn-body:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-body:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--body-darker);
}

.btn-muted,
.btn-muted:not(:disabled):not(.disabled):focus,
.btn-muted:not(:disabled):not(.disabled).focus {
  background-color: var(--muted);
}
.btn-muted:hover,
.btn-muted:not(:disabled):not(.disabled):focus:hover,
.btn-muted:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--muted-darker);
}

.btn-muted.disabled,
.btn-muted:disabled {
  background-color: var(--light);
}

.btn-muted:not(:disabled):not(.disabled):active,
.btn-muted:not(:disabled):not(.disabled).active,
.show > .btn-muted:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-muted:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--muted-darker);
}

.btn-separator,
.btn-separator:not(:disabled):not(.disabled):focus,
.btn-separator:not(:disabled):not(.disabled).focus {
  background-color: var(--separator);
}
.btn-separator:hover,
.btn-separator:not(:disabled):not(.disabled):focus:hover,
.btn-separator:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--separator-darker);
}

.btn-separator.disabled,
.btn-separator:disabled {
  background-color: var(--light);
}

.btn-separator:not(:disabled):not(.disabled):active,
.btn-separator:not(:disabled):not(.disabled).active,
.show > .btn-separator:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-separator:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--separator-darker);
}

.btn-separator-light,
.btn-separator-light:not(:disabled):not(.disabled):focus,
.btn-separator-light:not(:disabled):not(.disabled).focus {
  background-color: var(--separator-light);
  color: var(--body) !important;
}
.btn-separator-light:hover,
.btn-separator-light:not(:disabled):not(.disabled):focus:hover,
.btn-separator-light:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--separator-darker);
  color: var(--light-text) !important;
}

.btn-separator-light.disabled,
.btn-separator-light:disabled {
  background-color: var(--light);
}

.btn-separator-light:not(:disabled):not(.disabled):active,
.btn-separator-light:not(:disabled):not(.disabled).active,
.show > .btn-separator-light:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-separator-light:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--separator-darker);
}

.btn-alternate,
.btn-alternate:not(:disabled):not(.disabled):focus,
.btn-alternate:not(:disabled):not(.disabled).focus {
  background-color: var(--alternate);
}
.btn-alternate:hover,
.btn-alternate:not(:disabled):not(.disabled):focus:hover,
.btn-alternate:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--alternate-darker);
}

.btn-alternate.disabled,
.btn-alternate:disabled {
  background-color: var(--light);
}

.btn-alternate:not(:disabled):not(.disabled):active,
.btn-alternate:not(:disabled):not(.disabled).active,
.show > .btn-alternate:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-alternate:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--alternate-darker);
}

.btn-link,
.btn-link:not(:disabled):not(.disabled):focus,
.btn-link:not(:disabled):not(.disabled).focus {
  transition: color var(--transition-time-short);
  color: var(--primary) !important;
  text-decoration: initial !important;
}
.btn-link:hover, .btn-link:active,
.btn-link:not(:disabled):not(.disabled):focus:hover,
.btn-link:not(:disabled):not(.disabled):focus:active,
.btn-link:not(:disabled):not(.disabled).focus:hover,
.btn-link:not(:disabled):not(.disabled).focus:active {
  text-decoration: initial;
  color: var(--secondary) !important;
}

.btn-link.disabled,
.btn-link:disabled {
  background-color: var(--link);
}

.btn-link:not(:disabled):not(.disabled):active,
.btn-link:not(:disabled):not(.disabled).active,
.show > .btn-link:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-link:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  color: var(--primary-darker) !important;
}

.btn-outline-primary {
  box-shadow: inset 0 0 0 1px var(--primary) !important;
  color: var(--primary) !important;
}
.btn-outline-primary:hover {
  color: var(--light-text) !important;
  background-color: var(--primary);
  box-shadow: initial !important;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  box-shadow: inset 0 0 0 1px var(--primary) !important;
  background: initial;
  color: var(--primary) !important;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-outline-primary:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--primary);
  box-shadow: inset 0 0 0 1px var(--primary) !important;
  color: var(--light-text) !important;
}

.btn-outline-secondary {
  box-shadow: inset 0 0 0 1px var(--secondary) !important;
  color: var(--secondary) !important;
}
.btn-outline-secondary:hover {
  color: var(--light-text) !important;
  background-color: var(--secondary);
  box-shadow: initial !important;
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  box-shadow: inset 0 0 0 1px var(--secondary) !important;
  background: initial;
  color: var(--secondary) !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-outline-secondary:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--secondary);
  box-shadow: inset 0 0 0 1px var(--secondary) !important;
  color: var(--light-text) !important;
}

.btn-outline-tertiary {
  box-shadow: inset 0 0 0 1px var(--tertiary) !important;
  color: var(--tertiary) !important;
}
.btn-outline-tertiary:hover {
  color: var(--light-text) !important;
  background-color: var(--tertiary);
  box-shadow: initial !important;
}

.btn-outline-tertiary.disabled,
.btn-outline-tertiary:disabled {
  box-shadow: inset 0 0 0 1px var(--tertiary) !important;
  background: initial;
  color: var(--tertiary) !important;
}

.btn-outline-tertiary:not(:disabled):not(.disabled):active,
.btn-outline-tertiary:not(:disabled):not(.disabled).active,
.show > .btn-outline-tertiary:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-outline-tertiary:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--tertiary);
  box-shadow: inset 0 0 0 1px var(--tertiary) !important;
  color: var(--light-text) !important;
}

.btn-outline-quaternary {
  box-shadow: inset 0 0 0 1px var(--quaternary) !important;
  color: var(--quaternary) !important;
}
.btn-outline-quaternary:hover {
  color: var(--light-text) !important;
  background-color: var(--quaternary);
  box-shadow: initial !important;
}

.btn-outline-quaternary.disabled,
.btn-outline-quaternary:disabled {
  box-shadow: inset 0 0 0 1px var(--quaternary) !important;
  background: initial;
  color: var(--quaternary) !important;
}

.btn-outline-quaternary:not(:disabled):not(.disabled):active,
.btn-outline-quaternary:not(:disabled):not(.disabled).active,
.show > .btn-outline-quaternary:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-outline-quaternary:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--quaternary);
  box-shadow: inset 0 0 0 1px var(--quaternary) !important;
  color: var(--light-text) !important;
}

.btn-outline-success {
  box-shadow: inset 0 0 0 1px var(--success) !important;
  color: var(--success) !important;
}
.btn-outline-success:hover {
  color: var(--light-text) !important;
  background-color: var(--success);
  box-shadow: initial !important;
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  box-shadow: inset 0 0 0 1px var(--success) !important;
  background: initial;
  color: var(--success) !important;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-outline-success:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--success);
  box-shadow: inset 0 0 0 1px var(--success) !important;
  color: var(--light-text) !important;
}

.btn-outline-warning {
  box-shadow: inset 0 0 0 1px var(--warning) !important;
  color: var(--warning) !important;
}
.btn-outline-warning:hover {
  color: var(--light-text) !important;
  background-color: var(--warning);
  box-shadow: initial !important;
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  box-shadow: inset 0 0 0 1px var(--warning) !important;
  background: initial;
  color: var(--warning) !important;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-outline-warning:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--warning);
  box-shadow: inset 0 0 0 1px var(--warning) !important;
  color: var(--light-text) !important;
}

.btn-outline-light {
  box-shadow: inset 0 0 0 1px var(--light) !important;
  color: var(--light) !important;
}
.btn-outline-light:hover {
  color: var(--dark-text) !important;
  background-color: var(--light);
  box-shadow: initial !important;
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  box-shadow: inset 0 0 0 1px var(--light) !important;
  background: initial;
  color: var(--light) !important;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-outline-light:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--light);
  box-shadow: inset 0 0 0 1px var(--light) !important;
  color: var(--dark-text) !important;
}

.btn-outline-white {
  box-shadow: inset 0 0 0 1px var(--light-text) !important;
  color: var(--light-text) !important;
}
.btn-outline-white:hover {
  color: var(--dark-text) !important;
  background-color: var(--light-text);
  box-shadow: initial !important;
}

.btn-outline-white.disabled,
.btn-outline-white:disabled {
  box-shadow: inset 0 0 0 1px var(--light-text) !important;
  background: initial;
  color: var(--light-text) !important;
}

.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-white:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-outline-white:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--light-text);
  box-shadow: inset 0 0 0 1px var(--light-text) !important;
  color: var(--dark-text) !important;
}

.btn-outline-black {
  box-shadow: inset 0 0 0 1px var(--dark-text) !important;
  color: var(--dark-text) !important;
}
.btn-outline-black:hover {
  color: var(--light-text) !important;
  background-color: var(--dark-text);
  box-shadow: initial !important;
}

.btn-outline-black.disabled,
.btn-outline-black:disabled {
  box-shadow: inset 0 0 0 1px var(--dark-text) !important;
  background: initial;
  color: var(--dark-text) !important;
}

.btn-outline-black:not(:disabled):not(.disabled):active,
.btn-outline-black:not(:disabled):not(.disabled).active,
.show > .btn-outline-black:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-outline-black:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--dark-text);
  box-shadow: inset 0 0 0 1px var(--dark-text) !important;
  color: var(--light-text) !important;
}

.btn-outline-info {
  box-shadow: inset 0 0 0 1px var(--info) !important;
  color: var(--info) !important;
}
.btn-outline-info:hover {
  color: var(--light-text) !important;
  background-color: var(--info);
  box-shadow: initial !important;
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  box-shadow: inset 0 0 0 1px var(--info) !important;
  background: initial;
  color: var(--info) !important;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-outline-info:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--info);
  box-shadow: inset 0 0 0 1px var(--info) !important;
  color: var(--light-text) !important;
}

.btn-outline-danger {
  box-shadow: inset 0 0 0 1px var(--danger) !important;
  color: var(--danger) !important;
}
.btn-outline-danger:hover {
  color: var(--light-text) !important;
  background-color: var(--danger);
  box-shadow: initial !important;
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  box-shadow: inset 0 0 0 1px var(--info) !important;
  background: initial;
  color: var(--danger) !important;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-outline-danger:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--danger);
  box-shadow: inset 0 0 0 1px var(--danger) !important;
  color: var(--light-text) !important;
}

.btn-outline-dark {
  box-shadow: inset 0 0 0 1px var(--dark) !important;
  color: var(--dark) !important;
}
.btn-outline-dark:hover {
  color: var(--light-text) !important;
  background-color: var(--dark);
  box-shadow: initial !important;
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  box-shadow: inset 0 0 0 1px var(--dark) !important;
  background: initial;
  color: var(--dark) !important;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-outline-dark:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--dark);
  box-shadow: inset 0 0 0 1px var(--dark) !important;
  color: var(--light-text) !important;
}

.btn-outline-body {
  box-shadow: inset 0 0 0 1px var(--body) !important;
  color: var(--body) !important;
}
.btn-outline-body:hover {
  color: var(--light-text) !important;
  background-color: var(--body);
  box-shadow: initial !important;
}

.btn-outline-body.disabled,
.btn-outline-body:disabled {
  box-shadow: inset 0 0 0 1px var(--body) !important;
  background: initial;
  color: var(--body) !important;
}

.btn-outline-body:not(:disabled):not(.disabled):active,
.btn-outline-body:not(:disabled):not(.disabled).active,
.show > .btn-outline-body:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-outline-body:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--body);
  box-shadow: inset 0 0 0 1px var(--body) !important;
  color: var(--light-text) !important;
}

.btn-outline-muted {
  box-shadow: inset 0 0 0 1px var(--muted) !important;
  color: var(--muted) !important;
}
.btn-outline-muted:hover {
  color: var(--light-text) !important;
  background-color: var(--muted);
  box-shadow: initial !important;
}

.btn-outline-muted.disabled,
.btn-outline-muted:disabled {
  box-shadow: inset 0 0 0 1px var(--muted) !important;
  background: initial;
  color: var(--muted) !important;
}

.btn-outline-muted:not(:disabled):not(.disabled):active,
.btn-outline-muted:not(:disabled):not(.disabled).active,
.show > .btn-outline-muted:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-outline-muted:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--muted);
  box-shadow: inset 0 0 0 1px var(--muted) !important;
  color: var(--light-text) !important;
}

.btn-outline-separator {
  box-shadow: inset 0 0 0 1px var(--separator) !important;
  color: var(--separator) !important;
}
.btn-outline-separator:hover {
  color: var(--light-text) !important;
  background-color: var(--separator);
  box-shadow: initial !important;
}

.btn-outline-separator.disabled,
.btn-outline-separator:disabled {
  box-shadow: inset 0 0 0 1px var(--separator) !important;
  background: initial;
  color: var(--separator) !important;
}

.btn-outline-separator:not(:disabled):not(.disabled):active,
.btn-outline-separator:not(:disabled):not(.disabled).active,
.show > .btn-outline-separator:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-outline-separator:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--separator);
  box-shadow: inset 0 0 0 1px var(--separator) !important;
  color: var(--light-text) !important;
}

.btn-outline-alternate {
  box-shadow: inset 0 0 0 1px var(--alternate) !important;
  color: var(--alternate) !important;
}
.btn-outline-alternate:hover {
  color: var(--light-text) !important;
  background-color: var(--alternate);
  box-shadow: initial !important;
}

.btn-outline-alternate.disabled,
.btn-outline-alternate:disabled {
  box-shadow: inset 0 0 0 1px var(--alternate) !important;
  background: initial;
  color: var(--alternate) !important;
}

.btn-outline-alternate:not(:disabled):not(.disabled):active,
.btn-outline-alternate:not(:disabled):not(.disabled).active,
.show > .btn-outline-alternate:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-outline-alternate:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--alternate);
  box-shadow: inset 0 0 0 1px var(--alternate) !important;
  color: var(--light-text) !important;
}

.btn-gradient-primary,
.btn-gradient-primary:not(:disabled):not(.disabled):focus,
.btn-gradient-primary:not(:disabled):not(.disabled).focus {
  z-index: 1;
  position: relative;
  background-color: var(--primary);
  background-image: linear-gradient(to right, var(--primary), var(--primary), rgba(var(--secondary-rgb), 0.5));
}
.btn-gradient-primary::before,
.btn-gradient-primary:not(:disabled):not(.disabled):focus::before,
.btn-gradient-primary:not(:disabled):not(.disabled).focus::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transition: opacity var(--transition-time-short) linear;
  opacity: 0;
  background-image: linear-gradient(to right, var(--primary-darker), var(--primary-darker));
  border-radius: var(--border-radius-md);
}
.btn-gradient-primary:hover::before,
.btn-gradient-primary:not(:disabled):not(.disabled):focus:hover::before,
.btn-gradient-primary:not(:disabled):not(.disabled).focus:hover::before {
  opacity: 1;
}

.btn-gradient-primary:not(:disabled):not(.disabled):active,
.btn-gradient-primary:not(:disabled):not(.disabled).active,
.show > .btn-gradient-primary:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-gradient-primary:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-image: linear-gradient(to right, var(--primary-darker), var(--primary-darker));
}
.btn-gradient-primary:not(:disabled):not(.disabled):active::before,
.btn-gradient-primary:not(:disabled):not(.disabled).active::before,
.show > .btn-gradient-primary:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true]::before,
.show > .btn-gradient-primary:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true]::before {
  transition: opacity var(--transition-time-short) linear;
  opacity: 0;
}

.btn-gradient-primary.disabled,
.btn-gradient-primary:disabled {
  background-color: var(--primary);
  background-image: linear-gradient(to right, var(--primary), var(--primary), rgba(var(--secondary-rgb), 0.5));
}
.btn-gradient-primary.disabled::before,
.btn-gradient-primary:disabled::before {
  opacity: 0;
}

.btn-gradient-secondary,
.btn-gradient-secondary:not(:disabled):not(.disabled):focus,
.btn-gradient-secondary:not(:disabled):not(.disabled).focus {
  z-index: 1;
  position: relative;
  background-color: var(--secondary);
  background-image: linear-gradient(to right, var(--secondary), var(--secondary), rgba(var(--primary-rgb), 0.4));
}
.btn-gradient-secondary::before,
.btn-gradient-secondary:not(:disabled):not(.disabled):focus::before,
.btn-gradient-secondary:not(:disabled):not(.disabled).focus::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transition: opacity var(--transition-time-short) linear;
  opacity: 0;
  background-image: linear-gradient(to right, var(--secondary-darker), var(--secondary-darker));
  border-radius: var(--border-radius-md);
}
.btn-gradient-secondary:hover::before,
.btn-gradient-secondary:not(:disabled):not(.disabled):focus:hover::before,
.btn-gradient-secondary:not(:disabled):not(.disabled).focus:hover::before {
  opacity: 1;
}

.btn-gradient-secondary:not(:disabled):not(.disabled):active,
.btn-gradient-secondary:not(:disabled):not(.disabled).active,
.show > .btn-gradient-secondary:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-gradient-secondary:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-image: linear-gradient(to right, var(--secondary-darker), var(--secondary-darker));
}
.btn-gradient-secondary:not(:disabled):not(.disabled):active::before,
.btn-gradient-secondary:not(:disabled):not(.disabled).active::before,
.show > .btn-gradient-secondary:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true]::before,
.show > .btn-gradient-secondary:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true]::before {
  transition: opacity var(--transition-time-short) linear;
  opacity: 0;
}

.btn-gradient-secondary.disabled,
.btn-gradient-secondary:disabled {
  background-color: var(--secondary);
  background-image: linear-gradient(to right, var(--secondary), var(--secondary), rgba(var(--primary-rgb), 0.4));
}
.btn-gradient-secondary.disabled::before,
.btn-gradient-secondary:disabled::before {
  opacity: 0;
}

.btn-group-vertical > .btn:first-child,
.btn-group-vertical > .btn-group:first-child > .btn {
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
}

.btn-group-vertical > .btn:last-child,
.btn-group-vertical > .btn-group:last-child > .btn {
  border-bottom-left-radius: var(--border-radius-md);
  border-bottom-right-radius: var(--border-radius-md);
}

.btn-foreground,
.btn-foreground:not(:disabled):not(.disabled):focus,
.btn-foreground:not(:disabled):not(.disabled).focus {
  background-color: var(--foreground);
  color: var(--primary) !important;
}
.btn-foreground:hover,
.btn-foreground:not(:disabled):not(.disabled):focus:hover,
.btn-foreground:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--foreground);
  color: var(--secondary) !important;
}

.btn-foreground:not(:disabled):not(.disabled):active,
.btn-foreground:not(:disabled):not(.disabled).active,
.show > .btn-foreground:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-foreground:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--foreground);
  color: var(--primary) !important;
}

.btn-foreground.disabled,
.btn-foreground:disabled {
  background-color: var(--foreground);
  color: var(--primary) !important;
}

.btn-foreground-alternate,
.btn-foreground-alternate:not(:disabled):not(.disabled):focus,
.btn-foreground-alternate:not(:disabled):not(.disabled).focus {
  background-color: var(--foreground);
  color: var(--alternate) !important;
}
.btn-foreground-alternate:hover,
.btn-foreground-alternate:not(:disabled):not(.disabled):focus:hover,
.btn-foreground-alternate:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--foreground);
  color: var(--primary) !important;
}

.btn-foreground-alternate:not(:disabled):not(.disabled):active,
.btn-foreground-alternate:not(:disabled):not(.disabled).active,
.show > .btn-foreground-alternate:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-foreground-alternate:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--foreground);
  color: var(--alternate) !important;
}

.btn-foreground-alternate.disabled,
.btn-foreground-alternate:disabled {
  background-color: var(--foreground);
  color: var(--alternate) !important;
}

.btn-background-alternate,
.btn-background-alternate:not(:disabled):not(.disabled):focus,
.btn-background-alternate:not(:disabled):not(.disabled).focus {
  background-color: var(--background);
  color: var(--alternate) !important;
}
.btn-background-alternate:hover,
.btn-background-alternate:not(:disabled):not(.disabled):focus:hover,
.btn-background-alternate:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--background);
  color: var(--primary) !important;
}

.btn-background-alternate:not(:disabled):not(.disabled):active,
.btn-background-alternate:not(:disabled):not(.disabled).active,
.show > .btn-background-alternate:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-background-alternate:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--background);
  color: var(--alternate) !important;
}

.btn-background-alternate.disabled,
.btn-background-alternate:disabled {
  background-color: var(--background);
  color: var(--alternate) !important;
}

.btn-background,
.btn-background:not(:disabled):not(.disabled):focus,
.btn-background:not(:disabled):not(.disabled).focus {
  background-color: var(--background);
  color: var(--primary) !important;
}
.btn-background:hover,
.btn-background:not(:disabled):not(.disabled):focus:hover,
.btn-background:not(:disabled):not(.disabled).focus:hover {
  background-color: var(--background);
  color: var(--secondary) !important;
}

.btn-background:not(:disabled):not(.disabled):active,
.btn-background:not(:disabled):not(.disabled).active,
.show > .btn-background:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-background:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--background);
  color: var(--primary) !important;
}

.btn-background.disabled,
.btn-background:disabled {
  background-color: var(--background);
  color: var(--primary) !important;
}

.btn-foreground.hover-outline,
.btn-foreground.hover-outline:not(:disabled):not(.disabled):focus,
.btn-foreground.hover-outline:not(:disabled):not(.disabled).focus {
  background-color: var(--foreground);
  color: var(--primary) !important;
}
.btn-foreground.hover-outline:hover,
.btn-foreground.hover-outline:not(:disabled):not(.disabled):focus:hover,
.btn-foreground.hover-outline:not(:disabled):not(.disabled).focus:hover {
  color: var(--primary) !important;
  box-shadow: inset 0 0 0 1px var(--primary) !important;
}

.btn-foreground.hover-outline:not(:disabled):not(.disabled):active,
.btn-foreground.hover-outline:not(:disabled):not(.disabled).active,
.show > .btn-foreground.hover-outline:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-foreground.hover-outline:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  background-color: var(--foreground);
  color: var(--primary) !important;
  box-shadow: inset 0 0 0 1px var(--primary) !important;
}

.btn-foreground.hover-outline.disabled,
.btn-foreground.hover-outline:disabled {
  background-color: var(--foreground);
  color: var(--primary) !important;
}

.btn-background.hover-outline,
.btn-background.hover-outline:not(:disabled):not(.disabled):focus,
.btn-background.hover-outline:not(:disabled):not(.disabled).focus {
  background-color: var(--background);
  color: var(--primary) !important;
}
.btn-background.hover-outline:hover,
.btn-background.hover-outline:not(:disabled):not(.disabled):focus:hover,
.btn-background.hover-outline:not(:disabled):not(.disabled).focus:hover {
  color: var(--primary) !important;
  background-color: var(--foreground);
  box-shadow: inset 0 0 0 1px var(--primary) !important;
}

.btn-background.hover-outline:not(:disabled):not(.disabled):active,
.btn-background.hover-outline:not(:disabled):not(.disabled).active,
.show > .btn-background.hover-outline:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true],
.show > .btn-background.hover-outline:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true] {
  color: var(--primary) !important;
  background-color: var(--foreground);
  box-shadow: inset 0 0 0 1px var(--primary) !important;
}

.btn-background.hover-outline.disabled,
.btn-background.hover-outline:disabled {
  background-color: var(--background);
  color: var(--primary) !important;
}

.btn-overlay.btn-foreground {
  background-color: rgba(var(--foreground-rgb), 0.7);
}
.btn-overlay.btn-foreground:hover {
  background-color: rgba(var(--foreground-rgb), 1);
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true]:active:focus,
.show > .btn-outline-primary:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true]:active:focus {
  color: var(--light-text) !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true]:active:focus,
.show > .btn-outline-secondary:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true]:active:focus {
  color: var(--light-text) !important;
}

.btn-outline-tertiary:not(:disabled):not(.disabled):active:focus,
.btn-outline-tertiary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-tertiary:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true]:active:focus,
.show > .btn-outline-tertiary:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true]:active:focus {
  color: var(--light-text) !important;
}

.btn-outline-quaternary:not(:disabled):not(.disabled):active:focus,
.btn-outline-quaternary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-quaternary:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true]:active:focus,
.show > .btn-outline-quaternary:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true]:active:focus {
  color: var(--light-text) !important;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true]:active:focus,
.show > .btn-outline-success:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true]:active:focus {
  color: var(--light-text) !important;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true]:active:focus,
.show > .btn-outline-warning:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true]:active:focus {
  color: var(--light-text) !important;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true]:active:focus,
.show > .btn-outline-info:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true]:active:focus {
  color: var(--light-text) !important;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true]:active:focus,
.show > .btn-outline-danger:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true]:active:focus {
  color: var(--light-text) !important;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true]:active:focus,
.show > .btn-outline-light:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true]:active:focus {
  color: var(--dark-text) !important;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true]:active:focus,
.show > .btn-outline-dark:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true]:active:focus {
  color: var(--light-text) !important;
}

.btn-outline-body:not(:disabled):not(.disabled):active:focus,
.btn-outline-body:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-body:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true]:active:focus,
.show > .btn-outline-body:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true]:active:focus {
  color: var(--light-text) !important;
}

.btn-outline-alternate:not(:disabled):not(.disabled):active:focus,
.btn-outline-alternate:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-alternate:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true]:active:focus,
.show > .btn-outline-alternate:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true]:active:focus {
  color: var(--light-text) !important;
}

.btn-outline-muted:not(:disabled):not(.disabled):active:focus,
.btn-outline-muted:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-muted:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true]:active:focus,
.show > .btn-outline-muted:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true]:active:focus {
  color: var(--light-text) !important;
}

.btn-outline-separator:not(:disabled):not(.disabled):active:focus,
.btn-outline-separator:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-separator:not(:disabled):not(.disabled)[data-bs-toggle=dropdown][aria-expanded=true]:active:focus,
.show > .btn-outline-separator:not(:disabled):not(.disabled).dropdown-toggle[aria-expanded=true]:active:focus {
  color: var(--light-text) !important;
}

.dropdown-toggle-split {
  padding-left: 12px;
  padding-right: 12px;
}

.btn-xl + .dropdown-toggle-split,
.btn-group-xl > .btn + .dropdown-toggle-split {
  padding-left: 20px;
  padding-right: 20px;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: 15px;
  padding-right: 15px;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: 10px;
  padding-right: 10px;
}

.btn-close {
  width: calc(var(--input-height) / 2);
  height: calc(var(--input-height) / 2);
  margin: 0;
  padding: 0.25rem;
  background: initial;
  opacity: 1;
  font-size: 18px;
  font-family: "CS-Interface" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--alternate);
}
.btn-close:after {
  display: inline-block;
  content: "\e91b";
}
.btn-close:hover:after {
  color: var(--primary);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.5;
}

.btn-custom-control .form-check-input {
  pointer-events: none;
  margin-top: 0.25rem !important;
  background-color: transparent;
}
.btn-custom-control .form-check {
  margin-bottom: 0;
}
.btn-custom-control.btn-sm {
  padding-top: 3px;
}

[class*=btn-outline-].btn-custom-control:hover .form-check-input {
  border-color: var(--light-text) !important;
}

.btn-outline-primary.btn-custom-control .form-check-input {
  border-color: var(--primary) !important;
}
.btn-outline-primary.btn-custom-control .form-check-input:checked {
  background-color: var(--primary) !important;
}

.btn-outline-secondary.btn-custom-control .form-check-input {
  border-color: var(--secondary) !important;
}
.btn-outline-secondary.btn-custom-control .form-check-input:checked {
  background-color: var(--secondary) !important;
}
.btn-outline-secondary.btn-custom-control .form-check-input[type=checkbox]:indeterminate {
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-primary.btn-custom-control .form-check-input,
.btn-secondary.btn-custom-control .form-check-input {
  border-color: var(--light-text) !important;
}
.btn-primary.btn-custom-control .form-check-input:checked,
.btn-secondary.btn-custom-control .form-check-input:checked {
  border-color: var(--light-text) !important;
}

.check-all-container .btn-sm.btn-custom-control {
  padding-left: 10px !important;
  font-size: 0.8em;
  padding-right: 2px !important;
}
.check-all-container .btn-sm.btn-custom-control .form-check {
  padding-top: 0;
}

.check-all-container-checkbox-click .btn-custom-control .form-check,
.check-all-container .btn-custom-control .form-check {
  padding-top: 0.35rem;
}
.check-all-container-checkbox-click .btn-custom-control,
.check-all-container .btn-custom-control {
  padding-left: 0.75rem;
  padding-right: 0.25rem;
}

.hover-scale-up {
  backface-visibility: hidden;
  transition: transform var(--transition-time-short);
}
.hover-scale-up:hover {
  transform: scale(1.02);
  z-index: 1;
}

.hover-scale-down {
  backface-visibility: hidden;
  transition: transform var(--transition-time-short);
}
.hover-scale-down:hover {
  transform: scale(0.98);
}

.active-scale-up {
  backface-visibility: hidden;
  transition: transform var(--transition-time-short);
}
.active-scale-up:active {
  z-index: 1;
  transform: scale(1.05);
}

.active-scale-down {
  backface-visibility: hidden;
  transition: transform var(--transition-time-short);
}
.active-scale-down:active {
  transform: scale(0.95);
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: initial;
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  color: var(--light-text) !important;
  background-color: var(--primary);
  border-color: initial;
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
  color: var(--light-text) !important;
  background-color: var(--secondary);
  border-color: initial;
}

.btn-check:checked + .btn-foreground,
.btn-check:active + .btn-foreground,
.btn-foreground:active,
.btn-foreground.active,
.btn-foreground.dropdown-toggle.show {
  color: var(--primary) !important;
  background-color: var(--foreground);
  border-color: initial !important;
  box-shadow: inset 0 0 0 1px var(--primary) !important;
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary,
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--primary-darker);
  border-color: var(--primary-darker);
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  background-color: var(--primary-darker);
  border-color: var(--primary-darker);
}

.btn-check:not(:checked):focus + .btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  background-color: var(--secondary-darker);
  border-color: var(--secondary-darker);
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  background-color: var(--secondary-darker);
  border-color: var(--secondary-darker);
}

.btn-check:not(:checked):focus + .btn-secondary {
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) .btn,
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) .btn {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropstart.btn-group > .dropdown-toggle-split.btn {
  border-top-left-radius: var(--border-radius-md);
  border-bottom-left-radius: var(--border-radius-md);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/*
*
* Card
*
* Bootstrap card styles.
*
*/
.card {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03) !important;
  background: var(--foreground);
  border-radius: var(--border-radius-lg);
  border: initial;
}
.card.no-shadow {
  box-shadow: initial !important;
}
.card .card-body,
.card .card-footer,
.card .card-header {
  padding: var(--card-spacing);
}
.card .half-padding.card-body, .card .half-padding.card-footer, .card .half-padding.card-header {
  padding: calc(var(--card-spacing) / 2);
}
.card.sm .card-body,
.card.sm .card-footer,
.card.sm .card-header {
  padding: var(--card-spacing-sm);
}
.card .card-header .handle {
  cursor: default;
}
.card .card-header {
  background: var(--foreground);
  border-color: rgba(var(--separator-rgb), 0.5);
}
.card .card-footer {
  background: initial;
  border-color: rgba(var(--separator-rgb), 0.3);
}
.card .card-img {
  border-radius: var(--border-radius-lg);
}
.card .card-img-top {
  width: 100%;
  border-radius: initial;
  border-top-left-radius: var(--border-radius-lg);
  border-top-right-radius: var(--border-radius-lg);
}
.card .card-img-overlay {
  background: rgba(0, 0, 0, 0.5);
  border-radius: var(--border-radius-lg);
}
.card .card-img-bottom {
  width: 100%;
  border-radius: initial;
  border-bottom-left-radius: var(--border-radius-lg);
  border-bottom-right-radius: var(--border-radius-lg);
}
.card .card-img-left {
  border-radius: initial;
  border-top-left-radius: var(--border-radius-lg);
  border-bottom-left-radius: var(--border-radius-lg);
  border-bottom-right-radius: initial;
  border-top-right-radius: initial;
}
@media (max-width: 767.98px) {
  .card .card-img-left {
    border-radius: initial;
    border-bottom-left-radius: initial;
    border-top-left-radius: var(--border-radius-lg);
    border-top-right-radius: var(--border-radius-lg);
    border-bottom-right-radius: initial;
  }
}
.card [class*=card-img-horizontal] {
  border-radius: initial;
  border-top-left-radius: var(--border-radius-lg);
  border-bottom-left-radius: var(--border-radius-lg);
  border-bottom-right-radius: initial;
  border-top-right-radius: initial;
  height: 100%;
}
@media (max-width: 575.98px) {
  .card .card-img-horizontal-sm {
    border-radius: initial;
    border-bottom-left-radius: initial;
    border-top-left-radius: var(--border-radius-lg);
    border-top-right-radius: var(--border-radius-lg);
    border-bottom-right-radius: initial;
  }
}
@media (max-width: 767.98px) {
  .card .card-img-horizontal-md {
    border-radius: initial;
    border-bottom-left-radius: initial;
    border-top-left-radius: var(--border-radius-lg);
    border-top-right-radius: var(--border-radius-lg);
    border-bottom-right-radius: initial;
  }
}
@media (max-width: 991.98px) {
  .card .card-img-horizontal-lg {
    border-radius: initial;
    border-bottom-left-radius: initial;
    border-top-left-radius: var(--border-radius-lg);
    border-top-right-radius: var(--border-radius-lg);
    border-bottom-right-radius: initial;
  }
}
@media (max-width: 1199.98px) {
  .card .card-img-horizontal-xl {
    border-radius: initial;
    border-bottom-left-radius: initial;
    border-top-left-radius: var(--border-radius-lg);
    border-top-right-radius: var(--border-radius-lg);
    border-bottom-right-radius: initial;
  }
}
.card .card-footer {
  border-bottom-right-radius: var(--border-radius-lg);
  border-bottom-left-radius: var(--border-radius-lg);
}
.card .card[class*=border] {
  border: 1px solid var(--separator);
}
.card .card-header:first-child {
  border-top-left-radius: var(--border-radius-lg);
  border-top-right-radius: var(--border-radius-lg);
}
.card .card-img-overlay {
  padding: var(--card-spacing);
}
.card .card-top-buttons {
  right: 0;
  top: 0;
}
@media (max-width: 575.98px) {
  .card .card-top-buttons {
    padding: 0.35rem;
  }
}

.card.active:after,
.card.selected:after,
.card.activatable.context-menu-active:after {
  box-shadow: inset 0 0 0 1px rgba(var(--primary-rgb), 0.5), 0 4px 10px rgba(0, 0, 0, 0.03) !important;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: var(--border-radius-lg);
  z-index: 0;
  pointer-events: none;
}

.card .card {
  border-radius: var(--border-radius-md);
}

.card-top-buttons {
  position: absolute;
  right: 0;
  top: 0;
}
.card-top-buttons .btn {
  color: var(--primary) !important;
}

.card-deck {
  flex-flow: row wrap;
  margin-right: -20px;
  margin-left: -20px;
}
@media (max-width: 767.98px) {
  .card-deck {
    display: flex;
    flex-direction: column;
    margin-right: initial;
    margin-left: initial;
  }
}
.card-deck .card {
  margin-right: 20px;
  margin-left: 20px;
}
@media (max-width: 767.98px) {
  .card-deck .card {
    margin-right: initial;
    margin-left: initial;
  }
}

.bg-primary .card-header {
  background-color: var(--primary);
}

.bg-secondary .card-header {
  background-color: var(--secondary);
}

.bg-tertiary .card-header {
  background-color: var(--tertiary);
}

.bg-quaternary .card-header {
  background-color: var(--quaternary);
}

.bg-warning .card-header {
  background-color: var(--warning);
}

.bg-danger .card-header {
  background-color: var(--danger);
}

.bg-success .card-header {
  background-color: var(--success);
}

.bg-info .card-header {
  background-color: var(--info);
}

.bg-light {
  background-color: var(--background-light) !important;
}
.bg-light .card-header {
  background-color: var(--background-light) !important;
  border-color: rgba(var(--muted-rgb), 0.6);
}
.bg-light .card-footer {
  border-color: rgba(var(--muted-rgb), 0.6);
}

.bg-dark .card-header {
  background-color: var(--dark);
}

.border-primary.card {
  border: 1px solid var(--primary);
}
.border-primary.card .card-header {
  border-color: var(--primary);
}

.border-secondary {
  border: 1px solid var(--secondary);
}
.border-secondary .card-header {
  border-color: var(--secondary);
}

.border-tertiary {
  border: 1px solid var(--tertiary);
}
.border-tertiary .card-header {
  border-color: var(--tertiary);
}

.border-quaternary {
  border: 1px solid var(--quaternary);
}
.border-quaternary .card-header {
  border-color: var(--quaternary);
}

.border-info {
  border: 1px solid var(--info);
}
.border-info .card-header {
  border-color: var(--info);
}

.border-success {
  border: 1px solid var(--success);
}
.border-success .card-header {
  border-color: var(--success);
}

.border-danger {
  border: 1px solid var(--danger);
}
.border-danger .card-header {
  border-color: var(--danger);
}

.border-light {
  border: 1px solid var(--light);
}
.border-light .card-header {
  border-color: var(--light);
}

.border-dark {
  border: 1px solid var(--dark);
}
.border-dark .card-header {
  border-color: var(--dark);
}

.border-warning {
  border: 1px solid var(--warning);
}
.border-warning .card-header {
  border-color: var(--warning);
}

.p-card {
  padding: var(--card-spacing);
}

.pe-card {
  padding-right: var(--card-spacing);
}

.ps-card {
  padding-left: var(--card-spacing);
}

.pt-card {
  padding-top: var(--card-spacing);
}

.pb-card {
  padding-bottom: var(--card-spacing);
}

.m-card {
  margin: var(--card-spacing);
}

.mb-card {
  margin-bottom: var(--card-spacing);
}

.mt-card {
  margin-top: var(--card-spacing);
}

.ms-card {
  margin-left: var(--card-spacing);
}

.me-card {
  margin-right: var(--card-spacing);
}

.list-group-item.active {
  background: var(--primary);
  border-color: var(--primary);
  color: var(--light-text);
}
.list-group-item.active * {
  color: var(--light-text);
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  background: var(--primary);
  border-color: var(--primary);
  color: var(--light-text);
}
.list-group-item-action:hover *,
.list-group-item-action:focus * {
  color: var(--light-text);
}

.list-group-item {
  background: var(--foreground);
  color: var(--body);
  border-color: rgba(var(--separator-rgb), 0.5);
  padding: var(--card-spacing-sm) var(--card-spacing);
}
.list-group-item.list-group-item-primary {
  background: var(--primary);
}
.list-group-item.list-group-item-secondary {
  background: var(--secondary);
}
.list-group-item.list-group-item-success {
  background: var(--success);
}
.list-group-item.list-group-item-danger {
  background: var(--danger);
}
.list-group-item.list-group-item-warning {
  background: var(--warning);
}
.list-group-item.list-group-item-info {
  background: var(--info);
}
.list-group-item.list-group-item-light {
  background: var(--light);
}
.list-group-item.list-group-item-dark {
  background: var(--dark);
  color: var(--light);
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--muted);
  background: var(--foreground);
}

.list-group-item:first-child {
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
}

.list-group-item:last-child {
  border-bottom-right-radius: var(--border-radius-md);
  border-bottom-left-radius: var(--border-radius-md);
}

.list-group-horizontal > .list-group-item:first-child {
  border-top-left-radius: var(--border-radius-md);
  border-bottom-left-radius: var(--border-radius-md);
  border-top-right-radius: initial;
  border-bottom-right-radius: initial;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-left-radius: initial;
  border-bottom-left-radius: initial;
  border-top-right-radius: var(--border-radius-md);
  border-bottom-right-radius: var(--border-radius-md);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-top-left-radius: var(--border-radius-md);
    border-bottom-left-radius: var(--border-radius-md);
    border-top-right-radius: initial;
    border-bottom-right-radius: initial;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-left-radius: initial;
    border-bottom-left-radius: initial;
    border-top-right-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md > .list-group-item:first-child {
    border-top-left-radius: var(--border-radius-md);
    border-bottom-left-radius: var(--border-radius-md);
    border-top-right-radius: initial;
    border-bottom-right-radius: initial;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-left-radius: initial;
    border-bottom-left-radius: initial;
    border-top-right-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-top-left-radius: var(--border-radius-md);
    border-bottom-left-radius: var(--border-radius-md);
    border-top-right-radius: initial;
    border-bottom-right-radius: initial;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-left-radius: initial;
    border-bottom-left-radius: initial;
    border-top-right-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-top-left-radius: var(--border-radius-md);
    border-bottom-left-radius: var(--border-radius-md);
    border-top-right-radius: initial;
    border-bottom-right-radius: initial;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-left-radius: initial;
    border-bottom-left-radius: initial;
    border-top-right-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-top-left-radius: var(--border-radius-md);
    border-bottom-left-radius: var(--border-radius-md);
    border-top-right-radius: initial;
    border-bottom-right-radius: initial;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-left-radius: initial;
    border-bottom-left-radius: initial;
    border-top-right-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);
  }
}
.card.hover-border-primary {
  cursor: pointer;
}
.card.hover-border-primary:hover:after {
  box-shadow: inset 0 0 0 1px rgba(var(--primary-rgb), 0.5), 0 4px 10px rgba(0, 0, 0, 0.03) !important;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: var(--border-radius-lg);
  z-index: 0;
  pointer-events: none;
}

.hover-img-scale-up {
  overflow: hidden;
  backface-visibility: hidden;
  transform: scale(1);
}
.hover-img-scale-up img.scale {
  transition: transform var(--transition-time);
}
.hover-img-scale-up:hover img.scale {
  transform: scale(1.1);
}

.hover-img-scale-down {
  overflow: hidden;
  backface-visibility: hidden;
  transform: scale(1);
}
.hover-img-scale-down img.scale {
  transition: transform var(--transition-time);
  transform: scale(1.15);
}
.hover-img-scale-down:hover img.scale {
  transform: scale(1.05);
}

.hover-reveal .reveal-content {
  opacity: 0;
  transition: opacity var(--transition-time);
}
.hover-reveal:hover .reveal-content {
  opacity: 1;
}

@media (max-width: 991.98px) {
  .hover-reveal .reveal-content {
    opacity: 1;
  }
}
.hover-reveal-buttons .lower-opacity {
  transition-property: opacity;
  transition-duration: var(--transition-time);
}
.hover-reveal-buttons:hover .lower-opacity {
  opacity: 0.35;
}

/*
*
* Dropdown
*
* Bootstrap dropdown styles.
*
*/
.dropdown-menu {
  line-height: 1.3;
  background-color: var(--foreground);
  border-radius: var(--border-radius-md);
  border-color: var(--separator);
  color: var(--body);
  font-family: var(--font);
  font-size: 1em;
}
.dropdown-menu.shadow {
  border: initial;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03) !important;
}
.dropdown-menu ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.dropdown-menu ul li {
  padding: 3px 0 3px 0;
}
.dropdown-menu a,
.dropdown-menu .dropdown-item,
.dropdown-menu .nav-link {
  text-decoration: initial;
  color: var(--body);
  background: initial;
  border: initial !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.dropdown-menu a:hover, .dropdown-menu a.active,
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item.active,
.dropdown-menu .nav-link:hover,
.dropdown-menu .nav-link.active {
  background-color: initial;
  color: var(--primary) !important;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--body);
  opacity: 0.5;
}

.dropdown-toggle-split::after {
  margin-bottom: 0;
}

.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after,
.dropend .dropdown-toggle::after {
  margin-bottom: 1px;
}

.dropstart .caret-absolute {
  position: relative;
}
.dropstart .caret-absolute:before {
  position: absolute;
  left: 13px;
  top: calc(50% - 3px);
}

.dropend .caret-absolute {
  position: relative;
}
.dropend .caret-absolute:after {
  position: absolute;
  right: 13px;
  top: calc(50% - 3px);
}

.dropdown-toggle::after,
.dropup .dropdown-toggle::after,
.dropstart .dropdown-toggle::before,
.dropend .dropdown-toggle::after {
  width: 5px;
  height: 5px;
  border: initial;
  transform: rotate(45deg);
  border-top: 1px solid;
  border-right: 1px solid;
  margin-top: initial;
  vertical-align: middle;
  margin-bottom: 2px;
}

.dropstart .dropdown-toggle::before {
  transform: rotate(225deg);
}

.dropstart .dropdown-toggle.show::before,
.dropstart.show > .dropdown-toggle::before {
  transform: rotate(135deg);
}

.dropup .dropdown-toggle.show::after,
.dropup.show > .dropdown-toggle::after {
  transform: rotate(-45deg);
}

.dropdown-toggle.show::after,
.show > .dropdown-toggle::after {
  transform: rotate(135deg);
}

.dropdown-toggle[data-bs-toggle=collapse]:not(.collapsed)::after {
  transform: rotate(135deg);
}

.dropdown-toggle-no-arrow::after,
.dropdown-toggle-no-arrow::before {
  display: none !important;
}

.dropdown-menu.dropdown-menu-sm {
  min-width: 7rem;
  padding: 0.5rem 0;
}
.dropdown-menu.dropdown-menu-sm .dropdown-item,
.dropdown-menu.dropdown-menu-sm .nav-link,
.dropdown-menu.dropdown-menu-sm a {
  padding: 0.4rem 1.25rem;
  font-size: 0.9em;
}

.dropdown-header {
  font-size: 0.8em;
}

.dropdown-divider {
  border-color: var(--separator);
}

.dropdown-submenu.dropend > .dropdown-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dropdown-header,
.dropdown-item,
.dropdown-item-text {
  padding: 0.5rem 1.5rem;
}

.dropend.dropdown-submenu .dropdown-menu {
  bottom: initial;
}

.dropdown-submenu .dropdown-toggle.btn {
  padding: 0.5rem 1.5rem;
  color: var(--body) !important;
}
.dropdown-submenu .dropdown-toggle.btn:hover {
  color: var(--primary) !important;
}

.dropdown-submenu.dropstart .dropdown-toggle::before {
  position: absolute;
  left: 13px;
  top: calc(50% - 3px);
}

.btn-group .dropdown > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

/*
*
* Form
*
* Styles for Bootstrap forms, additional layouts and controls.
*
*/
.form-control,
.form-select,
.custom-select {
  font-size: 1em;
  height: auto;
  min-height: var(--input-height);
  color: var(--body);
  box-shadow: initial !important;
  background-color: var(--foreground);
  border-color: var(--separator);
  border-radius: var(--border-radius-md);
  filter: none;
  transition: border-color 0.15s ease-in-out;
}
.form-control.borderless,
.form-select.borderless,
.custom-select.borderless {
  border: initial;
  box-shadow: initial;
}
.form-control:focus,
.form-select:focus,
.custom-select:focus {
  background-color: var(--foreground);
  color: var(--body);
  border-color: rgba(var(--primary-rgb), 1);
}
.form-control.shadow,
.form-select.shadow,
.custom-select.shadow {
  border: initial;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03) !important;
}

.form-text {
  color: var(--muted);
}

.form-select {
  padding-right: 2rem;
}

textarea.form-control {
  height: initial;
}

.form-label,
.col-form-label {
  color: var(--alternate);
}

.form-control-muted {
  border-color: var(--muted);
}
.form-control-muted:focus {
  border-color: var(--alternate);
}

.form-control-separator {
  border-color: var(--separator);
}
.form-control-separator:focus {
  border-color: var(--alternate);
}

.form-control-sm,
.custom-select-sm {
  min-height: 30px;
  font-size: 0.9em;
  padding: 0.25rem 0.75rem;
  line-height: 1.25;
}

.form-control-lg,
.custom-select-lg {
  height: 44px;
  font-size: 1.25em;
  line-height: 1.5;
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
}

.form-control-xl {
  font-size: 1.6em;
  height: 56px;
}

.search-input-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  pointer-events: none;
  width: 17px;
  height: 17px;
}

.custom-select {
  background: var(--foreground) url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23777777' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
}

.search-input-container {
  position: relative;
  border-radius: var(--border-radius-md);
}
.search-input-container .search-magnifier-icon,
.search-input-container .search-delete-icon {
  color: var(--muted);
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 100%;
  text-align: center;
  cursor: pointer;
}
.search-input-container .search-magnifier-icon:hover,
.search-input-container .search-delete-icon:hover {
  color: var(--primary);
}
.search-input-container .search-magnifier-icon svg,
.search-input-container .search-delete-icon svg {
  width: 17px;
  height: 17px;
  margin-top: 9px;
}
.search-input-container input {
  background: transparent !important;
  width: calc(100% - 25px);
  border: initial;
}
.search-input-container.search-sm .search-magnifier-icon,
.search-input-container.search-sm .search-delete-icon {
  width: 28px;
}
.search-input-container.search-sm .search-magnifier-icon svg,
.search-input-container.search-sm .search-delete-icon svg {
  width: 15px;
  height: 15px;
  margin-top: 4px;
}

.form-control:disabled,
.form-control[readonly] {
  background: rgba(var(--separator-rgb), 0.5) !important;
  border-color: rgba(var(--separator-rgb), 0.5) !important;
  color: var(--muted);
  -webkit-text-fill-color: var(--muted) !important;
}

.form-control:disabled ~ span, .form-control:disabled ~ label {
  background: transparent !important;
}

.create_job .filled .form-control {
  padding-left: 12px;
}
.filled {
  position: relative;
}
.filled .form-control {
  min-height: 44px;
  border: 1px solid transparent;
  background: var(--background-light);
  padding-left: 45px;
}
.filled .form-control:focus {
  border-color: rgba(var(--primary-rgb), 1);
  background: initial;
}
.filled .form-control:disabled, .filled .form-control[readonly] {
  background: rgba(var(--separator-rgb), 0.5) !important;
  color: var(--muted);
}
.filled .form-control:disabled > svg, .filled .form-control[readonly] > svg {
  color: rgba(var(--alternate-rgb), 0.25);
}
.filled .form-control-lg {
  min-height: 52px;
}
.filled .form-control-sm {
  min-height: var(--input-height);
}
.filled > svg {
  position: absolute;
  top: 12px;
  left: 16px;
  color: rgba(var(--alternate-rgb), 0.5);
  z-index: 1;
}
.filled.lg > svg {
  top: 15px;
}
.filled.sm > svg {
  top: 9px;
  font-size: 14px;
  width: 17px;
  height: 17px;
}
.filled textarea {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
.filled > textarea ~ svg {
  margin-top: 0;
  top: 14px;
}
.filled.custom-control-container {
  min-height: 44px;
  border: 1px solid transparent;
  background: var(--background-light);
  padding-left: 45px;
  border-radius: var(--border-radius-md);
  padding-top: 0.75rem;
  padding-bottom: 0.25rem;
  padding-right: 0.75rem;
}
.filled .form-check {
  margin-bottom: initial;
  margin-top: 0;
}

*[disabled] .filled i {
  opacity: 0.3;
}

.top-label .form-control,
.top-label .bootstrap-tagsinput {
  padding: 1.5rem 0.75rem 0.25rem 0.75rem;
}

.top-label {
  display: block;
  position: relative;
}

.top-label label:not(.form-check-label),
.top-label > span:last-of-type {
  position: absolute;
  cursor: text;
  font-size: 0.7em !important;
  line-height: 1.1rem !important;
  opacity: 1;
  top: 0.5rem;
  left: 0.75rem;
  z-index: 1;
  line-height: 1;
  padding: 0 1px;
  background: var(--foreground);
}

.top-label label:not(.form-check-label)::after,
.top-label > span::after {
  content: " ";
  display: block;
  position: absolute;
  height: 2px;
  top: 50%;
  left: -0.2em;
  right: -0.2em;
  z-index: -1;
}

.input-group .top-label {
  flex-grow: 1;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top-label textarea ~ span {
  left: 0 !important;
  top: 0 !important;
  margin-top: 1px;
  margin-left: 1px;
  padding-left: 0.75rem !important;
  padding-top: calc(0.5rem - 1px) !important;
  width: calc(100% - 2px);
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
}

.top-label .form-control,
.top-label .bootstrap-tagsinput {
  height: auto;
  min-height: 52px;
}

.top-label textarea.form-control {
  min-height: 52px;
  height: initial;
}

.top-label select.form-control:not([size]):not([multiple]) {
  height: auto;
  min-height: 52px;
  padding: 1.7rem 0.75rem 0.5rem 0.5rem;
}

.top-label label:not(.form-check-label),
.top-label > span {
  color: var(--muted);
}

.top-label .form-control.form-control-lg,
.top-label .bootstrap-tagsinput.form-control-lg {
  min-height: 62px;
  height: auto;
  padding: 1.8rem 0.75rem 0.5rem 0.75rem;
}

.top-label textarea.form-control.form-control-lg {
  min-height: 62px;
  height: auto;
  padding: 1.8rem 0.75rem 0.5rem 0.75rem;
}

.top-label select.form-control.form-control-lg:not([size]):not([multiple]) {
  min-height: 62px;
  height: auto;
  padding: 1.8rem 0.75rem 0.5rem 0.75rem;
}

.top-label .form-control.form-control-sm,
.top-label .bootstrap-tagsinput.form-control-sm {
  min-height: 44px;
  height: auto;
  padding: 1.4rem 0.75rem 0.25rem 0.75rem;
}

.top-label textarea.form-control.form-control-sm {
  min-height: 44px;
  height: initial;
  padding: 1.4rem 0.75rem 0.25rem 0.75rem;
}

.top-label select.form-control.form-control-sm:not([size]):not([multiple]) {
  min-height: 44px;
  height: auto;
  padding: 1.4rem 0.75rem 0.25rem 0.75rem;
}

.top-label .form-check {
  margin-left: 4px;
  margin-bottom: initial;
  margin-top: 0;
}
.top-label.custom-control-container {
  border: 1px solid var(--separator) !important;
  background: var(--foreground) !important;
  border-radius: var(--border-radius-md) !important;
  color: var(--alternate) !important;
  padding: 1.5rem 0.75rem 0.1rem 0.75rem !important;
  height: auto;
  min-height: 52px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--body);
  border-color: var(--separator) !important;
  box-shadow: 0 0 0 1000px var(--foreground) inset !important;
  -webkit-box-shadow: 0 0 0 1000px var(--foreground) inset !important;
  background-color: initial !important;
  background-clip: content-box !important;
}

input:-webkit-autofill ~ label,
textarea:-webkit-autofill ~ label,
select:-webkit-autofill ~ label {
  -webkit-transition: initial;
  transition: initial;
}

input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--body);
  border-color: rgba(var(--primary-rgb), 1) !important;
  box-shadow: 0 0 0 1000px var(--foreground) inset !important;
  -webkit-box-shadow: 0 0 0 1000px var(--foreground) inset !important;
  background-color: initial !important;
  background-clip: content-box !important;
}

.filled input:-webkit-autofill,
.filled input:-webkit-autofill:hover,
.filled textarea:-webkit-autofill,
.filled textarea:-webkit-autofill:hover,
.filled select:-webkit-autofill,
.filled select:-webkit-autofill:hover,
.filled input:-webkit-autofill:focus,
.filled textarea:-webkit-autofill:focus,
.filled select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--body);
  border-color: var(--separator) !important;
  box-shadow: 0 0 0 1000px var(--separator-light) inset !important;
  -webkit-box-shadow: 0 0 0 1000px var(--foreground) inset !important;
  background-color: initial !important;
  background-clip: content-box !important;
}

.form-check-input[type=checkbox] {
  border-radius: var(--border-radius-sm);
}

.form-switch.form-check {
  padding-left: 2.5em;
}
.form-switch.form-check .form-check-input {
  margin-left: -2.5em;
}

.form-switch .form-check-input {
  border-radius: var(--border-radius-md);
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280,0,0,0.25%29'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check.custom-icon .content {
  display: flex !important;
  flex-direction: column;
}
.form-check.custom-icon .form-check-label {
  padding-top: initial;
}
.form-check.custom-icon .form-check-label::before {
  width: 26px;
  min-width: 26px;
  height: 26px;
  left: -40px;
  top: 0;
  border-radius: var(--border-radius-xl);
  border-color: var(--muted) !important;
  opacity: 0.3;
}
.form-check.custom-icon .form-check-input {
  width: 25px;
  height: 25px;
  background: initial !important;
  font-size: 16px;
  text-align: center;
  padding-top: 1px;
  border-radius: var(--border-radius-xl);
  border-color: var(--muted) !important;
  color: var(--muted) !important;
  opacity: 0.3;
  margin-top: 0;
  margin-right: 1rem;
}
.form-check.custom-icon .form-check-input:checked {
  border-color: var(--primary) !important;
  background: initial !important;
  opacity: 1;
}
.form-check.custom-icon .form-check-input:checked:after {
  font-family: "CS-Interface" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  color: var(--primary) !important;
  opacity: 1;
  content: "\e913";
}
.form-check.custom-icon .form-check-input[type=radio]:checked:after {
  content: "\e922";
}

.form-check.custom-card:hover .form-check-label::before,
.form-check.custom-card .form-check-input:checked ~ .form-check-label::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-md);
  box-shadow: inset 0 0 0 1px rgba(var(--primary-rgb), 0.5) !important;
}
.form-check.custom-card .form-check-input {
  border: initial;
  background-color: initial !important;
}
.form-check.custom-card .form-check-input:checked {
  border: 1px solid var(--primary);
  background-color: var(--primary) !important;
}

.form-check .custom-border {
  border: initial !important;
  box-shadow: initial !important;
}
.form-check .custom-border:hover .form-check-label::before,
.form-check .custom-border .form-check-input:checked ~ .form-check-label::before {
  box-shadow: inset 0 0 0 1px rgba(var(--primary-rgb), 0.5) !important;
}
.form-check .custom-border.form-check-label::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-md);
  box-shadow: inset 0 0 0 1px var(--separator);
}

.custom-icon input[type=radio] .form-check-label::after {
  content: "\e9d3";
}

.form-check.checked-line-through .form-check-input:checked ~ .form-check-label {
  text-decoration: line-through;
}
.form-check.checked-line-through .form-check-input:checked ~ .form-check-label span {
  text-decoration: line-through;
}

.form-check.checked-opacity-100 .form-check-input:checked ~ .form-check-label > span:first-of-type, .form-check.checked-opacity-100 .form-check-input:checked ~ .form-check-label > div:first-of-type {
  opacity: 1;
}

.form-check.checked-opacity-75 .form-check-input:checked ~ .form-check-label > span:first-of-type, .form-check.checked-opacity-75 .form-check-input:checked ~ .form-check-label > div:first-of-type {
  opacity: 0.75;
}

.form-check.checked-opacity-50 .form-check-input:checked ~ .form-check-label > span:first-of-type, .form-check.checked-opacity-50 .form-check-input:checked ~ .form-check-label > div:first-of-type {
  opacity: 0.5;
}

.form-check.checked-opacity-25 .form-check-input:checked ~ .form-check-label > span:first-of-type, .form-check.checked-opacity-25 .form-check-input:checked ~ .form-check-label > div:first-of-type {
  opacity: 0.25;
}

.form-check.checked-opacity-0 .form-check-input:checked ~ .form-check-label > span:first-of-type, .form-check.checked-opacity-0 .form-check-input:checked ~ .form-check-label > div:first-of-type {
  opacity: 0;
}

.form-check.unchecked-opacity-100 .form-check-input:not(:checked) ~ .form-check-label > span:first-of-type, .form-check.unchecked-opacity-100 .form-check-input:not(:checked) ~ .form-check-label > div:first-of-type {
  opacity: 1;
}

.form-check.unchecked-opacity-75 .form-check-input:not(:checked) ~ .form-check-label > span:first-of-type, .form-check.unchecked-opacity-75 .form-check-input:not(:checked) ~ .form-check-label > div:first-of-type {
  opacity: 0.75;
}

.form-check.unchecked-opacity-50 .form-check-input:not(:checked) ~ .form-check-label > span:first-of-type, .form-check.unchecked-opacity-50 .form-check-input:not(:checked) ~ .form-check-label > div:first-of-type {
  opacity: 0.5;
}

.form-check.unchecked-opacity-25 .form-check-input:not(:checked) ~ .form-check-label > span:first-of-type, .form-check.unchecked-opacity-25 .form-check-input:not(:checked) ~ .form-check-label > div:first-of-type {
  opacity: 0.25;
}

.form-check.unchecked-opacity-0 .form-check-input:not(:checked) ~ .form-check-label > span:first-of-type, .form-check.unchecked-opacity-0 .form-check-input:not(:checked) ~ .form-check-label > div:first-of-type {
  opacity: 0;
}

textarea {
  resize: none;
}

.form-check {
  margin-bottom: 0.25rem;
  padding-left: 1.75em;
  line-height: 1.5;
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: var(--primary);
  border-color: var(--primary);
}

.form-check-input,
.form-check-input label,
.form-check-input input {
  outline: initial !important;
  box-shadow: initial !important;
}

.form-check-input {
  width: 16px;
  height: 16px;
}

.form-check .form-check-input {
  border-color: var(--muted) !important;
  background-color: initial;
  margin-top: 0.2em;
  margin-left: -1.75em;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary) !important;
}

.form-check-label {
  color: var(--body);
  margin-top: 1px;
}

.form-floating label {
  color: var(--alternate);
  padding: 0.85rem 0.75rem;
  position: absolute;
  top: 0;
  left: 0;
}

.form-floating .form-control:not(:-moz-placeholder-shown) ~ label {
  color: var(--muted);
  opacity: 1;
}

.form-floating .form-control:focus ~ label,
.form-floating .form-control:not(:placeholder-shown) ~ label,
.form-floating .form-select ~ label {
  color: var(--muted);
  opacity: 1;
}

.form-floating textarea.form-control ~ label {
  transition: transform 0.1s ease-in-out, padding 0.1s ease-in-out, background-color 0s ease-in-out, height 0.1s ease-in-out;
}

.form-floating textarea.form-control:not(:-moz-placeholder-shown) ~ label {
  background-color: var(--foreground);
  padding-top: 0.25rem;
  padding-bottom: 0.05rem;
  border-top-left-radius: var(--border-radius-md);
  height: auto;
  transform: scale(0.85) translateY(1px) translateX(0.15rem);
  -moz-transition: transform 0.1s ease-in-out, padding 0.1s ease-in-out, background-color 0.1s ease-in-out 0.1s, height 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, padding 0.1s ease-in-out, background-color 0.1s ease-in-out 0.1s, height 0.1s ease-in-out;
}

.form-floating textarea.form-control:not(:placeholder-shown) ~ label,
.form-floating textarea.form-control:focus ~ label {
  background-color: var(--foreground);
  padding-top: 0.25rem;
  padding-bottom: 0.05rem;
  border-top-left-radius: var(--border-radius-md);
  height: auto;
  transform: scale(0.85) translateY(1px) translateX(0.15rem);
  transition: transform 0.1s ease-in-out, padding 0.1s ease-in-out, background-color 0.1s ease-in-out 0.1s, height 0.1s ease-in-out;
}

.form-floating .form-control,
.form-floating .form-select {
  height: auto;
  min-height: 52px;
  padding: 0.85rem 0.75rem;
}

.form-floating .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.45rem;
  padding-bottom: 0.25rem;
}

.form-floating .form-control:focus,
.form-floating .form-control:not(:placeholder-shown) {
  padding-top: 1.45rem;
  padding-bottom: 0.25rem;
}

.form-floating .form-control.form-control-lg {
  min-height: 62px;
  height: auto;
  padding: 1.2rem 0.75rem 1.2rem 0.75rem;
}
.form-floating .form-control.form-control-lg ~ label {
  padding: 1.2rem 0.75rem 1.2rem 0.75rem;
}
.form-floating .form-control.form-control-lg:not(:-moz-placeholder-shown) {
  padding-top: 2rem;
  padding-bottom: 0.4rem;
}
.form-floating .form-control.form-control-lg:focus, .form-floating .form-control.form-control-lg:not(:placeholder-shown) {
  padding-top: 2rem;
  padding-bottom: 0.4rem;
}

.form-floating .form-control.form-control-sm {
  min-height: 44px;
  height: auto;
  padding: 0.7rem 0.75rem 0.8rem 0.75rem;
}
.form-floating .form-control.form-control-sm ~ label {
  padding: 0.7rem 0.75rem 0.8rem 0.75rem;
}
.form-floating .form-control.form-control-sm:not(:-moz-placeholder-shown) {
  padding-top: 1.4rem;
  padding-bottom: 0.2rem;
}
.form-floating .form-control.form-control-sm:focus, .form-floating .form-control.form-control-sm:not(:placeholder-shown) {
  padding-top: 1.4rem;
  padding-bottom: 0.2rem;
}

/*
*
* Grid
*
* Bootstrap auto column fix.
*
*/
.col,
.col-sm,
.col-md,
.col-lg,
.col-xl {
  min-width: 0;
}

/*
*
* Input Group
*
* Bootstrap input group styles.
*
*/
.input-group-text {
  border-radius: var(--border-radius-md);
  font-size: 1em;
  padding: 0.25rem 0.75rem 0.35rem 0.75rem;
  background-color: var(--foreground);
  border-color: var(--separator);
  color: var(--body);
}

.input-group .form-control {
  border-top-right-radius: var(--border-radius-md);
  border-bottom-right-radius: var(--border-radius-md);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select {
  border-radius: var(--border-radius-md);
}

.input-group .top-label .form-control {
  width: 100%;
}

.input-group .top-label:not(:last-child),
.input-group .top-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}

.input-group .top-label:not(:first-child),
.input-group .top-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.custom-file-label,
.custom-file,
.custom-file-input {
  height: var(--input-height);
  border-color: var(--separator);
  background: var(--foreground);
}

.custom-file-label {
  border-radius: var(--border-radius-md);
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-file-label::after {
  border-radius: var(--border-radius-md);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 34px;
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--light-text);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  height: 44px;
  font-size: 1.25em;
  line-height: 1.5;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  border-radius: var(--border-radius-md);
  font-size: 0.9em;
  padding: 0.25rem 0.75rem;
  height: 28px;
  min-height: 28px;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  border-radius: var(--border-radius-md);
}

.input-group-text .form-check-input {
  margin-top: 1px !important;
}

.input-group .form-control[type=file] {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  line-height: 1.6;
}

input[type=file]::file-selector-button {
  background: var(--foreground);
  color: var(--body);
}

/*
*
* Modal
*
* Bootstrap modal styles.
*
*/
.modal-header,
.modal-body,
.modal-footer {
  padding: var(--card-spacing);
}

.modal-content {
  border-radius: var(--border-radius-lg);
  background: var(--foreground);
}

.modal-header {
  border-top-left-radius: var(--border-radius-lg);
  border-top-right-radius: var(--border-radius-lg);
  border-color: var(--separator);
}

.modal-footer {
  border-bottom-left-radius: var(--border-radius-lg);
  border-bottom-right-radius: var(--border-radius-lg);
  border-color: var(--separator);
}

.modal-under-nav .modal-dialog {
  margin-top: var(--nav-size-slim);
}

.modal-close-out .btn-close {
  position: absolute;
  right: -50px;
  top: 0;
  background: var(--foreground);
  opacity: 1;
  color: var(--primary);
  border-radius: var(--border-radius-md);
  width: var(--input-height);
  height: var(--input-height);
  margin: 0;
  padding: 0;
}
@media (max-width: 767.98px) {
  .modal-close-out .btn-close {
    right: 0;
    top: -50px;
  }
}
.modal-close-out .modal-dialog:not(.modal-dialog-centered) {
  margin-top: var(--nav-size-slim);
}

.modal-right {
  padding-right: 0 !important;
}
.modal-right .modal-dialog {
  margin: 0 auto;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
  max-width: 330px;
}
.modal-right .modal-body {
  height: 100% !important;
}
.modal-right .modal-content {
  min-height: 100%;
  border: initial;
  border-radius: initial;
  border-top-left-radius: var(--border-radius-lg);
  border-bottom-left-radius: var(--border-radius-lg);
}
.modal-right .modal-header {
  flex: 0 0 var(--nav-size-slim);
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top-left-radius: var(--border-radius-lg);
}
.modal-right .modal-footer {
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  border-bottom-left-radius: var(--border-radius-lg);
}
.modal-right.modal.fade .modal-dialog {
  transform: translate(25%, 0);
}
.modal-right.modal.show .modal-dialog {
  transform: translate(0, 0);
}
.modal-right.large .modal-dialog {
  max-width: 530px;
}

.modal-left {
  padding-left: 0 !important;
}
.modal-left .modal-dialog {
  margin: 0 auto;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
  max-width: 330px;
}
.modal-left .modal-body {
  height: 100% !important;
}
.modal-left .modal-content {
  min-height: 100%;
  border: initial;
  border-radius: initial;
  border-top-right-radius: var(--border-radius-lg);
  border-bottom-right-radius: var(--border-radius-lg);
}
.modal-left .modal-header {
  flex: 0 0 var(--nav-size-slim);
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top-right-radius: var(--border-radius-lg);
}
.modal-left .modal-footer {
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  border-bottom-right-radius: var(--border-radius-lg);
}
.modal-left.modal.fade .modal-dialog {
  transform: translate(-25%, 0);
}
.modal-left.modal.show .modal-dialog {
  transform: translate(0, 0);
}
.modal-left.large .modal-dialog {
  max-width: 530px;
}

.modal-dialog-scrollable.long .modal-body {
  overflow: initial;
}
.modal-dialog-scrollable.long .modal-body .scroll,
.modal-dialog-scrollable.long .modal-body .scroll-track-visible {
  max-height: calc(100vh - 25rem);
}

.modal-dialog-scrollable.full {
  max-height: 100% !important;
}
.modal-dialog-scrollable.full .modal-content {
  height: 100%;
  min-height: unset;
}
.modal-dialog-scrollable.full .modal-body {
  overflow: initial;
  max-height: 100% !important;
}
.modal-dialog-scrollable.full .modal-body .scroll,
.modal-dialog-scrollable.full .modal-body .scroll-track-visible {
  max-height: 100%;
  height: 100%;
}

.modal-dialog-scrollable.short .modal-body {
  overflow: initial;
}
.modal-dialog-scrollable.short .modal-body .scroll,
.modal-dialog-scrollable.short .modal-body .scroll-track-visible {
  max-height: 50vh;
}

.modal.modal-right .os-content {
  padding: 0 !important;
}
.modal.modal-right .os-host-resize-disabled.os-host-scrollbar-horizontal-hidden > .os-scrollbar-vertical {
  padding-right: 0;
  padding-left: 4px;
}

.modal.modal-left .os-content {
  padding: 0 !important;
}
.modal.modal-left .os-host-resize-disabled.os-host-scrollbar-horizontal-hidden > .os-scrollbar-vertical {
  padding-right: 4px;
  padding-left: 0;
}

.modal-semi-full {
  max-width: 90%;
  height: 95%;
  margin-left: auto;
  margin-right: auto;
}
.modal-semi-full .modal-content {
  height: 100%;
}

/*
*
* Nav
*
* Bootstrap navigation styles.
*
*/
.page-item {
  margin: 0 5px;
  text-align: center;
}

.page-link {
  outline: initial !important;
  box-shadow: initial !important;
  border: initial;
  padding: 8px 9px;
  line-height: 1.5;
  font-size: 1em;
  width: var(--input-height);
  height: var(--input-height);
  color: var(--primary);
  border-radius: var(--border-radius-md) !important;
  background: var(--foreground);
}
.page-link:focus {
  background: var(--foreground);
  color: var(--primary);
}
.page-link:hover, .page-link:hover:focus {
  background: var(--primary);
  color: var(--light-text);
}
.page-link svg {
  width: 15px;
  height: 15px;
  vertical-align: top;
  margin-top: 2px;
  margin-left: 2px;
}
.page-link i {
  margin-left: 1px;
}

.page-item.active .page-link {
  background: var(--primary);
  box-shadow: initial !important;
}

.page-item.disabled .page-link {
  opacity: 0.5;
  color: var(--primary);
  background: var(--foreground);
  box-shadow: initial !important;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border-radius: var(--border-radius-md);
}

.pagination.bordered .page-item .page-link {
  color: var(--primary);
  box-shadow: inset 0 0 0 1px var(--primary) !important;
  border-radius: var(--border-radius-md);
  background-color: transparent;
}
.pagination.bordered .page-item .page-link:hover {
  background: var(--primary);
  color: var(--light-text);
}
.pagination.bordered .page-item.active .page-link {
  box-shadow: initial !important;
  background: var(--primary);
  color: var(--light-text);
}

.pagination.semibordered .page-item .page-link:hover {
  background-color: transparent;
  color: var(--primary);
  box-shadow: inset 0 0 0 1px var(--primary) !important;
  border-radius: var(--border-radius-md);
}
.pagination.semibordered .page-item.active .page-link {
  background-color: transparent;
  color: var(--primary);
  box-shadow: inset 0 0 0 1px var(--primary) !important;
  border-radius: var(--border-radius-md);
}

.pagination.pagination-xl .page-link {
  line-height: 1;
  font-size: 1em;
  height: 44px;
  padding: 14px 26px;
  width: 65px;
}
.pagination.pagination-xl .page-link svg {
  margin-top: 0;
}

.pagination.pagination-lg .page-link {
  font-size: 1em;
  line-height: 18px;
  width: 50px;
  padding: 8px 15px;
}

.pagination.pagination-sm .page-link {
  font-size: 0.9em;
  height: 30px;
  width: 30px;
  line-height: 1;
  padding: 9px 3px;
}
.pagination.pagination-sm .page-link svg {
  margin-top: -1px;
}

/* Navigation */
.nav-tabs {
  border-color: var(--separator);
}

.nav-link {
  font-family: var(--font);
  font-size: 1em;
  line-height: 1;
  border: initial;
  box-shadow: initial !important;
  transition: all var(--transition-time-short);
  transition-property: color, background-color, background-image, background;
  color: var(--body);
}
.nav-link:hover, .nav-link:active, .nav-link.active {
  color: var(--primary);
}

.nav:not(.nav-pills):not(.nav-tabs-line) .nav-link {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.nav-tabs .nav-link {
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--body);
  background: var(--foreground);
}

.nav-item.disabled .nav-link {
  opacity: 0.5;
}

.card-header-tabs .nav-link {
  border-bottom-left-radius: initial;
  border-bottom-right-radius: initial;
}
.card-header-tabs .nav-link:not(a) {
  color: var(--body);
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--primary);
}

.nav-pills .dropdown-menu .nav-item .nav-link.active {
  background: initial !important;
}

.card-header-tabs-border > li > .nav-link,
.card-header-tabs-border > div > .nav-link {
  border: initial;
  border-radius: initial;
  position: relative;
  padding-top: 15px;
}
.card-header-tabs-border .nav-link.active::before,
.card-header-tabs-border .nav-item.show .nav-link::before {
  content: " ";
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 5px;
  left: 0;
  top: 0;
  background: var(--primary);
  color: var(--light-text);
}

.nav-pills .nav-link {
  line-height: 1.5;
  font-size: 1em;
  height: var(--input-height);
  cursor: pointer;
  border-radius: var(--border-radius-md);
}

.card .card-header-tabs {
  margin-bottom: calc(var(--card-spacing) * -1);
  margin-top: calc(var(--card-spacing) * -0.25);
}

.card-header-pills,
.card-header-tabs {
  margin-left: initial;
  margin-right: initial;
}

.card .card-header-tabs.nav-tabs-line {
  margin-top: calc(var(--card-spacing) * -1);
  margin-left: calc(var(--card-spacing) / 2 * -1);
  margin-right: calc(var(--card-spacing) / 2 * -1);
  margin-bottom: initial;
}

.nav-tabs-line {
  border: initial;
}
.nav-tabs-line > li > .nav-link,
.nav-tabs-line > div > .nav-link {
  border: initial;
  position: relative;
  padding-top: 20px;
  cursor: pointer;
  color: var(--body);
}
.nav-tabs-line > li > .nav-link:hover, .nav-tabs-line > li > .nav-link.active,
.nav-tabs-line > div > .nav-link:hover,
.nav-tabs-line > div > .nav-link.active {
  color: var(--primary);
}
.nav-tabs-line > li > .nav-link.active,
.nav-tabs-line > div > .nav-link.active {
  border: initial;
}
.nav-tabs-line > li > .nav-link.active::before,
.nav-tabs-line > div > .nav-link.active::before {
  content: " ";
  background: var(--primary);
  color: var(--foreground);
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 3px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.nav-tabs-line .dropdown-menu .nav-link.active {
  border: initial;
}
.nav-tabs-line .dropdown-menu .nav-link.active::before {
  background: initial;
}

.nav-tabs-title {
  margin: initial;
}
.nav-tabs-title .nav-item {
  margin: initial;
}
.nav-tabs-title > li > .nav-link,
.nav-tabs-title > div > .nav-link {
  height: var(--small-title-height);
  border: initial;
  background: initial;
  position: relative;
  color: var(--alternate);
  font-family: var(--font-heading);
  font-weight: 400;
  margin-bottom: 0;
  font-size: 1em;
  padding: 12px;
  padding-top: initial !important;
  padding-bottom: initial !important;
  cursor: pointer;
}
.nav-tabs-title > li > .nav-link:hover, .nav-tabs-title > li > .nav-link.active,
.nav-tabs-title > div > .nav-link:hover,
.nav-tabs-title > div > .nav-link.active {
  color: var(--primary);
}
.nav-tabs-title > li > .nav-link.active,
.nav-tabs-title > div > .nav-link.active {
  border: initial;
  background: initial;
  color: var(--primary);
}
.nav-tabs-title > li:first-of-type > .nav-link,
.nav-tabs-title > div:first-of-type > .nav-link {
  padding-left: initial;
}
.nav-tabs-title > li:last-of-type > .nav-link,
.nav-tabs-title > div:last-of-type > .nav-link {
  padding-right: initial;
}

.nav-tabs-line-title {
  border-bottom: 1px solid rgba(var(--separator-rgb), 0.3) !important;
  margin-bottom: calc(var(--small-title-height) / 3);
}
.nav-tabs-line-title > li,
.nav-tabs-line-title > div {
  margin-bottom: calc(-1px - var(--small-title-height) / 3) !important;
  padding-right: 12px;
  padding-left: 12px;
}
.nav-tabs-line-title > li > .nav-link,
.nav-tabs-line-title > div > .nav-link {
  padding-right: initial;
  padding-left: initial;
  padding-top: 1px !important;
}
.nav-tabs-line-title > li > .nav-link.active:after,
.nav-tabs-line-title > div > .nav-link.active:after {
  content: " ";
  left: 0;
  bottom: calc(-1px + var(--small-title-height) / 3);
  position: absolute;
  width: calc(100% + 1px);
  height: 1px;
  background: var(--primary);
}
.nav-tabs-line-title > li:first-of-type,
.nav-tabs-line-title > div:first-of-type {
  padding-left: initial;
}

.nav.horizontal-padding-0 > li:first-child a,
.nav.horizontal-padding-0 > div:first-child a,
.nav.horizontal-padding-0 > a:first-child {
  padding-left: 0;
}
.nav.horizontal-padding-0 > li:last-child a,
.nav.horizontal-padding-0 > div:last-child a,
.nav.horizontal-padding-0 > a:last-child {
  padding-right: 0;
}

/* Page Title */
.page-title-container {
  margin-bottom: var(--title-spacing);
}
.page-title-container h1 {
  line-height: 1;
}

/* Breadcrumb */
.breadcrumb {
  background: initial;
  padding: 0;
  font-size: 0.85em;
  margin-bottom: 0.25rem;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "|";
  color: var(--alternate);
}
.breadcrumb .breadcrumb-item,
.breadcrumb .breadcrumb-item a {
  color: var(--alternate);
}
.breadcrumb .breadcrumb-item:not(.active):hover,
.breadcrumb .breadcrumb-item a:not(.active):hover {
  color: var(--primary);
}

.breadcrumb-back span {
  display: inline-block;
  padding-top: 2px;
}

.mobile-buttons-container .dropdown-menu {
  min-width: 200px;
  padding: 15px 20px 15px 20px;
  width: unset;
}

.responsive-tabs .responsive-tab-dropdown .nav-item .nav-link {
  line-height: 1.3;
  width: 100%;
  text-align: initial;
}

/* Scroll Spy Menu */
#scrollSpyMenu {
  position: sticky;
  overflow-y: auto;
  width: 190px;
}
@media (min-width: 1400px) {
  #scrollSpyMenu {
    width: 260px;
  }
}

#scrollSpyMenu .nav ul,
html .nav-container .mobile-buttons-container .dropdown-menu .nav ul {
  padding-left: 35px;
  margin-top: -2px;
  margin-bottom: 2px;
}
#scrollSpyMenu li,
html .nav-container .mobile-buttons-container .dropdown-menu li {
  width: 100%;
  padding: initial;
}
#scrollSpyMenu li a,
html .nav-container .mobile-buttons-container .dropdown-menu li a {
  width: 100%;
}
#scrollSpyMenu .nav-link,
html .nav-container .mobile-buttons-container .dropdown-menu .nav-link {
  display: inline-block;
  opacity: 0.8;
  color: var(--alternate);
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  padding-left: 0;
}
#scrollSpyMenu .nav-link i,
html .nav-container .mobile-buttons-container .dropdown-menu .nav-link i {
  display: inline-block;
}
#scrollSpyMenu .nav-link:hover,
html .nav-container .mobile-buttons-container .dropdown-menu .nav-link:hover {
  color: var(--primary);
}
#scrollSpyMenu .nav-link.active,
html .nav-container .mobile-buttons-container .dropdown-menu .nav-link.active {
  color: var(--alternate);
}
#scrollSpyMenu svg,
html .nav-container .mobile-buttons-container .dropdown-menu svg {
  display: block;
  float: left;
  width: 17px;
  height: 17px;
  margin-right: 0.25rem;
}
#scrollSpyMenu span,
html .nav-container .mobile-buttons-container .dropdown-menu span {
  float: left;
  display: block;
  line-height: 1.4;
  width: calc(100% - 25px);
}

html[data-placement=horizontal] #scrollSpyMenu {
  top: calc(var(--nav-size-slim) + var(--main-spacing-horizontal) / 2);
  height: calc(100vh - (var(--nav-size-slim) * 2 + var(--main-spacing-horizontal) + var(--card-spacing)));
}

html[data-placement=vertical] #scrollSpyMenu {
  top: var(--main-spacing-horizontal);
  height: calc(100vh - (var(--nav-size-slim) * 2 + var(--main-spacing-horizontal) + var(--card-spacing)));
}

/*
*
* Offcanvas
*
* Bootstrap offcanvas styles.
*
*/
.offcanvas {
  background-color: var(--foreground);
}

/*
*
* Progress
*
* Bootstrap progress styles.
*
*/
.progress {
  height: 0.25rem;
  border-radius: var(--border-radius-md);
  background-color: var(--separator-light);
}
.progress .progress-bar {
  background-color: var(--primary);
}
.progress .progress-bar + .progress-bar:not(:last-child) {
  border-radius: initial;
}
.progress .progress-bar:last-child {
  border-top-right-radius: var(--border-radius-md);
  border-bottom-right-radius: var(--border-radius-md);
}
.progress.progress-xs {
  height: 0.1rem;
}
.progress.progress-sm {
  height: 0.15rem;
}
.progress.progress-md {
  height: 0.2rem;
}
.progress.progress-lg {
  height: 0.35rem;
}
.progress.progress-xl {
  height: 0.5rem;
}
.progress.progress-xxl {
  height: 1rem;
}

.text-body .progressbar-text {
  color: var(--body) !important;
}

.text-alternate .progressbar-text {
  color: var(--alternate) !important;
}

.text-muted .progressbar-text {
  color: var(--muted) !important;
}

.text-separator .progressbar-text {
  color: var(--separator) !important;
}

.text-separator-light .progressbar-text {
  color: var(--separator-light) !important;
}

/*
*
* Popover
*
* Bootstrap popover styles.
*
*/
.popover {
  border-radius: var(--border-radius-md);
  border-color: var(--separator);
  background-color: var(--foreground);
  color: var(--body);
}
.popover .popover-header {
  background: initial;
}
.popover .popover-body,
.popover .popover-header {
  padding: var(--card-spacing-xs) var(--card-spacing-sm);
  color: var(--body);
  border: initial;
  font-size: 1em;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom: none !important;
}

.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[x-placement^=right] > .popover-arrow::before {
  border-right-color: var(--separator);
}

.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[x-placement^=right] > .popover-arrow::after {
  border-right-color: var(--foreground);
}

.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[x-placement^=bottom] > .popover-arrow::after {
  border-bottom-color: var(--foreground);
}

.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[x-placement^=bottom] > .popover-arrow::before {
  border-bottom-color: var(--separator);
}

.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[x-placement^=top] > .popover-arrow::after {
  border-top-color: var(--foreground);
}

.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[x-placement^=top] > .popover-arrow::before {
  border-top-color: var(--separator);
}

.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[x-placement^=left] > .popover-arrow::after {
  border-left-color: var(--foreground);
}

.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[x-placement^=left] > .popover-arrow::before {
  border-left-color: var(--separator);
}

/*
*
* Spinner
*
* Bootstrap spinner styles.
*
*/
.spinner-border {
  border-width: 0.15em;
  margin-right: 5px;
}

.spinner-border-sm {
  border-width: 0.15em;
  margin-right: 5px;
}

body.spinner:after {
  content: " ";
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid rgba(0, 0, 0, 0.5);
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
  left: 50%;
  position: fixed;
  top: 50%;
  border-width: 0.15em;
  margin-top: -15px;
  margin-left: -15px;
}
body.spinner #root {
  opacity: 0.3;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.overlay-spinner {
  position: relative;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.overlay-spinner:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-lg);
  background: rgba(var(--foreground-rgb), 0.7);
}
.overlay-spinner:after {
  content: " ";
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid var(--primary);
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
  left: 50%;
  position: absolute;
  top: 50%;
  border-width: 0.15em;
  margin-top: -15px;
  margin-left: -15px;
}

.filled .input-group.spinner .input-group-text {
  background-color: var(--background-light);
}

.form-floating .form-control ~ label {
  z-index: 3;
}

/*
*
* Texts
*
* Bootstrap text styles.
*
*/
.text-primary {
  color: var(--primary) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.text-tertiary {
  color: var(--tertiary) !important;
}

.text-quaternary {
  color: var(--quaternary) !important;
}

.text-success {
  color: var(--success) !important;
}

.text-danger {
  color: var(--danger) !important;
}

.text-warning {
  color: var(--warning) !important;
}

.text-info {
  color: var(--info) !important;
}

.text-light {
  color: var(--light) !important;
}

.text-dark {
  color: var(--dark) !important;
}

.text-body {
  color: var(--body) !important;
}

.text-alternate {
  color: var(--alternate) !important;
}

.text-muted {
  color: var(--muted) !important;
}

.text-white {
  color: var(--light-text) !important;
}

.text-black {
  color: var(--dark-text) !important;
}

.text-separator {
  color: var(--separator) !important;
}

.text-separator-light {
  color: var(--separator-light) !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

/*
*
* Table
*
* Bootstrap table styles.
*
*/
.table {
  --bs-table-bg: rgba(0, 0, 0, 0);
  --bs-table-striped-color: var(--body);
  --bs-table-striped-bg: rgba(var(--body-rgb), 0.05);
  --bs-table-active-color: var(--body);
  --bs-table-active-bg: rgba(var(--body-rgb), 0.1);
  --bs-table-hover-color: var(--body);
  --bs-table-hover-bg: rgba(var(--body-rgb), 0.075);
  color: var(--body);
  border-color: var(--separator-light);
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: var(--separator);
}

.table > :not(caption) > * > * {
  --bs-table-bg: rgba(0, 0, 0, 0);
  color: var(--body);
}

.table-bordered > :not(caption) > * {
  border-color: var(--separator);
}

/*
*
* Toast
*
* Bootstrap toast styles.
*
*/
.toast {
  border-radius: var(--border-radius-md);
  border-color: var(--separator);
  font-size: 1em;
  background: rgba(var(--foreground-rgb), 0.9);
}
.toast .toast-body,
.toast .toast-header {
  padding: var(--card-spacing-sm);
  color: var(--body);
  border: initial;
  background: initial;
}
.toast .toast-header + .toast-body {
  padding-top: initial;
}
.toast .btn-close {
  margin-right: -0.25rem;
  margin-left: 0.25rem;
}

/*
*
* Tooltip
*
* Bootstrap tooltip styles.
*
*/
.tooltip .tooltip-inner {
  background: var(--primary);
  color: var(--light-text);
  font-size: 0.875em;
  border-radius: var(--border-radius-md);
  padding: 0.3rem 0.5rem;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^=top] .tooltip-arrow::before {
  border-top-color: var(--primary);
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^=left] .tooltip-arrow::before {
  border-left-color: var(--primary);
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^=right] .tooltip-arrow::before {
  border-right-color: var(--primary);
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^=bottom] .tooltip-arrow::before {
  border-bottom-color: var(--primary);
}

/*
*
* Autosize
*
* Autosize plugin styles.
*
*/
#chatInput {
  max-height: 80px;
}

/*
*
* Autosuggest
*
* Autosuggest form component styles.
*
*/
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input--focused {
  border: 1px solid var(--primary);
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  z-index: 2;
  position: absolute;
  list-style: none;
  background: var(--foreground);
  width: 100%;
  border-radius: var(--border-radius-md);
  border: 1px solid rgba(var(--primary-rgb), 1) !important;
  padding: 0.75rem;
  margin-top: 4px;
}
.react-autosuggest__suggestions-container--open.scroll .react-autosuggest__suggestions-list {
  max-height: 150px;
  padding-right: 0.5rem;
  margin-right: -0.5rem;
  overflow-y: auto;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0.5rem 0.75rem !important;
  border-radius: var(--border-radius-sm);
  line-height: 1.3;
  margin-top: 5px;
}
.react-autosuggest__suggestion:first-of-type {
  margin-top: 0;
}

.react-autosuggest__suggestion--highlighted {
  color: var(--primary);
  background: var(--separator-light) !important;
}

.autocomplete-scroll .react-autosuggest__suggestions-container--open .react-autosuggest__suggestions-list {
  max-height: 150px;
  padding-right: 0.5rem;
  margin-right: -0.5rem;
  overflow-y: auto;
}
.autocomplete-scroll .react-autosuggest__suggestions-container--open .react-autosuggest__suggestions-list ::-webkit-scrollbar, .autocomplete-scroll .react-autosuggest__suggestions-container--open .react-autosuggest__suggestions-list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
  border-radius: var(--border-radius-md);
  cursor: default !important;
}
.autocomplete-scroll .react-autosuggest__suggestions-container--open .react-autosuggest__suggestions-list ::-webkit-scrollbar-thumb, .autocomplete-scroll .react-autosuggest__suggestions-container--open .react-autosuggest__suggestions-list::-webkit-scrollbar-thumb {
  border-radius: var(--border-radius-md);
  background: rgba(var(--muted-rgb), 0.4);
  cursor: default !important;
}
.autocomplete-scroll .react-autosuggest__suggestions-container--open .react-autosuggest__suggestions-list ::-webkit-scrollbar-button, .autocomplete-scroll .react-autosuggest__suggestions-container--open .react-autosuggest__suggestions-list::-webkit-scrollbar-button {
  background: transparent;
  width: 4px;
  height: 3px;
  cursor: default !important;
}
.autocomplete-scroll .react-autosuggest__suggestions-container--open .react-autosuggest__suggestions-list ::-webkit-scrollbar-track-piece, .autocomplete-scroll .react-autosuggest__suggestions-container--open .react-autosuggest__suggestions-list::-webkit-scrollbar-track-piece {
  background: rgba(var(--muted-rgb), 0.15);
  border-radius: var(--border-radius-md);
  cursor: default !important;
}

.top-label .react-autosuggest__input {
  height: auto;
  min-height: 52px;
  padding: 1.5rem 0.75rem 0.25rem 0.75rem;
}

.filled .react-autosuggest__input {
  min-height: 44px;
  border: 1px solid transparent;
  background: var(--background-light);
  padding-left: 45px;
}
.filled .react-autosuggest__input--focused {
  border: 1px solid var(--primary);
}

/*
*
* Calendar
*
* Full page calendar plugin styles.
*
*/
:root {
  --fc-small-font-size: 0.85em;
  --fc-page-bg-color: var(--foreground);
  --fc-neutral-bg-color: var(--separator-light);
  --fc-neutral-text-color: var(--separator);
  --fc-border-color: var(--separator-light);
  --fc-event-bg-color: var(--primary);
  --fc-event-border-color: var(--primary);
  --fc-event-text-color: var(--light-text);
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);
  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;
  --fc-non-business-color: rgba(var(--primary-rgb), 0.2);
  --fc-bg-event-color: rgba(var(--primary-rgb), 0.2);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(var(--primary-rgb), 0.1);
  --fc-today-bg-color: rgba(var(--primary-rgb), 0.05);
  --fc-now-indicator-color: var(--danger);
}

.fc {
  min-height: 675px;
  max-height: 900px;
}
.fc .fc-scrollgrid {
  border: initial;
}
.fc .table-bordered thead th,
.fc .table-bordered thead td {
  border-bottom-width: 1px;
  border-right: initial;
}
.fc .fc-scrollgrid-section > * {
  border: initial;
}
.fc .fc-col-header-cell-cushion {
  padding-top: 15px;
  padding-bottom: 15px;
}
.fc .fc-toolbar-title {
  font-family: var(--font-heading);
  font-weight: 400;
  margin-bottom: 0;
  font-size: 1em;
  color: var(--primary);
  height: var(--small-title-height);
}
.fc .table-bordered th,
.fc .table-bordered td {
  border-color: var(--separator);
}
.fc a.fc-col-header-cell-cushion {
  color: var(--primary);
}
.fc .fc-timegrid-divider {
  padding: 0 0 1px;
  background: var(--separator);
}
.fc .fc-daygrid-event {
  border-radius: var(--border-radius-sm);
  padding-left: 3px;
  padding-right: 3px;
}

.fc .table-bordered th,
.fc .table-bordered td {
  border-width: 1px;
}

/*
*
* Context Menu
*
* Context menu plugin styles.
*
*/
.react-contexify {
  box-shadow: initial !important;
  border: 1px solid var(--separator) !important;
  padding: 0.5rem 0 !important;
  background-color: var(--foreground) !important;
  border-radius: var(--border-radius-md) !important;
}

.react-contexify__item__content {
  color: var(--body) !important;
  background: initial !important;
  border: initial !important;
  font-size: 1em !important;
  line-height: 1;
  padding: 0.5rem 1.5rem !important;
  font-family: var(--font);
}

.react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content,
.react-contexify__item:not(.react-contexify__item--disabled):focus > .react-contexify__item__content {
  color: var(--primary) !important;
}

.submenu-arrow {
  content: "";
  border: initial;
  border-width: initial;
  right: 1rem;
  width: 5px;
  height: 5px;
  border-top: 1px solid var(--body);
  border-right: 1px solid var(--body);
  transform: rotate(45deg);
  order: 3;
  margin-top: -2px;
  position: absolute;
}

/*
*
* Datatables
*
* Datatables plugin styles.
*
*/
table.react-table {
  border-collapse: separate;
  width: 100%;
}
table.react-table thead th {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  height: 20px;
}
table.react-table thead th.empty:after, table.react-table thead th.empty:before {
  content: " " !important;
  display: none !important;
}
table.react-table thead .sorting:after,
table.react-table thead .sorting_desc:after,
table.react-table thead .sorting_asc:after,
table.react-table thead .sorting_asc_disabled:after,
table.react-table thead .sorting_desc_disabled:after {
  content: " " !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  position: relative;
  left: 10px;
  top: 0;
  opacity: 1;
}
html[data-color*=light] table.react-table thead .sorting:after {
  background-image: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 10'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bopacity:0.3;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M2.68.82h0a.27.27,0,0,0-.36,0h0L.82,2.32a.27.27,0,0,0,0,.36.27.27,0,0,0,.36,0L2.25,1.6V9a.25.25,0,0,0,.5,0V1.6L3.82,2.68a.27.27,0,0,0,.36,0,.27.27,0,0,0,0-.36Z'/%3E%3Cpath class='cls-1' d='M8.18,7.32a.27.27,0,0,0-.36,0L6.75,8.4V1a.25.25,0,0,0-.5,0V8.4L5.18,7.32a.25.25,0,0,0-.36.36l1.5,1.5a.27.27,0,0,0,.36,0l1.5-1.5A.27.27,0,0,0,8.18,7.32Z'/%3E%3C/svg%3E");
}
html[data-color*=light] table.react-table thead .sorting_desc:after {
  background-image: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 10'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bopacity:0.3;%7D.cls-2%7Bopacity:0.9;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M2.68.82h0a.27.27,0,0,0-.36,0h0L.82,2.32a.27.27,0,0,0,0,.36.27.27,0,0,0,.36,0L2.25,1.6V9a.25.25,0,0,0,.5,0V1.6L3.82,2.68a.27.27,0,0,0,.36,0,.27.27,0,0,0,0-.36Z'/%3E%3Cpath class='cls-2' d='M8.18,7.32a.27.27,0,0,0-.36,0L6.75,8.4V1a.25.25,0,0,0-.5,0V8.4L5.18,7.32a.25.25,0,0,0-.36.36l1.5,1.5a.27.27,0,0,0,.36,0l1.5-1.5A.27.27,0,0,0,8.18,7.32Z'/%3E%3C/svg%3E");
}
html[data-color*=light] table.react-table thead .sorting_asc:after {
  background-image: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 10'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bopacity:0.9;%7D.cls-2%7Bopacity:0.3;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M2.68.82h0a.27.27,0,0,0-.36,0h0L.82,2.32a.27.27,0,0,0,0,.36.27.27,0,0,0,.36,0L2.25,1.6V9a.25.25,0,0,0,.5,0V1.6L3.82,2.68a.27.27,0,0,0,.36,0,.27.27,0,0,0,0-.36Z'/%3E%3Cpath class='cls-2' d='M8.18,7.32a.27.27,0,0,0-.36,0L6.75,8.4V1a.25.25,0,0,0-.5,0V8.4L5.18,7.32a.25.25,0,0,0-.36.36l1.5,1.5a.27.27,0,0,0,.36,0l1.5-1.5A.27.27,0,0,0,8.18,7.32Z'/%3E%3C/svg%3E");
}
html[data-color*=dark] table.react-table thead .sorting:after {
  background-image: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 10'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;opacity:0.3;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M2.68.82h0a.27.27,0,0,0-.36,0h0L.82,2.32a.27.27,0,0,0,0,.36.27.27,0,0,0,.36,0L2.25,1.6V9a.25.25,0,0,0,.5,0V1.6L3.82,2.68a.27.27,0,0,0,.36,0,.27.27,0,0,0,0-.36Z'/%3E%3Cpath class='cls-1' d='M8.18,7.32a.27.27,0,0,0-.36,0L6.75,8.4V1a.25.25,0,0,0-.5,0V8.4L5.18,7.32a.25.25,0,0,0-.36.36l1.5,1.5a.27.27,0,0,0,.36,0l1.5-1.5A.27.27,0,0,0,8.18,7.32Z'/%3E%3C/svg%3E");
}
html[data-color*=dark] table.react-table thead .sorting_desc:after {
  background-image: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 10'%3E%3Cdefs%3E%3Cstyle%3E.cls-1,.cls-2%7Bfill:%23fff;%7D.cls-1%7Bopacity:0.3;%7D.cls-2%7Bopacity:0.9;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M2.68.82h0a.27.27,0,0,0-.36,0h0L.82,2.32a.27.27,0,0,0,0,.36.27.27,0,0,0,.36,0L2.25,1.6V9a.25.25,0,0,0,.5,0V1.6L3.82,2.68a.27.27,0,0,0,.36,0,.27.27,0,0,0,0-.36Z'/%3E%3Cpath class='cls-2' d='M8.18,7.32a.27.27,0,0,0-.36,0L6.75,8.4V1a.25.25,0,0,0-.5,0V8.4L5.18,7.32a.25.25,0,0,0-.36.36l1.5,1.5a.27.27,0,0,0,.36,0l1.5-1.5A.27.27,0,0,0,8.18,7.32Z'/%3E%3C/svg%3E");
}
html[data-color*=dark] table.react-table thead .sorting_asc:after {
  background-image: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 10'%3E%3Cdefs%3E%3Cstyle%3E.cls-1,.cls-2%7Bfill:%23fff;%7D.cls-1%7Bopacity:0.9;%7D.cls-2%7Bopacity:0.3;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M2.68.82h0a.27.27,0,0,0-.36,0h0L.82,2.32a.27.27,0,0,0,0,.36.27.27,0,0,0,.36,0L2.25,1.6V9a.25.25,0,0,0,.5,0V1.6L3.82,2.68a.27.27,0,0,0,.36,0,.27.27,0,0,0,0-.36Z'/%3E%3Cpath class='cls-2' d='M8.18,7.32a.27.27,0,0,0-.36,0L6.75,8.4V1a.25.25,0,0,0-.5,0V8.4L5.18,7.32a.25.25,0,0,0-.36.36l1.5,1.5a.27.27,0,0,0,.36,0l1.5-1.5A.27.27,0,0,0,8.18,7.32Z'/%3E%3C/svg%3E");
}
table.react-table tbody td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
table.react-table.nowrap th, table.react-table.nowrap td {
  white-space: nowrap;
}
table.react-table.hover {
  border-spacing: 0 2px;
}
table.react-table.hover tbody tr:hover, table.react-table.hover tbody tr.selected {
  background-color: rgba(var(--separator-rgb), 0.3);
}
table.react-table.hover tbody tr.selected:hover {
  background-color: rgba(var(--separator-rgb), 0.5);
}
table.react-table.stripe {
  border-spacing: 0 2px;
}
table.react-table.stripe tbody tr.odd {
  background-color: rgba(var(--body-rgb), 0.05);
}
table.react-table.rows {
  border-spacing: 0 calc(var(--card-spacing-xs) / 10 * 7);
}
table.react-table.rows thead tr, table.react-table.rows thead th {
  padding-left: var(--card-spacing-sm);
  padding-right: var(--card-spacing-sm);
}
table.react-table.rows tbody tr, table.react-table.rows tbody td {
  padding-left: var(--card-spacing-sm);
  padding-right: var(--card-spacing-sm);
}
table.react-table.rows tbody tr {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03);
  border-radius: var(--border-radius-lg);
}
table.react-table.rows tbody tr.selected td {
  border-color: rgba(var(--primary-rgb), 0.5);
}
table.react-table.rows tbody tr:hover.selected td {
  border-color: rgba(var(--primary-rgb), 1);
}
table.react-table.rows tbody td {
  height: 50px;
  border: 1px solid transparent;
  border-width: 1px 0;
  background: var(--foreground);
}
table.react-table.rows tbody td:first-child {
  border-top-left-radius: var(--border-radius-lg);
  border-bottom-left-radius: var(--border-radius-lg);
  border-width: 1px 0 1px 1px;
}
table.react-table.rows tbody td:last-child {
  border-top-right-radius: var(--border-radius-lg);
  border-bottom-right-radius: var(--border-radius-lg);
  border-width: 1px 1px 1px 0;
}
table.react-table.rows tbody tr:hover td {
  border-color: rgba(var(--separator-rgb), 1);
}
table.react-table.boxed {
  border-spacing: 0 2px;
  padding-top: calc(var(--card-spacing) / 2);
}
table.react-table.boxed thead tr, table.react-table.boxed thead td {
  padding-left: var(--card-spacing-sm);
  padding-right: var(--card-spacing-sm);
}
table.react-table.boxed tbody tr, table.react-table.boxed tbody td {
  padding-left: var(--card-spacing-sm);
  padding-right: var(--card-spacing-sm);
}
table.react-table.boxed tbody tr:hover, table.react-table.boxed tbody tr.selected {
  background-color: rgba(var(--separator-rgb), 0.3);
}
table.react-table.boxed tbody tr.selected:hover {
  background-color: rgba(var(--separator-rgb), 0.5);
}
table.react-table.boxed tr td:first-of-type,
table.react-table.boxed tr th:first-of-type {
  padding-left: calc(var(--card-spacing) / 2);
}
table.react-table.boxed tr td:last-of-type,
table.react-table.boxed tr th:last-of-type {
  padding-right: calc(var(--card-spacing) / 2);
}
table.react-table.boxed tr td:first-child {
  border-top-left-radius: var(--border-radius-md);
  border-bottom-left-radius: var(--border-radius-md);
}
table.react-table.boxed tr td:last-child {
  border-top-right-radius: var(--border-radius-md);
  border-bottom-right-radius: var(--border-radius-md);
}

/*
*
* Listjs
*
* Listjs plugin styles.
*
*/
html[data-color*=light] .custom-sort .sort:after {
  content: " ";
  display: inline-block;
  width: 10px;
  height: 10px;
  position: static;
  margin-left: 10px;
  opacity: 1;
  background-image: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 10'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bopacity:0.3;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M2.68.82h0a.27.27,0,0,0-.36,0h0L.82,2.32a.27.27,0,0,0,0,.36.27.27,0,0,0,.36,0L2.25,1.6V9a.25.25,0,0,0,.5,0V1.6L3.82,2.68a.27.27,0,0,0,.36,0,.27.27,0,0,0,0-.36Z'/%3E%3Cpath class='cls-1' d='M8.18,7.32a.27.27,0,0,0-.36,0L6.75,8.4V1a.25.25,0,0,0-.5,0V8.4L5.18,7.32a.25.25,0,0,0-.36.36l1.5,1.5a.27.27,0,0,0,.36,0l1.5-1.5A.27.27,0,0,0,8.18,7.32Z'/%3E%3C/svg%3E");
}
html[data-color*=light] .custom-sort .sort.desc:after {
  content: " ";
  display: inline-block;
  width: 10px;
  height: 10px;
  position: static;
  margin-left: 10px;
  top: 0;
  opacity: 1;
  background-image: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 10'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bopacity:0.3;%7D.cls-2%7Bopacity:0.9;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M2.68.82h0a.27.27,0,0,0-.36,0h0L.82,2.32a.27.27,0,0,0,0,.36.27.27,0,0,0,.36,0L2.25,1.6V9a.25.25,0,0,0,.5,0V1.6L3.82,2.68a.27.27,0,0,0,.36,0,.27.27,0,0,0,0-.36Z'/%3E%3Cpath class='cls-2' d='M8.18,7.32a.27.27,0,0,0-.36,0L6.75,8.4V1a.25.25,0,0,0-.5,0V8.4L5.18,7.32a.25.25,0,0,0-.36.36l1.5,1.5a.27.27,0,0,0,.36,0l1.5-1.5A.27.27,0,0,0,8.18,7.32Z'/%3E%3C/svg%3E");
}
html[data-color*=light] .custom-sort .sort.asc:after {
  content: " ";
  display: inline-block;
  width: 10px;
  height: 10px;
  position: static;
  margin-left: 10px;
  opacity: 1;
  background-image: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 10'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bopacity:0.9;%7D.cls-2%7Bopacity:0.3;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M2.68.82h0a.27.27,0,0,0-.36,0h0L.82,2.32a.27.27,0,0,0,0,.36.27.27,0,0,0,.36,0L2.25,1.6V9a.25.25,0,0,0,.5,0V1.6L3.82,2.68a.27.27,0,0,0,.36,0,.27.27,0,0,0,0-.36Z'/%3E%3Cpath class='cls-2' d='M8.18,7.32a.27.27,0,0,0-.36,0L6.75,8.4V1a.25.25,0,0,0-.5,0V8.4L5.18,7.32a.25.25,0,0,0-.36.36l1.5,1.5a.27.27,0,0,0,.36,0l1.5-1.5A.27.27,0,0,0,8.18,7.32Z'/%3E%3C/svg%3E");
}

html[data-color*=dark] .custom-sort .sort:after {
  content: " ";
  display: inline-block;
  width: 10px;
  height: 10px;
  position: static;
  margin-left: 10px;
  opacity: 1;
  background-image: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 10'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;opacity:0.3;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M2.68.82h0a.27.27,0,0,0-.36,0h0L.82,2.32a.27.27,0,0,0,0,.36.27.27,0,0,0,.36,0L2.25,1.6V9a.25.25,0,0,0,.5,0V1.6L3.82,2.68a.27.27,0,0,0,.36,0,.27.27,0,0,0,0-.36Z'/%3E%3Cpath class='cls-1' d='M8.18,7.32a.27.27,0,0,0-.36,0L6.75,8.4V1a.25.25,0,0,0-.5,0V8.4L5.18,7.32a.25.25,0,0,0-.36.36l1.5,1.5a.27.27,0,0,0,.36,0l1.5-1.5A.27.27,0,0,0,8.18,7.32Z'/%3E%3C/svg%3E");
}
html[data-color*=dark] .custom-sort .sort.desc:after {
  content: " ";
  display: inline-block;
  width: 10px;
  height: 10px;
  position: static;
  margin-left: 10px;
  opacity: 1;
  background-image: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 10'%3E%3Cdefs%3E%3Cstyle%3E.cls-1,.cls-2%7Bfill:%23fff;%7D.cls-1%7Bopacity:0.3;%7D.cls-2%7Bopacity:0.9;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M2.68.82h0a.27.27,0,0,0-.36,0h0L.82,2.32a.27.27,0,0,0,0,.36.27.27,0,0,0,.36,0L2.25,1.6V9a.25.25,0,0,0,.5,0V1.6L3.82,2.68a.27.27,0,0,0,.36,0,.27.27,0,0,0,0-.36Z'/%3E%3Cpath class='cls-2' d='M8.18,7.32a.27.27,0,0,0-.36,0L6.75,8.4V1a.25.25,0,0,0-.5,0V8.4L5.18,7.32a.25.25,0,0,0-.36.36l1.5,1.5a.27.27,0,0,0,.36,0l1.5-1.5A.27.27,0,0,0,8.18,7.32Z'/%3E%3C/svg%3E");
}
html[data-color*=dark] .custom-sort .sort.asc:after {
  content: " ";
  display: inline-block;
  width: 10px;
  height: 10px;
  position: static;
  margin-left: 10px;
  opacity: 1;
  background-image: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 10'%3E%3Cdefs%3E%3Cstyle%3E.cls-1,.cls-2%7Bfill:%23fff;%7D.cls-1%7Bopacity:0.9;%7D.cls-2%7Bopacity:0.3;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M2.68.82h0a.27.27,0,0,0-.36,0h0L.82,2.32a.27.27,0,0,0,0,.36.27.27,0,0,0,.36,0L2.25,1.6V9a.25.25,0,0,0,.5,0V1.6L3.82,2.68a.27.27,0,0,0,.36,0,.27.27,0,0,0,0-.36Z'/%3E%3Cpath class='cls-2' d='M8.18,7.32a.27.27,0,0,0-.36,0L6.75,8.4V1a.25.25,0,0,0-.5,0V8.4L5.18,7.32a.25.25,0,0,0-.36.36l1.5,1.5a.27.27,0,0,0,.36,0l1.5-1.5A.27.27,0,0,0,8.18,7.32Z'/%3E%3C/svg%3E");
}

/*
*
* Datepicker
*
* Datepicker form control styles.
*
*/
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  border: 1px solid var(--primary) !important;
  color: var(--body) !important;
  border-radius: var(--border-radius-md) !important;
  padding: 0.5rem !important;
  font-family: var(--font) !important;
  background: var(--foreground) !important;
  background-color: var(--foreground) !important;
}
.react-datepicker .react-datepicker__header {
  background: var(--foreground) !important;
  border-top-left-radius: var(--border-radius-md) !important;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: var(--border-radius-md) !important;
}

.react-datepicker__navigation {
  top: 0.75rem !important;
}

.react-datepicker__navigation-icon::before,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-color: var(--alternate) !important;
  border-width: 1px 1px 0 0 !important;
  height: 8px !important;
  top: 6px !important;
  width: 8px !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 1em !important;
  font-weight: initial !important;
  font-family: var(--font) !important;
  color: var(--body) !important;
}

.react-datepicker__header {
  border-bottom: initial !important;
}

.react-datepicker__navigation--previous {
  left: 10px !important;
}

.react-datepicker__navigation--next {
  right: 10px !important;
}

.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--body) !important;
}

.react-datepicker__day-name {
  color: var(--alternate) !important;
}

.react-datepicker-popper {
  z-index: 4 !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: var(--primary) !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-bottom-color: var(--foreground) !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  margin-top: -5px !important;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  margin-bottom: -5px !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  border-width: 5px !important;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  border-top-color: var(--primary) !important;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  border-bottom: none;
  border-top-color: var(--foreground) !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0.1rem !important;
  width: 2rem !important;
  line-height: 2rem !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--primary) !important;
  color: var(--light-text) !important;
  border-radius: var(--border-radius-sm) !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--primary) !important;
  color: var(--light-text) !important;
  border-radius: var(--border-radius-sm) !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: var(--primary) !important;
  color: var(--light-text) !important;
  border-radius: var(--border-radius-sm) !important;
}

.react-datepicker-popper[data-placement^=top] {
  padding-bottom: 5px !important;
}

.react-datepicker-popper[data-placement=bottom-start] .react-datepicker__triangle,
.react-datepicker-popper[data-placement=top-start] .react-datepicker__triangle {
  transform: translate(0, 0px) !important;
  left: 2rem !important;
}

.react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle,
.react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
  transform: translate(0, 0px) !important;
  left: initial !important;
  right: 2rem !important;
}

.inline-datepicker-container {
  padding: 1.25rem 0.75rem !important;
}
.inline-datepicker-container .react-datepicker {
  border: initial !important;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: var(--muted) !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 95px !important;
}

.react-datepicker__time-container {
  border: initial !important;
}

.react-datepicker__time-list ::-webkit-scrollbar, .react-datepicker__time-list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
  border-radius: var(--border-radius-md);
  cursor: default !important;
}
.react-datepicker__time-list ::-webkit-scrollbar-thumb, .react-datepicker__time-list::-webkit-scrollbar-thumb {
  border-radius: var(--border-radius-md);
  background: rgba(var(--muted-rgb), 0.4);
  cursor: default !important;
}
.react-datepicker__time-list ::-webkit-scrollbar-button, .react-datepicker__time-list::-webkit-scrollbar-button {
  background: transparent;
  width: 4px;
  height: 3px;
  cursor: default !important;
}
.react-datepicker__time-list ::-webkit-scrollbar-track-piece, .react-datepicker__time-list::-webkit-scrollbar-track-piece {
  background: rgba(var(--muted-rgb), 0.15);
  border-radius: var(--border-radius-md);
  cursor: default !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  border-radius: var(--border-radius-sm);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: var(--primary) !important;
  color: var(--light-text) !important;
}

.react-datepicker__year-wrapper {
  justify-content: center;
}

.react-datepicker__year .react-datepicker__year-text,
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  padding-top: 2px;
  padding-bottom: 2px;
}

.react-datepicker__year-dropdown-container .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-dropdown-container .react-datepicker__month-read-view--down-arrow {
  top: 2px !important;
}

.react-datepicker__header__dropdown {
  margin-top: 3px;
  margin-bottom: 3px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  padding-left: 15px !important;
  padding-right: 15px !important;
  cursor: pointer;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background: var(--foreground) !important;
  border-color: var(--muted) !important;
  padding: 5px !important;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background: var(--separator-light) !important;
}

.react-datepicker__navigation--years {
  display: none !important;
}

/*
*
* Dropzone
*
* Dropzone form control styles.
*
*/
.dzu-dropzone {
  overflow: initial !important;
  min-height: 112px !important;
  border: 1px solid var(--separator) !important;
  background: var(--foreground) !important;
  padding: var(--card-spacing-sm) !important;
  border-radius: var(--border-radius-md) !important;
  align-items: flex-start !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  padding-bottom: initial !important;
}
.dzu-dropzone.dzu-dropzoneActive {
  border-color: var(--primary) !important;
}

.dzu-inputLabel {
  color: var(--body) !important;
  font-size: 1em !important;
  font-weight: initial !important;
  font-family: var(--font) !important;
}

.dzu-previewContainer progress {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  height: 3px;
}
.dzu-previewContainer progress::-webkit-progress-value {
  background: var(--primary);
}

.dzu-preview-file-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dzu-preview-success,
.dzu-preview-error,
.dzu-preview-spinner {
  position: absolute;
  background: var(--foreground);
  color: var(--primary);
  border-radius: var(--border-radius-xl);
  width: 20px;
  height: 20px;
  text-align: center;
  top: 3px;
  left: 3px;
  padding-top: 1px;
}
.dzu-preview-success i,
.dzu-preview-error i,
.dzu-preview-spinner i {
  font-size: 14px;
}

.dzu-preview-spinner {
  padding-left: 2px;
}

.dzu-preview-icon {
  font-size: 24px !important;
  color: var(--primary);
}

.dzu-inputLabel {
  width: 100%;
}

.dzu-dropzone.row {
  border: initial !important;
  padding: initial !important;
  min-height: 226px;
}
.dzu-dropzone.row .dzu-inputLabel {
  padding-top: 4px !important;
}

.filled .dzu-dropzone {
  border-color: var(--background-light) !important;
  background: var(--background-light) !important;
  padding-left: 45px !important;
}
.filled .dzu-dropzone.dzu-dropzoneActive {
  border-color: var(--primary) !important;
}
.filled .dzu-inputLabel {
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 45px !important;
  padding-top: 12px !important;
  color: var(--alternate) !important;
}

.top-label .dzu-dropzone {
  padding: 2rem 0.5rem 0 1rem !important;
  min-height: 124px !important;
}
.top-label .dzu-inputLabel {
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  font-size: 1em !important;
  z-index: initial !important;
}

.top-label label:not(.form-check-label).dzu-inputLabel {
  font-size: 1em !important;
  background: initial !important;
  cursor: pointer !important;
}

.form-floating .dzu-dropzone {
  padding: 1rem !important;
  padding-bottom: initial !important;
  min-height: 124px !important;
}
.form-floating .dzu-inputLabel {
  pointer-events: initial !important;
}
.form-floating .dzu-dropzone.full {
  padding-top: 2rem !important;
}
.form-floating .dzu-dropzone.full ~ label {
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  color: var(--muted);
}

/*
*
* Editor
*
* Quill editor form control styles.
*
*/
.html-editor {
  display: flex;
  flex-direction: column;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: var(--separator) !important;
  background-color: var(--foreground) !important;
}

.ql-toolbar.ql-snow {
  border-color: var(--separator) !important;
}

.ql-container.ql-snow {
  border-color: var(--separator) !important;
}

.html-editor-bubble {
  border: 1px solid var(--separator) !important;
  border-radius: var(--border-radius-md) !important;
}
.html-editor-bubble.active {
  border-color: rgba(var(--primary-rgb), 1) !important;
}

.ql-tooltip {
  z-index: 1010 !important;
}

.ql-bubble .ql-tooltip {
  border-radius: var(--border-radius-md) !important;
  border: 1px solid var(--separator) !important;
  background-color: var(--foreground) !important;
  color: var(--body) !important;
}

.ql-bubble .ql-tooltip,
.ql-snow .ql-tooltip {
  color: var(--body) !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--primary) !important;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--primary) !important;
}

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: var(--primary) !important;
}

.ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
  border-bottom: 6px solid var(--separator) !important;
}
.ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow:after {
  position: absolute !important;
  display: block !important;
  content: "" !important;
  border-color: transparent !important;
  border-style: solid !important;
  border-bottom-color: var(--foreground) !important;
  border-width: 0 5px 5px 5px !important;
  width: 5px !important;
  height: 5px !important;
  left: -5px !important;
  top: 1px !important;
}

.ql-editor {
  padding: 0.5rem 0.75rem 0.5rem 0.75rem !important;
  font-size: 1em !important;
  line-height: 1.5 !important;
}

.ql-bubble .ql-picker-options {
  background-color: var(--foreground) !important;
  border: 1px solid var(--separator) !important;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow,
.ql-bubble .ql-editor {
  border-radius: var(--border-radius-md) !important;
}

.ql-toolbar.ql-snow {
  border-bottom-left-radius: initial !important;
  border-bottom-right-radius: initial !important;
}

.ql-container.ql-snow {
  border-top-left-radius: initial !important;
  border-top-right-radius: initial !important;
}

.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid transparent !important;
  outline: initial !important;
  box-shadow: initial !important;
}

.ql-editor.ql-blank::before {
  padding: initial !important;
  font-style: initial !important;
  color: var(--alternate) !important;
  left: initial !important;
  right: initial !important;
}

.ql-container.active .ql-editor {
  border-color: rgba(var(--primary-rgb), 1) !important;
}

.editor-container.active .ql-toolbar {
  border-color: rgba(var(--primary-rgb), 1) !important;
  border-bottom: 1px solid var(--separator) !important;
}
.editor-container.active .html-editor {
  border-color: rgba(var(--primary-rgb), 1) !important;
}

.filled.custom-control-container.editor-container {
  padding-top: initial !important;
  padding-bottom: initial !important;
  padding-right: initial !important;
}
.filled.custom-control-container.editor-container .ql-editor {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  padding-left: initial !important;
  padding-right: initial !important;
}
.filled.custom-control-container.editor-container > i {
  margin-top: 0 !important;
  top: 14px !important;
}
.filled.custom-control-container.editor-container .ql-editor.ql-blank::before {
  font-family: var(--font) !important;
  color: var(--muted) !important;
  top: 0.75rem !important;
  font-size: 1em !important;
}
.filled.custom-control-container.editor-container.active {
  border-color: rgba(var(--primary-rgb), 1) !important;
  background: initial !important;
}

.filled.custom-control-container .ql-editor {
  padding-right: 0.75rem !important;
}

.top-label.custom-control-container.editor-container {
  padding: initial !important;
}
.top-label.custom-control-container.editor-container .ql-editor {
  padding: 0 0.75rem 0.5rem 0.75rem !important;
  color: var(--body) !important;
  margin-top: 1.65rem !important;
}
.top-label.custom-control-container.editor-container .ql-editor.ql-blank::before {
  font-family: var(--font) !important;
  color: var(--muted) !important;
  top: 1.65rem !important;
  font-size: 1em !important;
}
.top-label.custom-control-container.editor-container.active {
  border-color: rgba(var(--primary-rgb), 1) !important;
}

.form-floating .html-editor {
  border: 1px solid var(--separator) !important;
  border-radius: var(--border-radius-md) !important;
}
.form-floating .html-editor .ql-editor {
  padding-top: 1.65rem !important;
}
.form-floating .html-editor.active {
  border-color: rgba(var(--primary-rgb), 1) !important;
}
.form-floating .html-editor ~ label {
  transition: transform 0.1s ease-in-out, padding 0.1s ease-in-out, background-color 0s ease-in-out, height 0.1s ease-in-out !important;
}
.form-floating .html-editor.active ~ label, .form-floating .html-editor.full ~ label {
  color: var(--muted) !important;
  background: var(--foreground) !important;
  padding-top: 0.25rem !important;
  padding-bottom: 0.05rem !important;
  border-top-left-radius: var(--border-radius-md) !important;
  height: auto !important;
  transform: scale(0.85) translateY(1px) translateX(0.15rem) !important;
  transition: transform 0.1s ease-in-out, padding 0.1s ease-in-out, background-color 0.1s ease-in-out 0.1s, height 0.1s ease-in-out !important;
}

.editor-container.active .ql-bubble .ql-tooltip .ql-toolbar,
.editor-container .ql-bubble .ql-tooltip .ql-toolbar {
  border-bottom: initial !important;
}

.ql-bubble .ql-stroke,
.ql-snow .ql-stroke {
  stroke: var(--body) !important;
}

.ql-bubble .ql-fill,
.ql-bubble .ql-stroke.ql-fill,
.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: var(--body) !important;
}

.ql-bubble.ql-toolbar button:hover,
.ql-bubble .ql-toolbar button:hover,
.ql-bubble.ql-toolbar button:focus,
.ql-bubble .ql-toolbar button:focus,
.ql-bubble.ql-toolbar button.ql-active,
.ql-bubble .ql-toolbar button.ql-active,
.ql-bubble.ql-toolbar .ql-picker-label:hover,
.ql-bubble .ql-toolbar .ql-picker-label:hover,
.ql-bubble.ql-toolbar .ql-picker-label.ql-active,
.ql-bubble .ql-toolbar .ql-picker-label.ql-active,
.ql-bubble.ql-toolbar .ql-picker-item:hover,
.ql-bubble .ql-toolbar .ql-picker-item:hover,
.ql-bubble.ql-toolbar .ql-picker-item.ql-selected,
.ql-bubble .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--primary) !important;
}

.ql-bubble.ql-toolbar button:hover .ql-stroke,
.ql-bubble .ql-toolbar button:hover .ql-stroke,
.ql-bubble.ql-toolbar button:focus .ql-stroke,
.ql-bubble .ql-toolbar button:focus .ql-stroke,
.ql-bubble.ql-toolbar button.ql-active .ql-stroke,
.ql-bubble .ql-toolbar button.ql-active .ql-stroke,
.ql-bubble.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-bubble.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-bubble.ql-toolbar button:hover .ql-stroke-miter,
.ql-bubble .ql-toolbar button:hover .ql-stroke-miter,
.ql-bubble.ql-toolbar button:focus .ql-stroke-miter,
.ql-bubble .ql-toolbar button:focus .ql-stroke-miter,
.ql-bubble.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-bubble .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-bubble.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-bubble .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-bubble.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-bubble .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--primary) !important;
}

.ql-bubble.ql-toolbar button:hover .ql-fill,
.ql-bubble .ql-toolbar button:hover .ql-fill,
.ql-bubble.ql-toolbar button:focus .ql-fill,
.ql-bubble .ql-toolbar button:focus .ql-fill,
.ql-bubble.ql-toolbar button.ql-active .ql-fill,
.ql-bubble .ql-toolbar button.ql-active .ql-fill,
.ql-bubble.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-bubble .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-bubble.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-bubble .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-bubble.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-bubble.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-bubble.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-bubble.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-bubble.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: var(--primary) !important;
}

.ql-bubble .ql-picker,
.ql-snow .ql-picker {
  color: var(--body) !important;
}

.ql-bubble .ql-toolbar .ql-formats,
.ql-bubble .ql-picker-label,
.ql-bubble .ql-picker {
  outline: initial !important;
}

.ql-bubble .ql-picker.ql-expanded .ql-picker-options {
  border-radius: var(--border-radius-md) !important;
  padding: 0.5rem 1.5rem !important;
}

.ql-bubble .ql-color-picker .ql-picker-options {
  width: 145px !important;
}

.ql-bubble .ql-color-picker .ql-picker-item {
  border-radius: var(--border-radius-sm) !important;
}

.ql-bubble .ql-editor h1,
.ql-bubble .ql-editor h2,
.ql-bubble .ql-editor h3,
.ql-bubble .ql-editor h4,
.ql-bubble .ql-editor h5,
.ql-bubble .ql-editor h6,
.ql-bubble .ql-editor .h1,
.ql-bubble .ql-editor .h2,
.ql-bubble .ql-editor .h3,
.ql-bubble .ql-editor .h4,
.ql-bubble .ql-editor .h5,
.ql-bubble .ql-editor .h6,
.ql-snow .ql-editor h1,
.ql-snow .ql-editor h2,
.ql-snow .ql-editor h3,
.ql-snow .ql-editor h4,
.ql-snow .ql-editor h5,
.ql-snow .ql-editor h6,
.ql-snow .ql-editor .h1,
.ql-snow .ql-editor .h2,
.ql-snow .ql-editor .h3,
.ql-snow .ql-editor .h4,
.ql-snow .ql-editor .h5,
.ql-snow .ql-editor .h6 {
  font-family: var(--font-heading) !important;
  color: var(--body) !important;
  font-weight: 400 !important;
  line-height: 1.25 !important;
  margin-bottom: 0.75rem !important;
}
.ql-bubble .ql-editor h1,
.ql-bubble .ql-editor .h1,
.ql-snow .ql-editor h1,
.ql-snow .ql-editor .h1 {
  font-size: 1.8em !important;
}
@media (max-width: 767.98px) {
  .ql-bubble .ql-editor h1,
  .ql-bubble .ql-editor .h1,
  .ql-snow .ql-editor h1,
  .ql-snow .ql-editor .h1 {
    font-size: 1.5em !important;
  }
}
.ql-bubble .ql-editor h2,
.ql-bubble .ql-editor .h2,
.ql-snow .ql-editor h2,
.ql-snow .ql-editor .h2 {
  font-size: 1.65em !important;
}
@media (max-width: 767.98px) {
  .ql-bubble .ql-editor h2,
  .ql-bubble .ql-editor .h2,
  .ql-snow .ql-editor h2,
  .ql-snow .ql-editor .h2 {
    font-size: 1.3em !important;
  }
}
.ql-bubble .ql-editor h3,
.ql-bubble .ql-editor .h3,
.ql-snow .ql-editor h3,
.ql-snow .ql-editor .h3 {
  font-size: 1.5em !important;
}
@media (max-width: 767.98px) {
  .ql-bubble .ql-editor h3,
  .ql-bubble .ql-editor .h3,
  .ql-snow .ql-editor h3,
  .ql-snow .ql-editor .h3 {
    font-size: 1.25em !important;
  }
}
.ql-bubble .ql-editor h4,
.ql-bubble .ql-editor .h4,
.ql-snow .ql-editor h4,
.ql-snow .ql-editor .h4 {
  font-size: 1.35em !important;
}
@media (max-width: 767.98px) {
  .ql-bubble .ql-editor h4,
  .ql-bubble .ql-editor .h4,
  .ql-snow .ql-editor h4,
  .ql-snow .ql-editor .h4 {
    font-size: 1.15em !important;
  }
}
.ql-bubble .ql-editor h5,
.ql-bubble .ql-editor .h5,
.ql-snow .ql-editor h5,
.ql-snow .ql-editor .h5 {
  font-size: 1.1em !important;
}
@media (max-width: 767.98px) {
  .ql-bubble .ql-editor h5,
  .ql-bubble .ql-editor .h5,
  .ql-snow .ql-editor h5,
  .ql-snow .ql-editor .h5 {
    font-size: 1em !important;
  }
}
.ql-bubble .ql-editor h6,
.ql-bubble .ql-editor .h6,
.ql-snow .ql-editor h6,
.ql-snow .ql-editor .h6 {
  font-size: 1em !important;
}
@media (max-width: 767.98px) {
  .ql-bubble .ql-editor h6,
  .ql-bubble .ql-editor .h6,
  .ql-snow .ql-editor h6,
  .ql-snow .ql-editor .h6 {
    font-size: 1em !important;
  }
}

/*
*
* Lightbox
*
* Lightbox plugin styles.
*
*/
.ReactModal__Overlay {
  z-index: 1200 !important;
}

.ril__caption,
.ril__toolbar {
  background: initial !important;
}

.ril__builtinButton {
  font-size: 1em;
  line-height: 1rem;
  border: initial;
  box-shadow: initial !important;
  transition: all var(--transition-time-short);
  border-radius: var(--border-radius-md);
  height: 40px !important;
  width: 40px !important;
  background-size: 16px !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  opacity: 0.75 !important;
}
.ril__builtinButton:not([disabled]):hover {
  opacity: 1 !important;
  background-color: rgb(0, 0, 0) !important;
}

.ril__toolbarItem {
  margin: 2px !important;
}

.ril__outer {
  background: rgba(0, 0, 0, 0.8) !important;
}

.ril__toolbar {
  top: 10px !important;
}

.ril__navButtons {
  height: 40px !important;
  width: 40px !important;
  padding: initial !important;
  background-size: 16px !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  opacity: 0.75 !important;
  border-radius: var(--border-radius-md);
  background-size: 20px !important;
}
.ril__navButtons:not([disabled]):hover {
  opacity: 1 !important;
  background-color: rgb(0, 0, 0) !important;
}

.ril__caption {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.ril__caption .ril__captionContent {
  color: var(--light-text) !important;
  padding: 15px 20px !important;
  margin: 0 auto !important;
}

.ril__navButtonNext {
  right: 3rem !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M7 4L12.6464 9.64645C12.8417 9.84171 12.8417 10.1583 12.6464 10.3536L7 16'%3E%3C/path%3E%3C/svg%3E") !important;
}

.ril__navButtonPrev {
  left: 3rem !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M13 16L7.35355 10.3536C7.15829 10.1583 7.15829 9.84171 7.35355 9.64645L13 4'%3E%3C/path%3E%3C/svg%3E") !important;
}

.ril__loadingContainer .spinner-border {
  color: var(--light-text) !important;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -15px;
  margin-top: -15px;
  z-index: 1;
}

.ril-outer {
  border-radius: initial !important;
}

.ril-outer.rounded-lg .ril-inner img.ril__image {
  border-radius: var(--border-radius-lg);
}

.ril-outer.rounded-md .ril-inner img.ril__image {
  border-radius: var(--border-radius-md);
}

/*
*
* Listjs
*
* Listjs plugin styles.
*
*/
html[data-color*=light] .custom-sort .sort:after {
  content: " ";
  display: inline-block;
  width: 10px;
  height: 10px;
  position: static;
  margin-left: 10px;
  opacity: 1;
  background-image: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 10'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bopacity:0.3;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M2.68.82h0a.27.27,0,0,0-.36,0h0L.82,2.32a.27.27,0,0,0,0,.36.27.27,0,0,0,.36,0L2.25,1.6V9a.25.25,0,0,0,.5,0V1.6L3.82,2.68a.27.27,0,0,0,.36,0,.27.27,0,0,0,0-.36Z'/%3E%3Cpath class='cls-1' d='M8.18,7.32a.27.27,0,0,0-.36,0L6.75,8.4V1a.25.25,0,0,0-.5,0V8.4L5.18,7.32a.25.25,0,0,0-.36.36l1.5,1.5a.27.27,0,0,0,.36,0l1.5-1.5A.27.27,0,0,0,8.18,7.32Z'/%3E%3C/svg%3E");
}
html[data-color*=light] .custom-sort .sort.desc:after {
  content: " ";
  display: inline-block;
  width: 10px;
  height: 10px;
  position: static;
  margin-left: 10px;
  top: 0;
  opacity: 1;
  background-image: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 10'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bopacity:0.3;%7D.cls-2%7Bopacity:0.9;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M2.68.82h0a.27.27,0,0,0-.36,0h0L.82,2.32a.27.27,0,0,0,0,.36.27.27,0,0,0,.36,0L2.25,1.6V9a.25.25,0,0,0,.5,0V1.6L3.82,2.68a.27.27,0,0,0,.36,0,.27.27,0,0,0,0-.36Z'/%3E%3Cpath class='cls-2' d='M8.18,7.32a.27.27,0,0,0-.36,0L6.75,8.4V1a.25.25,0,0,0-.5,0V8.4L5.18,7.32a.25.25,0,0,0-.36.36l1.5,1.5a.27.27,0,0,0,.36,0l1.5-1.5A.27.27,0,0,0,8.18,7.32Z'/%3E%3C/svg%3E");
}
html[data-color*=light] .custom-sort .sort.asc:after {
  content: " ";
  display: inline-block;
  width: 10px;
  height: 10px;
  position: static;
  margin-left: 10px;
  opacity: 1;
  background-image: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 10'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bopacity:0.9;%7D.cls-2%7Bopacity:0.3;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M2.68.82h0a.27.27,0,0,0-.36,0h0L.82,2.32a.27.27,0,0,0,0,.36.27.27,0,0,0,.36,0L2.25,1.6V9a.25.25,0,0,0,.5,0V1.6L3.82,2.68a.27.27,0,0,0,.36,0,.27.27,0,0,0,0-.36Z'/%3E%3Cpath class='cls-2' d='M8.18,7.32a.27.27,0,0,0-.36,0L6.75,8.4V1a.25.25,0,0,0-.5,0V8.4L5.18,7.32a.25.25,0,0,0-.36.36l1.5,1.5a.27.27,0,0,0,.36,0l1.5-1.5A.27.27,0,0,0,8.18,7.32Z'/%3E%3C/svg%3E");
}

html[data-color*=dark] .custom-sort .sort:after {
  content: " ";
  display: inline-block;
  width: 10px;
  height: 10px;
  position: static;
  margin-left: 10px;
  opacity: 1;
  background-image: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 10'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;opacity:0.3;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M2.68.82h0a.27.27,0,0,0-.36,0h0L.82,2.32a.27.27,0,0,0,0,.36.27.27,0,0,0,.36,0L2.25,1.6V9a.25.25,0,0,0,.5,0V1.6L3.82,2.68a.27.27,0,0,0,.36,0,.27.27,0,0,0,0-.36Z'/%3E%3Cpath class='cls-1' d='M8.18,7.32a.27.27,0,0,0-.36,0L6.75,8.4V1a.25.25,0,0,0-.5,0V8.4L5.18,7.32a.25.25,0,0,0-.36.36l1.5,1.5a.27.27,0,0,0,.36,0l1.5-1.5A.27.27,0,0,0,8.18,7.32Z'/%3E%3C/svg%3E");
}
html[data-color*=dark] .custom-sort .sort.desc:after {
  content: " ";
  display: inline-block;
  width: 10px;
  height: 10px;
  position: static;
  margin-left: 10px;
  opacity: 1;
  background-image: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 10'%3E%3Cdefs%3E%3Cstyle%3E.cls-1,.cls-2%7Bfill:%23fff;%7D.cls-1%7Bopacity:0.3;%7D.cls-2%7Bopacity:0.9;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M2.68.82h0a.27.27,0,0,0-.36,0h0L.82,2.32a.27.27,0,0,0,0,.36.27.27,0,0,0,.36,0L2.25,1.6V9a.25.25,0,0,0,.5,0V1.6L3.82,2.68a.27.27,0,0,0,.36,0,.27.27,0,0,0,0-.36Z'/%3E%3Cpath class='cls-2' d='M8.18,7.32a.27.27,0,0,0-.36,0L6.75,8.4V1a.25.25,0,0,0-.5,0V8.4L5.18,7.32a.25.25,0,0,0-.36.36l1.5,1.5a.27.27,0,0,0,.36,0l1.5-1.5A.27.27,0,0,0,8.18,7.32Z'/%3E%3C/svg%3E");
}
html[data-color*=dark] .custom-sort .sort.asc:after {
  content: " ";
  display: inline-block;
  width: 10px;
  height: 10px;
  position: static;
  margin-left: 10px;
  opacity: 1;
  background-image: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 10'%3E%3Cdefs%3E%3Cstyle%3E.cls-1,.cls-2%7Bfill:%23fff;%7D.cls-1%7Bopacity:0.9;%7D.cls-2%7Bopacity:0.3;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M2.68.82h0a.27.27,0,0,0-.36,0h0L.82,2.32a.27.27,0,0,0,0,.36.27.27,0,0,0,.36,0L2.25,1.6V9a.25.25,0,0,0,.5,0V1.6L3.82,2.68a.27.27,0,0,0,.36,0,.27.27,0,0,0,0-.36Z'/%3E%3Cpath class='cls-2' d='M8.18,7.32a.27.27,0,0,0-.36,0L6.75,8.4V1a.25.25,0,0,0-.5,0V8.4L5.18,7.32a.25.25,0,0,0-.36.36l1.5,1.5a.27.27,0,0,0,.36,0l1.5-1.5A.27.27,0,0,0,8.18,7.32Z'/%3E%3C/svg%3E");
}

/*
*
* Player
*
* Plyr plugin styles.
*
*/
.plyr,
.plyr__video-wrapper,
.plyr audio,
.plyr iframe,
.plyr video,
.plyr__poster {
  border-radius: var(--border-radius-lg) !important;
  background-color: initial !important;
}

.plyr__video-wrapper {
  height: 100%;
}

.plyr__poster {
  background-size: cover !important;
}

.theme-filter-player .plyr__poster {
  filter: var(--theme-image-filter) !important;
}

.cover .plyr video {
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.plyr--video.plyr--stopped .plyr__controls {
  display: none !important;
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true],
.plyr--audio .plyr__control.plyr__tab-focus,
.plyr--audio .plyr__control:hover,
.plyr--audio .plyr__control[aria-expanded=true] {
  background: var(--primary) !important;
  color: var(--light-text) !important;
}

.plyr__control {
  color: var(--body) !important;
}

.plyr__control--overlaid {
  padding: 9px 24px !important;
  border-radius: var(--border-radius-md) !important;
  background: var(--primary) !important;
  color: var(--light-text) !important;
}

.plyr--full-ui input[type=range] {
  color: var(--primary) !important;
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: var(--primary) !important;
}

.plyr--audio .plyr__controls {
  background: var(--foreground) !important;
}

.plyr__menu__container {
  background: var(--foreground) !important;
}

.plyr__menu__container .plyr__control--back::before {
  background: var(--separator) !important;
  box-shadow: initial !important;
}

.plyr--audio .plyr__controls {
  color: var(--body) !important;
}

.plyr--video .plyr__control {
  color: var(--light-text) !important;
}

.modal-player .modal-content {
  background: initial !important;
  border: initial !important;
}
.modal-player .plyr,
.modal-player .plyr audio,
.modal-player .plyr iframe,
.modal-player .plyr video,
.modal-player .plyr__video-wrapper {
  background: initial !important;
}

.plyr__menu__container .plyr__control {
  color: var(--body) !important;
}

.card-img-top .plyr,
.card-img-top .plyr__video-wrapper,
.card-img-top .plyr audio,
.card-img-top .plyr iframe,
.card-img-top .plyr video,
.card-img-top .plyr__poster {
  border-bottom-left-radius: initial !important;
  border-bottom-right-radius: initial !important;
}

.card-img-bottom .plyr,
.card-img-bottom .plyr__video-wrapper,
.card-img-bottom .plyr audio,
.card-img-bottom .plyr iframe,
.card-img-bottom .plyr video,
.card-img-bottom .plyr__poster {
  border-top-left-radius: initial !important;
  border-top-right-radius: initial !important;
}

.plyr__menu__container {
  border-radius: var(--border-radius-md) !important;
}

.plyr__control {
  border-radius: var(--border-radius-md) !important;
}

.card .plyr {
  height: 100% !important;
}

/*
*
* Glide
*
* Glide carousel plugin styles.
*
*/
.glide__track .glide__slide {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.gx-2 .glide__track .glide__slide {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.glide__bullets {
  display: inline-block;
  position: initial;
  text-align: center;
  transform: initial;
  left: initial;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.glide__bullets .glide__bullet {
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background: var(--separator);
  outline: initial !important;
  border: initial;
  margin: 0 3px;
  padding: 0;
  cursor: pointer;
  box-shadow: initial;
}
.glide__bullets .glide__bullet.glide__bullet:hover, .glide__bullets .glide__bullet.glide__bullet:focus {
  background: var(--separator);
  border: initial;
}
.glide__bullets .glide__bullet.glide__bullet--active {
  background: var(--primary) !important;
}

.glide-thumb .glide__slide,
.glide-large .glide__slide {
  cursor: pointer;
}

.glide-thumb {
  margin: 0 auto;
}
.glide-thumb li {
  text-align: center;
  opacity: 0.5;
  -o-object-fit: cover;
     object-fit: cover;
}
.glide-thumb li.active, .glide-thumb li:hover {
  opacity: 1;
}
.glide-thumb li img {
  width: 60px;
  margin-bottom: 0;
}
.glide-thumb .glide__arrows .btn {
  position: absolute;
  top: 5px;
}
.glide-thumb .glide__arrows .btn.left-arrow {
  left: -50px;
}
.glide-thumb .glide__arrows .btn.right-arrow {
  right: -50px;
}

/*
*
* Input Spinner
*
* Input spinner form control styles.
*
*/
.input-group.spinner {
  z-index: 0;
}

.input-group.spinner .input-group-text {
  flex-direction: column;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  padding: 0;
}

.input-group.spinner .input-group-text .spin-up,
.input-group.spinner .input-group-text .spin-down {
  display: flex;
  background: none;
  border: none;
  padding: 0;
  height: 40%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}
.input-group.spinner .input-group-text .spin-up.single,
.input-group.spinner .input-group-text .spin-down.single {
  height: 100%;
  color: var(--alternate);
}
.input-group.spinner .input-group-text .spin-up:hover .arrow,
.input-group.spinner .input-group-text .spin-down:hover .arrow {
  border-color: var(--primary);
}
.input-group.spinner .input-group-text .spin-up:hover,
.input-group.spinner .input-group-text .spin-down:hover {
  color: var(--primary);
}
.input-group.spinner .input-group-text .spin-up .arrow,
.input-group.spinner .input-group-text .spin-down .arrow {
  border: initial;
  border-top: 1px solid var(--alternate);
  border-right: 1px solid var(--alternate);
  width: 5px;
  height: 5px;
  margin: 0 auto;
}

.input-group.spinner .input-group-text .spin-up .arrow {
  transform: rotate(-45deg);
}

.input-group.spinner .input-group-text .spin-down .arrow {
  transform: rotate(135deg);
}

.filled .input-group-text {
  border: initial;
}

/*
*
* Notify
*
* Notify plugin styles.
*
*/
.Toastify__toast--default {
  padding: var(--card-spacing-xs) var(--card-spacing-sm) !important;
  background-color: var(--background) !important;
  border: 1px solid var(--primary) !important;
  border-radius: var(--border-radius-md) !important;
  color: var(--alternate) !important;
  font-family: var(--font) !important;
}

.Toastify__progress-bar--default {
  background: var(--primary) !important;
  bottom: 0.5rem !important;
  height: 1px !important;
  left: 1.25rem !important;
  width: calc(100% - 2.5rem) !important;
}

.Toastify__toast-body {
  padding: initial !important;
}

.Toastify__close-button--default {
  color: var(--muted) !important;
  opacity: 1 !important;
}
.Toastify__close-button--default:hover {
  color: var(--primary) !important;
}

.Toastify__toast--default.secondary {
  border: 1px solid var(--secondary) !important;
}
.Toastify__toast--default.secondary .Toastify__progress-bar--default {
  background: var(--secondary) !important;
}

.Toastify__toast--default.tertiary {
  border: 1px solid var(--tertiary) !important;
}
.Toastify__toast--default.tertiary .Toastify__progress-bar--default {
  background: var(--tertiary) !important;
}

.Toastify__toast--default.quaternary {
  border: 1px solid var(--quaternary) !important;
}
.Toastify__toast--default.quaternary .Toastify__progress-bar--default {
  background: var(--quaternary) !important;
}

.Toastify__toast--default.warning {
  border: 1px solid var(--warning) !important;
}
.Toastify__toast--default.warning .Toastify__progress-bar--default {
  background: var(--warning) !important;
}

.Toastify__toast--default.info {
  border: 1px solid var(--info) !important;
}
.Toastify__toast--default.info .Toastify__progress-bar--default {
  background: var(--info) !important;
}

.Toastify__toast--default.danger {
  border: 1px solid var(--danger) !important;
}
.Toastify__toast--default.danger .Toastify__progress-bar--default {
  background: var(--danger) !important;
}

.Toastify__toast--default.success {
  border: 1px solid var(--success) !important;
}
.Toastify__toast--default.success .Toastify__progress-bar--default {
  background: var(--success) !important;
}

.Toastify__close-button > svg {
  height: 14px !important;
  width: 14px !important;
}

/*
*
* Progressbar
*
* Progressbar plugin styles.
*
*/
.CircularProgressbar .CircularProgressbar-path {
  stroke: var(--primary) !important;
}
.CircularProgressbar .CircularProgressbar-text {
  fill: var(--primary) !important;
}
.CircularProgressbar.primary .CircularProgressbar-path {
  stroke: var(--primary) !important;
}
.CircularProgressbar.primary .CircularProgressbar-text {
  fill: var(--primary) !important;
}
.CircularProgressbar.secondary .CircularProgressbar-path {
  stroke: var(--secondary) !important;
}
.CircularProgressbar.secondary .CircularProgressbar-text {
  fill: var(--secondary) !important;
}
.CircularProgressbar.tertiary .CircularProgressbar-path {
  stroke: var(--tertiary) !important;
}
.CircularProgressbar.tertiary .CircularProgressbar-text {
  fill: var(--tertiary) !important;
}
.CircularProgressbar.quaternary .CircularProgressbar-path {
  stroke: var(--quaternary) !important;
}
.CircularProgressbar.quaternary .CircularProgressbar-text {
  fill: var(--quaternary) !important;
}
.CircularProgressbar.warning .CircularProgressbar-path {
  stroke: var(--warning) !important;
}
.CircularProgressbar.warning .CircularProgressbar-text {
  fill: var(--warning) !important;
}
.CircularProgressbar.danger .CircularProgressbar-path {
  stroke: var(--danger) !important;
}
.CircularProgressbar.danger .CircularProgressbar-text {
  fill: var(--danger) !important;
}
.CircularProgressbar.body .CircularProgressbar-path {
  stroke: var(--body) !important;
}
.CircularProgressbar.body .CircularProgressbar-text {
  fill: var(--body) !important;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: var(--separator-light) !important;
}

.CircularProgressbar .CircularProgressbar-text {
  font-size: 1em !important;
}

.CircularProgressbar.transparent-trailer .CircularProgressbar-trail {
  stroke: transparent !important;
}

/*
*
* Rating
*
* Rating form control styles.
*
*/
.top-label.rating-container {
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
  padding-top: 1.5rem !important;
}
.top-label.rating-container i {
  font-size: 16px;
  margin-top: 2px;
  display: inline-block;
}

.filled.rating-container {
  padding-top: 0;
  padding-bottom: 0;
}
.filled.rating-container i {
  margin-top: 3px;
  display: inline-block;
}

.form-floating.rating-container {
  border: 1px solid var(--separator);
  border-radius: var(--border-radius-md);
  height: auto;
  min-height: 52px;
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
  padding-top: 1.5rem !important;
}
.form-floating.rating-container label {
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  color: var(--muted);
}
.form-floating.rating-container i {
  font-size: 16px;
  margin-top: 2px;
  display: inline-block;
}

.btn-primary.hover-disabled {
  cursor: default;
}
.btn-primary.hover-disabled:hover {
  background-color: var(--primary) !important;
}

.btn-outline-primary.hover-disabled {
  cursor: default;
}
.btn-outline-primary.hover-disabled:hover {
  box-shadow: inset 0 0 0 1px var(--primary) !important;
  color: var(--primary) !important;
  background: initial !important;
}

/*
*
* Scrollbar
*
* Scrollbar plugin styles.
*
*/
.scroll-out .os-host {
  margin-right: -15px;
  padding-right: 15px;
  margin-left: -15px;
  padding-left: 15px;
  position: relative;
}

.scroll-out-negative .os-padding {
  margin-right: 15px;
}
.scroll-out-negative .os-host {
  margin-right: -15px;
}

.os-theme-dark > .os-scrollbar > .os-scrollbar-track,
.os-theme-dark > .os-scrollbar > .os-scrollbar-track,
.os-theme-light > .os-scrollbar > .os-scrollbar-track,
.os-theme-light > .os-scrollbar > .os-scrollbar-track {
  background: rgba(var(--muted-rgb), 0.15);
}

.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle,
.os-theme-light > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background: rgba(var(--muted-rgb), 0.4);
}

.os-theme-dark > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle,
.os-theme-light > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle {
  background: rgba(var(--muted-rgb), 0.5);
}

.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active,
.os-theme-light > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active {
  background: rgba(var(--muted-rgb), 0.5);
}

.os-theme-dark > .os-scrollbar-vertical,
.os-theme-light > .os-scrollbar-vertical {
  width: 8px;
}

.os-theme-dark > .os-scrollbar-horizontal,
.os-theme-light > .os-scrollbar-horizontal {
  height: 8px;
}

.scroll.fix-margin .os-scrollbar-vertical {
  height: calc(100% - 15px);
}

.scroll-track-visible.os-theme-dark > .os-scrollbar > .os-scrollbar-track,
.scroll-track-visible .os-theme-dark > .os-scrollbar > .os-scrollbar-track, .scroll-track-visible.os-theme-light > .os-scrollbar > .os-scrollbar-track,
.scroll-track-visible .os-theme-light > .os-scrollbar > .os-scrollbar-track {
  background: rgba(var(--separator-rgb), 0.5);
}
.scroll-track-visible.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle,
.scroll-track-visible .os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle, .scroll-track-visible.os-theme-light > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle,
.scroll-track-visible .os-theme-light > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background: rgba(var(--muted-rgb), 0.5);
}
.scroll-track-visible.os-theme-dark > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle,
.scroll-track-visible .os-theme-dark > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle, .scroll-track-visible.os-theme-light > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle,
.scroll-track-visible .os-theme-light > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle {
  background: rgba(var(--muted-rgb), 0.6);
}
.scroll-track-visible.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active,
.scroll-track-visible .os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active, .scroll-track-visible.os-theme-light > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active,
.scroll-track-visible .os-theme-light > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active {
  background: rgba(var(--muted-rgb), 0.6);
}

.select2-results ::-webkit-scrollbar, .select2-results::-webkit-scrollbar,
.tagify__dropdown ::-webkit-scrollbar,
.tagify__dropdown::-webkit-scrollbar,
.html-editor ::-webkit-scrollbar,
.html-editor::-webkit-scrollbar,
textarea ::-webkit-scrollbar,
textarea::-webkit-scrollbar,
#scrollSpyMenu ::-webkit-scrollbar,
#scrollSpyMenu::-webkit-scrollbar,
.override-native ::-webkit-scrollbar,
.override-native::-webkit-scrollbar,
.dataTables_scrollBody ::-webkit-scrollbar,
.dataTables_scrollBody::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
  border-radius: var(--border-radius-md);
  cursor: default !important;
}
.select2-results ::-webkit-scrollbar-thumb, .select2-results::-webkit-scrollbar-thumb,
.tagify__dropdown ::-webkit-scrollbar-thumb,
.tagify__dropdown::-webkit-scrollbar-thumb,
.html-editor ::-webkit-scrollbar-thumb,
.html-editor::-webkit-scrollbar-thumb,
textarea ::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb,
#scrollSpyMenu ::-webkit-scrollbar-thumb,
#scrollSpyMenu::-webkit-scrollbar-thumb,
.override-native ::-webkit-scrollbar-thumb,
.override-native::-webkit-scrollbar-thumb,
.dataTables_scrollBody ::-webkit-scrollbar-thumb,
.dataTables_scrollBody::-webkit-scrollbar-thumb {
  border-radius: var(--border-radius-md);
  background: rgba(var(--muted-rgb), 0.4);
  cursor: default !important;
}
.select2-results ::-webkit-scrollbar-button, .select2-results::-webkit-scrollbar-button,
.tagify__dropdown ::-webkit-scrollbar-button,
.tagify__dropdown::-webkit-scrollbar-button,
.html-editor ::-webkit-scrollbar-button,
.html-editor::-webkit-scrollbar-button,
textarea ::-webkit-scrollbar-button,
textarea::-webkit-scrollbar-button,
#scrollSpyMenu ::-webkit-scrollbar-button,
#scrollSpyMenu::-webkit-scrollbar-button,
.override-native ::-webkit-scrollbar-button,
.override-native::-webkit-scrollbar-button,
.dataTables_scrollBody ::-webkit-scrollbar-button,
.dataTables_scrollBody::-webkit-scrollbar-button {
  background: transparent;
  width: 4px;
  height: 3px;
  cursor: default !important;
}
.select2-results ::-webkit-scrollbar-track-piece, .select2-results::-webkit-scrollbar-track-piece,
.tagify__dropdown ::-webkit-scrollbar-track-piece,
.tagify__dropdown::-webkit-scrollbar-track-piece,
.html-editor ::-webkit-scrollbar-track-piece,
.html-editor::-webkit-scrollbar-track-piece,
textarea ::-webkit-scrollbar-track-piece,
textarea::-webkit-scrollbar-track-piece,
#scrollSpyMenu ::-webkit-scrollbar-track-piece,
#scrollSpyMenu::-webkit-scrollbar-track-piece,
.override-native ::-webkit-scrollbar-track-piece,
.override-native::-webkit-scrollbar-track-piece,
.dataTables_scrollBody ::-webkit-scrollbar-track-piece,
.dataTables_scrollBody::-webkit-scrollbar-track-piece {
  background: rgba(var(--muted-rgb), 0.15);
  border-radius: var(--border-radius-md);
  cursor: default !important;
}

.tagify__dropdown ::-webkit-scrollbar, .tagify__dropdown::-webkit-scrollbar {
  width: 14px;
}
.tagify__dropdown ::-webkit-scrollbar-track, .tagify__dropdown::-webkit-scrollbar-track {
  box-shadow: inset 0 0 14px 14px rgba(var(--muted-rgb), 0.15);
  border: solid 5px transparent;
  background: initial !important;
}
.tagify__dropdown ::-webkit-scrollbar-thumb, .tagify__dropdown::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 14px 14px rgba(var(--muted-rgb), 0.4);
  border: solid 5px transparent;
  border-radius: 14px;
  background: initial !important;
}
.tagify__dropdown ::-webkit-scrollbar-track-piece, .tagify__dropdown::-webkit-scrollbar-track-piece {
  background: initial !important;
}

body.custom-scrollbar ::-webkit-scrollbar, body.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: transparent;
  border-radius: var(--border-radius-md);
  cursor: default !important;
}
body.custom-scrollbar ::-webkit-scrollbar-thumb, body.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: var(--border-radius-md);
  background: rgba(var(--alternate-rgb), 0.5);
  cursor: default !important;
}
body.custom-scrollbar ::-webkit-scrollbar-button, body.custom-scrollbar::-webkit-scrollbar-button {
  background: transparent;
  width: 6px;
  height: 0;
  cursor: default !important;
}
body.custom-scrollbar ::-webkit-scrollbar-track-piece, body.custom-scrollbar::-webkit-scrollbar-track-piece {
  background: rgba(var(--muted-rgb), 0.15);
  border-radius: var(--border-radius-md);
  cursor: default !important;
}

/*
*
* Search
*
* Search autocomplete styles.
*
*/
#searchPagesResults {
  list-style: none;
  padding-left: 0;
}
#searchPagesResults .auto-complete-result-item {
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius-md) !important;
  border: 1px solid var(--separator) !important;
  margin-bottom: 5px;
}
#searchPagesResults .auto-complete-result-item.autoComplete_selected,
#searchPagesResults .auto-complete-result-item:hover {
  border: 1px solid var(--primary) !important;
  cursor: pointer;
}
#searchPagesResults .autoComplete_highlighted {
  color: var(--primary) !important;
}

/*
*
* React Select
*
* Select form control styles.
*
*/
.react-select__indicator-separator {
  display: none;
}

.react-select__input {
  color: var(--body);
}

.react-select__control {
  box-shadow: initial !important;
  background-color: var(--foreground) !important;
  border-color: var(--separator) !important;
  border-radius: var(--border-radius-md) !important;
  min-height: var(--input-height) !important;
}

.react-select__control--menu-is-open {
  border-color: var(--primary) !important;
}

.react-select__indicator {
  padding: 7px !important;
}

.react-select__value-container {
  padding: 0.15rem 0.75rem !important;
}

.react-select__indicator svg {
  width: 15px;
  height: 15px;
  color: var(--separator);
}

.react-select__menu {
  border-radius: var(--border-radius-md) !important;
  border: 1px solid var(--primary) !important;
  background: var(--foreground) !important;
  box-shadow: initial !important;
  z-index: 5 !important;
  margin-top: 3px !important;
}

.react-select__option {
  border-radius: var(--border-radius-sm) !important;
  padding: 0.5rem 0.75rem !important;
  line-height: 1.3 !important;
  background: var(--foreground) !important;
  color: var(--body) !important;
}

.react-select__option.react-select__option--is-focused {
  background: var(--separator-light) !important;
  color: var(--primary) !important;
}

.react-select__option--is-selected {
  color: var(--primary) !important;
}

.react-select__menu-list {
  padding: 0.25rem !important;
  margin: 0.5rem !important;
  max-height: 215px !important;
}
.react-select__menu-list ::-webkit-scrollbar, .react-select__menu-list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
  border-radius: var(--border-radius-md);
  cursor: default !important;
}
.react-select__menu-list ::-webkit-scrollbar-thumb, .react-select__menu-list::-webkit-scrollbar-thumb {
  border-radius: var(--border-radius-md);
  background: rgba(var(--muted-rgb), 0.4);
  cursor: default !important;
}
.react-select__menu-list ::-webkit-scrollbar-button, .react-select__menu-list::-webkit-scrollbar-button {
  background: transparent;
  width: 4px;
  height: 3px;
  cursor: default !important;
}
.react-select__menu-list ::-webkit-scrollbar-track-piece, .react-select__menu-list::-webkit-scrollbar-track-piece {
  background: rgba(var(--muted-rgb), 0.15);
  border-radius: var(--border-radius-md);
  cursor: default !important;
}

.react-select__multi-value {
  background: initial !important;
  border: 1px solid var(--separator);
  border-radius: var(--border-radius-sm) !important;
}
.react-select__multi-value:first-of-type {
  margin-left: -0.25rem;
}

.react-select__multi-value__label,
.react-select__single-value {
  color: var(--body) !important;
}

.react-select__multi-value__remove {
  background: initial !important;
  color: var(--muted) !important;
}
.react-select__multi-value__remove:hover {
  color: var(--primary) !important;
}

.option-circle {
  width: 15px;
  height: 15px;
}

.top-label .react-select__control {
  min-height: 52px !important;
  padding-top: 1rem !important;
}
.top-label .react-select__value-container {
  padding-left: 0.6rem !important;
}
.top-label .react-select__multi-value__label {
  padding: 0 !important;
}

.filled .react-select__control {
  min-height: 44px !important;
  border: 1px solid transparent !important;
  background: var(--background-light) !important;
}
.filled .react-select__value-container {
  padding-left: 43px !important;
}
.filled .react-select__placeholder {
  color: var(--alternate) !important;
}
.filled .react-select__control--menu-is-open {
  border: 1px solid var(--primary) !important;
  background: var(--foreground) !important;
}

.form-floating.react-select__control {
  min-height: 52px !important;
  padding-top: 1rem !important;
}
.form-floating.react-select__control > label {
  transform: initial;
  color: var(--alternate);
  transition: initial;
}
.form-floating.react-select__control.react-select__control--menu-is-open > label,
.form-floating.react-select__control .react-select__value-container.react-select__value-container--has-value ~ label {
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  color: var(--muted);
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

/*
*
* Slider
*
* Noui slider form control styles.
*
*/
.rc-slider-track {
  background-color: var(--primary) !important;
}

.rc-slider-rail {
  background-color: var(--separator-light) !important;
}

.rc-slider-handle {
  border-color: var(--primary) !important;
  background: var(--foreground) !important;
  border-width: 3px !important;
  box-shadow: initial !important;
  outline: initial !important;
  cursor: pointer !important;
}

.rc-slider-tooltip-inner {
  background: var(--primary) !important;
  color: var(--light-text) !important;
  padding: calc(var(--card-spacing-xs) / 2) var(--card-spacing-xs) !important;
  border-radius: var(--border-radius-md) !important;
  border: initial !important;
  font-size: 0.875em !important;
  box-shadow: initial !important;
  width: auto !important;
  height: auto !important;
  line-height: 1.5 !important;
}

.rc-slider-tooltip-placement-bottom .rc-slider-tooltip-content {
  margin-top: 5px;
}
.rc-slider-tooltip-placement-bottom .rc-slider-tooltip-arrow {
  top: -3px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 4px 4px;
  border-bottom-color: var(--primary) !important;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: var(--primary) !important;
}

.rc-slider-tooltip-placement-right .rc-slider-tooltip-content {
  margin-left: 5px;
}
.rc-slider-tooltip-placement-right .rc-slider-tooltip-arrow {
  top: 50%;
  margin-left: -4px;
  margin-top: -4px;
  border-width: 4px 4px 4px 0;
  border-right-color: var(--primary) !important;
}

.rc-slider-tooltip-placement-left .rc-slider-tooltip-content {
  margin-right: 5px;
}
.rc-slider-tooltip-placement-left .rc-slider-tooltip-arrow {
  left: 100%;
  top: 50%;
  margin-left: -5px;
  margin-top: -4px;
  border-width: 4px 0 4px 4px;
  border-left-color: var(--primary) !important;
}

.rc-slider-dot-active {
  border-color: var(--primary) !important;
}

.top-label.slider-container {
  padding-top: 1.75rem !important;
}

.filled.slider-container {
  padding-top: 1rem;
  padding-left: 50px;
}

.form-floating.slider-container {
  border: 1px solid var(--separator);
  border-radius: var(--border-radius-md);
  height: auto;
  min-height: 52px;
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
  padding-top: 1.75rem !important;
}
.form-floating.slider-container label {
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  color: var(--muted);
}

.fix-left-spacing.slider-container .rc-slider-handle {
  margin-left: 5px;
}
.fix-left-spacing.slider-container .rc-slider {
  width: calc(100% - 5px);
}

/*
*
* Steps
*
* Styles for steps ui elements.
*
*/
.line-w-1 {
  width: 1px;
}

.line-h-1 {
  height: 1px;
}

/*
*
* Tags
*
* Tags plugin styles.
*
*/
.react-tags {
  position: relative;
  border: 1px solid var(--separator);
  font-size: 1em;
  height: auto;
  min-height: var(--input-height);
  color: var(--body);
  box-shadow: initial !important;
  background-color: initial;
  border-color: var(--separator);
  border-radius: var(--border-radius-md);
  width: 100%;
  padding: 0.375rem 0 0 0.75rem;
  cursor: text;
}

.react-tags.is-focused {
  border-color: var(--primary);
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--separator) !important;
  background: initial;
  margin: 0 0.75rem 0.375rem 0;
  box-shadow: initial !important;
  line-height: 1.3;
  margin-left: -0.375rem;
}
.react-tags__selected-tag:hover:after {
  color: var(--primary);
}

.react-tags__selected-tag:after {
  content: "✕";
  color: var(--muted);
  margin-left: 0.5rem;
  font-size: 0.75rem;
}

.react-tags__search {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 0.375rem;
  margin-right: 0.75rem;
}

.react-tags__search-input {
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: initial;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: var(--foreground);
  border: 1px solid var(--primary);
  border-radius: var(--border-radius-md);
  padding: 0.75rem;
}

.react-tags__suggestions li {
  padding: 0.5rem 0.75rem !important;
  line-height: 1.3;
  border-radius: var(--border-radius-sm);
  color: var(--body);
}

.react-tags__suggestions li mark {
  text-decoration: initial;
  background: initial;
  font-weight: initial;
  color: var(--body);
  padding: initial;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: var(--separator-light) !important;
  color: var(--primary) !important;
}
.react-tags__suggestions li:hover mark {
  color: var(--primary) !important;
}

.react-tags__suggestions li.is-active {
  color: var(--primary);
  cursor: pointer;
  background: var(--separator-light) !important;
  color: var(--primary) !important;
}
.react-tags__suggestions li.is-active mark {
  color: var(--primary) !important;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.top-label .react-tags {
  min-height: 52px !important;
  padding-top: 1.5rem !important;
}
.top-label .react-tags__selected-tag {
  margin-left: initial;
}
.top-label .react-tags__selected-tag,
.top-label .react-tags__search {
  margin-bottom: 0.15rem;
  margin-right: 0.25rem;
}

.filled .react-tags {
  min-height: 44px;
  border: 1px solid transparent;
  background: var(--background-light);
  padding-left: 45px;
  padding-top: 10px;
}
.filled .react-tags.is-focused {
  border-color: var(--primary);
  background: var(--foreground);
}

.form-floating .react-tags {
  min-height: 52px !important;
  padding-top: 1.5rem !important;
}
.form-floating .react-tags label {
  transform: initial;
  color: var(--alternate);
  transition: initial;
}
.form-floating .react-tags.is-focused ~ label, .form-floating.tag-added .react-tags ~ label {
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  color: var(--muted);
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

/*
*
* Tour
*
* Introjs plugin styles.
*
*/
.introjs-helperLayer {
  padding: 1rem !important;
  transform: translate(-1rem, -1rem) !important;
  border-radius: var(--border-radius-lg) !important;
  box-shadow: var(--primary) 0px 0px 0 0, rgba(0, 0, 0, 0.6) 0px 0px 0px 5000px !important;
}

.introjs-tooltip {
  border-radius: var(--border-radius-lg) !important;
  padding: var(--card-spacing-xs) !important;
  background: var(--foreground) !important;
}

.introjs-overlay {
  background: #000 !important;
}

.introjs-button {
  box-sizing: border-box !important;
  font-family: var(--font) !important;
  padding: 9px 20px !important;
  height: var(--input-height) !important;
  font-size: 1em !important;
  line-height: 1rem !important;
  border-radius: var(--border-radius-md) !important;
  border: initial !important;
  box-shadow: initial !important;
  transition: all var(--transition-time-short) !important;
  transition-property: color, background-color, background-image, background !important;
  border-radius: var(--border-radius-md) !important;
  color: var(--light-text) !important;
  background-color: initial !important;
  background-image: initial !important;
  text-shadow: initial !important;
  box-shadow: inset 0px 0px 0px 1px var(--primary) !important;
  color: var(--primary) !important;
  margin: 3px !important;
  margin-bottom: 0 !important;
}
.introjs-button i {
  font-size: 14px !important;
  width: 14px !important;
  vertical-align: middle !important;
  display: inline-block !important;
}
.introjs-button span {
  vertical-align: middle !important;
  display: inline-block !important;
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.introjs-button:hover {
  color: var(--light-text) !important;
  background-color: var(--primary) !important;
  box-shadow: initial !important;
}
.introjs-button.introjs-disabled {
  opacity: 0.5 !important;
  cursor: initial !important;
  pointer-events: none !important;
}

.introjs-tooltipReferenceLayer i[class^=cs] {
  font-family: "CS-Interface" !important;
}

.introjs-bullets {
  margin-bottom: 15px !important;
}

.introjs-tooltiptext {
  font-family: var(--font-heading) !important;
  margin-bottom: 15px !important;
}

.introjs-tooltipbuttons {
  border-top: 1px solid var(--separator) !important;
  text-align: center !important;
}

.introjs-bullets ul li a {
  background: var(--separator) !important;
}

.introjs-bullets ul li a.active {
  background: var(--primary) !important;
}

.introjs-tooltipReferenceLayer *,
.introjs-tooltipReferenceLayer {
  font-family: var(--font) !important;
}

.introjs-tooltip-title {
  font-family: var(--font-heading) !important;
  font-weight: 400 !important;
  font-size: 1.1rem !important;
}

.introjs-tooltip-header {
  padding-right: 10px !important;
}

.introjs-arrow.top {
  left: 15px !important;
}

.introjs-arrow.left {
  top: 15px !important;
  border-right-color: var(--foreground) !important;
}

.introjs-arrow.right-bottom {
  bottom: 15px !important;
  border-left-color: var(--foreground) !important;
}

.introjs-arrow.bottom {
  left: 15px !important;
  border-top-color: var(--foreground) !important;
}

.introjs-arrow.top,
.introjs-arrow.top-middle,
.introjs-arrow.top-right {
  border-bottom-color: var(--foreground) !important;
}

.introjs-skipbutton {
  color: var(--alternate) !important;
}
.introjs-skipbutton:hover {
  color: var(--primary) !important;
}

.introjs-bullets ul li a.active, .introjs-bullets ul li a:focus, .introjs-bullets ul li a:hover {
  width: 6px !important;
}

/*
*
* Validation
*
* Validation plugin styles.
*
*/
.invalid-tooltip,
.valid-tooltip,
div.error {
  border-radius: var(--border-radius-md);
  font-size: 0.875em;
  color: var(--light-text);
  background: var(--primary);
  border: initial;
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 4;
  margin-top: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  line-height: 1.5;
  padding: calc(var(--card-spacing-xs) / 3 * 2) var(--card-spacing-xs);
}
.invalid-tooltip::before,
.valid-tooltip::before,
div.error::before {
  content: "";
  position: absolute;
  top: 50%;
  top: -4px;
  left: -2.5px;
  margin-left: 50%;
  width: 10px;
  height: 5px;
  border-bottom: solid 5px var(--primary);
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
}

.tooltip-end-bottom .invalid-tooltip,
.tooltip-end-bottom .valid-tooltip,
.tooltip-end-bottom div.error {
  left: initial;
  right: 0;
  transform: translateX(0);
  top: calc(100% + 0.5rem);
}
.tooltip-end-bottom .invalid-tooltip::before,
.tooltip-end-bottom .valid-tooltip::before,
.tooltip-end-bottom div.error::before {
  left: initial;
  margin-top: initial;
  right: 25px;
  margin-left: 0;
  top: -4px;
}

.tooltip-center-bottom .invalid-tooltip,
.tooltip-center-bottom .valid-tooltip,
.tooltip-center-bottom div.error {
  left: 50%;
  right: initial;
  transform: translateX(-50%) translateY(0);
  top: calc(100% + 0.5rem);
}
.tooltip-center-bottom .invalid-tooltip::before,
.tooltip-center-bottom .valid-tooltip::before,
.tooltip-center-bottom div.error::before {
  margin-top: initial;
  top: -4px;
}

.tooltip-start-bottom .invalid-tooltip,
.tooltip-start-bottom .valid-tooltip,
.tooltip-start-bottom div.error {
  left: 0;
  right: initial;
  transform: translateX(0);
  top: calc(100% + 0.5rem);
}
.tooltip-start-bottom .invalid-tooltip::before,
.tooltip-start-bottom .valid-tooltip::before,
.tooltip-start-bottom div.error::before {
  margin-top: initial;
  left: 25px;
  right: initial;
  margin-left: 0;
  top: -4px;
}

.tooltip-center-top .invalid-tooltip,
.tooltip-center-top .valid-tooltip,
.tooltip-center-top div.error {
  bottom: initial;
  transform: translateX(-50%) translateY(50%);
  top: -0.75rem;
}
.tooltip-center-top .invalid-tooltip::before,
.tooltip-center-top .valid-tooltip::before,
.tooltip-center-top div.error::before {
  margin-top: initial;
  content: "";
  position: absolute;
  top: initial;
  bottom: -4px;
  border-top: solid 5px var(--primary);
  border-bottom: initial;
}

.tooltip-end-top .invalid-tooltip,
.tooltip-end-top .valid-tooltip,
.tooltip-end-top div.error {
  bottom: initial;
  top: -0.75rem;
  transform: translateX(0) translateY(50%);
  left: initial;
  right: 0;
}
.tooltip-end-top .invalid-tooltip::before,
.tooltip-end-top .valid-tooltip::before,
.tooltip-end-top div.error::before {
  content: "";
  margin-top: initial;
  position: absolute;
  top: initial;
  bottom: -4px;
  border-top: solid 5px var(--primary);
  border-bottom: initial;
  left: initial;
  right: 25px;
  margin-left: 0;
}

.tooltip-start-top .invalid-tooltip,
.tooltip-start-top .valid-tooltip,
.tooltip-start-top div.error {
  bottom: initial;
  transform: translateX(0) translateY(50%);
  top: -0.75rem;
  left: 0;
  right: initial;
}
.tooltip-start-top .invalid-tooltip::before,
.tooltip-start-top .valid-tooltip::before,
.tooltip-start-top div.error::before {
  content: "";
  margin-top: initial;
  position: absolute;
  top: initial;
  bottom: -4px;
  border-top: solid 5px var(--primary);
  border-bottom: initial;
  left: 25px;
  right: initial;
  margin-left: 0;
}

.tooltip-label-end .invalid-tooltip,
.tooltip-label-end .valid-tooltip,
.tooltip-label-end div.error {
  transform: translateX(0) translateY(-50%);
  top: 18px;
  bottom: initial;
  right: initial;
}
.tooltip-label-end .invalid-tooltip::before,
.tooltip-label-end .valid-tooltip::before,
.tooltip-label-end div.error::before {
  content: "";
  position: absolute;
  left: -5px;
  right: initial;
  margin-left: 0;
  border: initial;
  border-top: solid 5px transparent;
  border-bottom: solid 5px transparent;
  border-right: solid 5px var(--primary);
  bottom: initial;
  top: 50%;
  margin-top: -5px;
  width: 5px;
}

.is-invalid .invalid-tooltip {
  display: block;
}

.valid-icon {
  position: absolute;
  bottom: 8px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.76rem;
  line-height: 1;
  color: var(--foreground);
  border-radius: 0.2rem;
  right: 4px;
  color: var(--success);
}

.invalid-icon {
  position: absolute;
  bottom: 2px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.875em;
  line-height: 1;
  color: var(--foreground);
  border-radius: 0.2rem;
  right: 4px;
  color: var(danger);
}

.tooltip-end-top .filled .invalid-tooltip,
.tooltip-end-top .filled .valid-tooltip,
.tooltip-end-top .filled div.error,
.tooltip-center-top .filled .invalid-tooltip,
.tooltip-center-top .filled .valid-tooltip,
.tooltip-center-top .filled div.error,
.tooltip-start-top .filled .invalid-tooltip,
.tooltip-start-top .filled .valid-tooltip,
.tooltip-start-top .filled div.error {
  top: -35px;
  bottom: initial;
}
.tooltip-end-top.filled .invalid-tooltip,
.tooltip-end-top.filled .valid-tooltip,
.tooltip-end-top.filled div.error,
.tooltip-center-top.filled .invalid-tooltip,
.tooltip-center-top.filled .valid-tooltip,
.tooltip-center-top.filled div.error,
.tooltip-start-top.filled .invalid-tooltip,
.tooltip-start-top.filled .valid-tooltip,
.tooltip-start-top.filled div.error {
  top: -35px;
  bottom: initial;
}

.tooltip-end-top .top-label .invalid-tooltip,
.tooltip-end-top .top-label .valid-tooltip,
.tooltip-end-top .top-label div.error,
.tooltip-end-top .form-floating .invalid-tooltip,
.tooltip-end-top .form-floating .valid-tooltip,
.tooltip-end-top .form-floating div.error,
.tooltip-center-top .top-label .invalid-tooltip,
.tooltip-center-top .top-label .valid-tooltip,
.tooltip-center-top .top-label div.error,
.tooltip-center-top .form-floating .invalid-tooltip,
.tooltip-center-top .form-floating .valid-tooltip,
.tooltip-center-top .form-floating div.error,
.tooltip-start-top .top-label .invalid-tooltip,
.tooltip-start-top .top-label .valid-tooltip,
.tooltip-start-top .top-label div.error,
.tooltip-start-top .form-floating .invalid-tooltip,
.tooltip-start-top .form-floating .valid-tooltip,
.tooltip-start-top .form-floating div.error {
  top: -35px;
  bottom: initial;
}
.tooltip-end-top.top-label .invalid-tooltip,
.tooltip-end-top.top-label .valid-tooltip,
.tooltip-end-top.top-label div.error, .tooltip-end-top.form-floating .invalid-tooltip,
.tooltip-end-top.form-floating .valid-tooltip,
.tooltip-end-top.form-floating div.error,
.tooltip-center-top.top-label .invalid-tooltip,
.tooltip-center-top.top-label .valid-tooltip,
.tooltip-center-top.top-label div.error,
.tooltip-center-top.form-floating .invalid-tooltip,
.tooltip-center-top.form-floating .valid-tooltip,
.tooltip-center-top.form-floating div.error,
.tooltip-start-top.top-label .invalid-tooltip,
.tooltip-start-top.top-label .valid-tooltip,
.tooltip-start-top.top-label div.error,
.tooltip-start-top.form-floating .invalid-tooltip,
.tooltip-start-top.form-floating .valid-tooltip,
.tooltip-start-top.form-floating div.error {
  top: -35px;
  bottom: initial;
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: var(--body);
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: var(--primary);
}

.valid-tooltip {
  display: none !important;
}

.form-check.tooltip-end-top .invalid-tooltip,
.form-check.tooltip-end-top .valid-tooltip,
.form-check.tooltip-end-top div.error,
.form-check.tooltip-center-top .invalid-tooltip,
.form-check.tooltip-center-top .valid-tooltip,
.form-check.tooltip-center-top div.error,
.form-check.tooltip-start-top .invalid-tooltip,
.form-check.tooltip-start-top .valid-tooltip,
.form-check.tooltip-start-top div.error {
  top: -2.5rem;
}

/*
*
* Wizard
*
* Styles for wizard ui.
*
*/
.wizard-buttons {
  display: flex;
}
.wizard-buttons .disabled {
  opacity: 0.5;
}

.wizard {
  margin-top: -2rem;
}

.wizard ul.nav {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: initial;
  padding: initial;
  background: initial;
  position: relative;
  border: initial;
  margin-bottom: 1.5rem;
}
.wizard ul.nav:before {
  content: " ";
  position: absolute;
  bottom: 1px;
  width: 100%;
  height: 1px;
  z-index: 0;
  background: var(--separator-light);
}

.wizard-default ul.nav li {
  position: relative;
  padding: 0.5rem 1rem;
  text-align: center;
}
.wizard-default ul.nav li .nav-link,
.wizard-default ul.nav li .nav-link:focus {
  border: initial !important;
  color: var(--separator);
}
.wizard-default ul.nav li .nav-link span,
.wizard-default ul.nav li .nav-link small,
.wizard-default ul.nav li .nav-link:focus span,
.wizard-default ul.nav li .nav-link:focus small {
  color: var(--separator);
  text-align: center;
}
.wizard-default ul.nav li .nav-link:active,
.wizard-default ul.nav li .nav-link:hover {
  color: var(--primary);
}
.wizard-default ul.nav li .nav-link:active span,
.wizard-default ul.nav li .nav-link:active small,
.wizard-default ul.nav li .nav-link:hover span,
.wizard-default ul.nav li .nav-link:hover small {
  color: var(--primary);
}
.wizard-default ul.nav li span {
  display: block;
  font-weight: 700;
  color: var(--separator);
}
.wizard-default ul.nav li.step-doing .nav-link {
  color: var(--primary);
}
.wizard-default ul.nav li.step-doing .nav-link span,
.wizard-default ul.nav li.step-doing .nav-link small {
  color: var(--primary);
}
.wizard-default ul.nav li .nav-link:before {
  content: " ";
  position: absolute;
  margin-top: 10px;
  display: block;
  border-radius: 50%;
  color: initial;
  background: var(--separator-light);
  border: none;
  width: 18px;
  height: 18px;
  text-decoration: none;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  bottom: -6px;
}
.wizard-default ul.nav li.step-doing .nav-link:before, .wizard-default ul.nav li.step-done .nav-link:before {
  background: var(--primary);
}
.wizard-default ul.nav li.step-done .nav-link:after {
  content: " ";
  position: absolute;
  background: initial;
  z-index: 1;
  width: 8px;
  height: 8px;
  left: 50%;
  bottom: -1px;
  transform: translateX(-50%);
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.wizard-default ul.nav.disabled .nav-link,
.wizard-default ul.nav.disabled .nav-link:active,
.wizard-default ul.nav.disabled .nav-link:hover {
  cursor: default;
  color: var(--separator);
}
.wizard-default ul.nav.disabled .nav-link span,
.wizard-default ul.nav.disabled .nav-link small,
.wizard-default ul.nav.disabled .nav-link:active span,
.wizard-default ul.nav.disabled .nav-link:active small,
.wizard-default ul.nav.disabled .nav-link:hover span,
.wizard-default ul.nav.disabled .nav-link:hover small {
  color: var(--separator);
}
.wizard-default ul.nav.disabled .step-doing .nav-link {
  color: var(--primary) !important;
}
.wizard-default ul.nav.disabled .step-doing .nav-link span,
.wizard-default ul.nav.disabled .step-doing .nav-link small {
  color: var(--primary) !important;
}

@media (max-width: 767.98px) {
  .wizard-default ul.nav li small,
  .wizard-default ul.nav li span {
    display: none;
  }
}
/*
*
* Base
*
* Template styles for base core items such as root, html and body.
*
*/
:root {
  scroll-behavior: initial;
}

html {
  height: 100%;
  width: 100%;
  margin: 0;
  font-size: 16px;
}

body {
  width: 100%;
  margin: 0;
  font-family: var(--font);
  font-weight: 400;
  color: var(--body);
  background-color: var(--background);
  font-size: 87.5%;
}

#root {
  opacity: 0;
}

html:not([data-show=true]) body.spinner #root {
  opacity: 0;
}
html:not([data-show=true]) body.spinner:after {
  border-color: #7d7d7d;
  border-right-color: transparent;
}

html[data-show=true] {
  scroll-behavior: smooth;
}
html[data-show=true] #root {
  opacity: 1;
}

/*
*
* Typography
*
* Template styles for typography.
*
*/
p {
  font-family: var(--font);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--font-heading);
  font-weight: 400;
  line-height: 1.25;
}

.font-standard {
  font-family: var(--font);
}

.font-heading {
  font-family: var(--font-heading);
}

.text-medium {
  font-size: 0.9em !important;
}

.text-semi-large {
  font-size: 1.1em !important;
}

.text-xlarge {
  font-size: 2.7em !important;
  font-weight: 300 !important;
}

.text-small {
  font-size: 0.7em !important;
  font-weight: initial !important;
}

.text-extra-small {
  font-size: 0.65em !important;
  line-height: 1em !important;
}

.text-large {
  font-size: 1.75em !important;
  font-weight: 300 !important;
}

.font-weight-bold {
  font-weight: 600;
}

[class*=cs-] {
  font-size: 18px;
}

.heading {
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 0.7em;
}

.small-title {
  font-family: var(--font-heading);
  font-weight: 400;
  margin-bottom: 0;
  font-size: 1em;
  color: var(--primary);
  height: var(--small-title-height);
}

.blockquote {
  font-size: 1em;
}

.display-1 {
  font-family: var(--font-heading);
  font-size: 3.5em;
  line-height: 1.2;
  font-weight: 300;
}
@media (max-width: 767.98px) {
  .display-1 {
    font-size: 3em;
  }
}

.display-2 {
  font-family: var(--font-heading);
  font-size: 3em;
  line-height: 1.2;
  font-weight: 300;
}
@media (max-width: 767.98px) {
  .display-2 {
    font-size: 2.75em;
  }
}

.display-3 {
  font-family: var(--font-heading);
  font-size: 2.15em;
  line-height: 1.2;
  font-weight: 300;
}
@media (max-width: 767.98px) {
  .display-3 {
    font-size: 2em;
  }
}

.display-4 {
  font-family: var(--font-heading);
  font-size: 1.85em;
  line-height: 1.2;
  font-weight: 300;
}
@media (max-width: 991.98px) {
  .display-4 {
    font-size: 1.7em;
  }
}
@media (max-width: 767.98px) {
  .display-4 {
    font-size: 1.6em;
  }
}

.display-5 {
  font-family: var(--font-heading);
  font-size: 1.75em;
  line-height: 1.2;
  font-weight: 300;
}
@media (max-width: 991.98px) {
  .display-5 {
    font-size: 1.6em;
  }
}
@media (max-width: 767.98px) {
  .display-5 {
    font-size: 1.5em;
  }
}

.display-6 {
  font-family: var(--font-heading);
  font-size: 1.6em;
  line-height: 1.2;
  font-weight: 300;
}
@media (max-width: 991.98px) {
  .display-6 {
    font-size: 1.5em;
  }
}
@media (max-width: 767.98px) {
  .display-6 {
    font-size: 1.5em;
  }
}

.cta-1 {
  font-family: var(--font-heading);
  font-size: 1.5em;
  line-height: 1.4;
  font-weight: 400;
}

.cta-2 {
  font-family: var(--font-heading);
  font-size: 1.35em;
  line-height: 1.4;
  font-weight: 400;
}

.cta-3 {
  font-family: var(--font-heading);
  font-size: 1.25em;
  line-height: 1.4;
  font-weight: 400;
}

.cta-4 {
  font-family: var(--font-heading);
  font-size: 1.15em;
  line-height: 1.25;
  font-weight: 400;
}

.lead {
  font-weight: 300;
}

h1,
.h1 {
  font-size: 1.8em;
}
@media (max-width: 767.98px) {
  h1,
  .h1 {
    font-size: 1.5em;
  }
}

h2,
.h2 {
  font-size: 1.65em;
}
@media (max-width: 767.98px) {
  h2,
  .h2 {
    font-size: 1.3em;
  }
}

h3,
.h3 {
  font-size: 1.5em;
}
@media (max-width: 767.98px) {
  h3,
  .h3 {
    font-size: 1.25em;
  }
}

h4,
.h4 {
  font-size: 1.35em;
}
@media (max-width: 767.98px) {
  h4,
  .h4 {
    font-size: 1.15em;
  }
}

h5,
.h5 {
  font-size: 1.1em;
}
@media (max-width: 767.98px) {
  h5,
  .h5 {
    font-size: 1em;
  }
}

h6,
.h6 {
  font-size: 1em;
}
@media (max-width: 767.98px) {
  h6,
  .h6 {
    font-size: 1em;
  }
}

.icon-24 {
  font-size: 24px !important;
}

.icon-22 {
  font-size: 22px !important;
}

.icon-20 {
  font-size: 20px !important;
}

.icon-18 {
  font-size: 18px !important;
}

.icon-16 {
  font-size: 16px !important;
}

.icon-14 {
  font-size: 14px !important;
}

pre {
  color: var(--body);
}

code {
  color: var(--body);
}

mark,
.mark {
  background-color: rgba(var(--secondary-rgb), 0.1);
}

.ll-nam {
  color: var(--tertiary);
}

.ll-num {
  color: var(--info);
}

.ll-str {
  color: var(--secondary);
}

.ll-rex {
  color: var(--warning);
}

.ll-pct {
  color: var(--body);
}

.ll-key {
  color: var(--body);
  font-weight: bold;
}

.ll-com {
  color: var(--text-muted);
  font-style: italic;
}

kbd {
  background: var(--primary);
  color: var(--light-text);
  border-radius: calc(var(--border-radius-md) / 2);
}

.tooltip,
.popover {
  font-family: var(--font);
}

.lh-1 {
  line-height: 1 !important;
}

.lh-1-25 {
  line-height: 1.25 !important;
}

.lh-1-5 {
  line-height: 1.5 !important;
}

.disable-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.line-through {
  text-decoration: line-through;
}

.blockquote-footer {
  margin-top: initial;
}

/*
*
* Main
*
* Template styles for main content area.
*
*/
main {
  min-height: 100%;
  padding-left: calc(var(--nav-size) + var(--main-spacing-horizontal));
  padding-right: var(--main-spacing-horizontal);
  padding-top: var(--main-spacing-vertical);
  padding-bottom: var(--main-spacing-vertical);
}

main > .container-fluid,
main > .container-lg,
main > .container-md,
main > .container-sm,
main > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

html[data-placement=horizontal] main {
  padding-left: var(--main-spacing-horizontal);
  padding-top: calc(var(--nav-size-slim) + var(--main-spacing-horizontal) / 2);
}

html[data-placement=vertical][data-dimension=mobile] main {
  padding-left: calc(var(--main-spacing-horizontal) + var(--nav-size-slim));
}

html[data-placement=vertical][data-behaviour=unpinned] main {
  padding-left: calc(var(--main-spacing-horizontal) + var(--nav-size-slim));
}

html[data-layout=fluid] main .container {
  width: 100%;
  max-width: initial;
  padding-right: 0;
  padding-left: 0;
}

@media (max-width: 1199.98px) {
  html[data-layout=boxed] main .container {
    width: 100%;
    max-width: initial;
    padding-right: 0;
    padding-left: 0;
  }
}

html[data-placement=horizontal][data-dimension=mobile] main,
html[data-placement=vertical][data-dimension=mobile] main {
  padding-left: var(--main-spacing-horizontal) !important;
  padding-top: calc(var(--nav-size-slim) + var(--main-spacing-horizontal) / 2);
}

html[data-fullpage=true] body,
html[data-fullpage=true] #root,
html[data-fullpage=true] main {
  height: 100%;
}
html[data-fullpage=true] main .container {
  height: 100%;
}

html[data-footer=true][data-fullpage=true] body,
html[data-footer=true][data-fullpage=true] #root,
html[data-footer=true][data-fullpage=true] main {
  height: 100%;
}
html[data-footer=true][data-fullpage=true] main .container {
  height: 100%;
}

html[data-placement=horizontal] section.scroll-section {
  scroll-margin-top: calc(var(--nav-size-slim) + var(--main-spacing-horizontal) / 2);
}

html[data-placement=vertical] section.scroll-section {
  scroll-margin-top: var(--main-spacing-vertical);
}

/*
*
* Nav Primary
*
* Template styles for both vertical and horizontal navigation as well as mobile menu.
*
*/
html[data-placement=horizontal] .nav-container,
html[data-placement=vertical] .nav-container {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1001;
  background-repeat: no-repeat;
  background-size: cover;
  width: var(--nav-size);
  background-position: center;
  background-image: linear-gradient(160deg, var(--gradient-1), var(--gradient-1), var(--gradient-2), var(--gradient-3));
}
html[data-placement=horizontal] .nav-container .nav-shadow,
html[data-placement=vertical] .nav-container .nav-shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  box-shadow: var(--menu-shadow);
  pointer-events: none;
  z-index: 1001;
}
html[data-placement=horizontal] .nav-container .mobile-buttons-container,
html[data-placement=vertical] .nav-container .mobile-buttons-container {
  display: none;
}
html[data-placement=horizontal] .nav-container .menu-container .menu,
html[data-placement=vertical] .nav-container .menu-container .menu {
  display: none;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  /* All li items - all of the items */
  /* Only top level li items - main menu items */
  /* Only sub level li items - sub menu items */
}
html[data-placement=horizontal] .nav-container .menu-container .menu ul,
html[data-placement=vertical] .nav-container .menu-container .menu ul {
  list-style: none;
}
html[data-placement=horizontal] .nav-container .menu-container .menu li a,
html[data-placement=vertical] .nav-container .menu-container .menu li a {
  text-decoration: initial;
  position: relative;
  width: 100%;
  transition: opacity var(--transition-time);
  display: inline-block;
}
html[data-placement=horizontal] .nav-container .menu-container .menu li a:after,
html[data-placement=vertical] .nav-container .menu-container .menu li a:after {
  content: none;
}
html[data-placement=horizontal] .nav-container .menu-container .menu li a .icon,
html[data-placement=horizontal] .nav-container .menu-container .menu li a .label,
html[data-placement=vertical] .nav-container .menu-container .menu li a .icon,
html[data-placement=vertical] .nav-container .menu-container .menu li a .label {
  vertical-align: middle;
}
html[data-placement=horizontal] .nav-container .menu-container .menu > li,
html[data-placement=vertical] .nav-container .menu-container .menu > li {
  margin: 0;
  position: relative;
}
html[data-placement=horizontal] .nav-container .menu-container .menu > li a,
html[data-placement=vertical] .nav-container .menu-container .menu > li a {
  color: var(--light-text);
}
html[data-placement=horizontal] .nav-container .menu-container .menu > li .icon,
html[data-placement=vertical] .nav-container .menu-container .menu > li .icon {
  margin-right: 8px;
  display: inline-block;
  margin-top: -1px;
  margin-left: -1px;
}
html[data-placement=horizontal] .nav-container .menu-container .menu > li li,
html[data-placement=vertical] .nav-container .menu-container .menu > li li {
  padding: 0;
}
html[data-placement=horizontal] .nav-container .menu-container .menu > li li a,
html[data-placement=vertical] .nav-container .menu-container .menu > li li a {
  padding: 0.5rem 1rem;
}
html[data-placement=horizontal] .nav-container .menu-container .menu > li li a .label,
html[data-placement=vertical] .nav-container .menu-container .menu > li li a .label {
  margin-right: 1rem;
}
html[data-placement=horizontal] .nav-container .menu-container .menu.show,
html[data-placement=vertical] .nav-container .menu-container .menu.show {
  display: flex;
}
html[data-placement=horizontal] .nav-container .menu-container.os-host,
html[data-placement=vertical] .nav-container .menu-container.os-host {
  padding-left: 0;
  padding-right: 0;
}
html[data-placement=horizontal] .nav-container .menu-icons,
html[data-placement=vertical] .nav-container .menu-icons {
  display: flex !important;
  justify-content: center;
  cursor: pointer;
}
html[data-placement=horizontal] .nav-container .menu-icons .list-inline-item,
html[data-placement=vertical] .nav-container .menu-icons .list-inline-item {
  margin-left: 0;
  margin-right: 0;
}
html[data-placement=horizontal] .nav-container .menu-icons i,
html[data-placement=vertical] .nav-container .menu-icons i {
  font-size: 18px !important;
}
html[data-placement=horizontal] .nav-container .menu-icons > li,
html[data-placement=vertical] .nav-container .menu-icons > li {
  height: 38px;
}
html[data-placement=horizontal] .nav-container .menu-icons > li > a,
html[data-placement=vertical] .nav-container .menu-icons > li > a {
  color: var(--light-text);
  font-size: 18px;
  padding: 0.25rem 0.5rem;
  transition: opacity var(--transition-time);
}
html[data-placement=horizontal] .nav-container .menu-icons > li > a:hover,
html[data-placement=vertical] .nav-container .menu-icons > li > a:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03) !important;
  background: rgba(255, 255, 255, 0.05);
}
html[data-placement=horizontal] .nav-container .menu-icons .notification-dot,
html[data-placement=vertical] .nav-container .menu-icons .notification-dot {
  width: 3px;
  height: 3px;
  background: var(--light-text);
  top: -2px;
  right: 0;
}
html[data-placement=horizontal] .nav-container .user-container,
html[data-placement=vertical] .nav-container .user-container {
  flex-direction: column;
}
html[data-placement=horizontal] .nav-container .user-container .user,
html[data-placement=vertical] .nav-container .user-container .user {
  text-align: center;
  transition: opacity var(--transition-time);
  border-radius: var(--border-radius-md);
}
html[data-placement=horizontal] .nav-container .user-container .user::after,
html[data-placement=vertical] .nav-container .user-container .user::after {
  content: initial;
}
html[data-placement=horizontal] .nav-container .user-container .user:hover,
html[data-placement=vertical] .nav-container .user-container .user:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03) !important;
  background: rgba(255, 255, 255, 0.05);
}
html[data-placement=horizontal] .nav-container .user-container .user .profile,
html[data-placement=vertical] .nav-container .user-container .user .profile {
  margin: 0 auto;
  margin-bottom: 5px;
  width: 60px;
  height: 60px;
  border-radius: var(--border-radius-xl);
}
html[data-placement=horizontal] .nav-container .user-container .user .name,
html[data-placement=vertical] .nav-container .user-container .user .name {
  color: var(--light-text);
  line-height: 1;
}
html[data-placement=horizontal] .nav-container .user-container .user-menu i,
html[data-placement=vertical] .nav-container .user-container .user-menu i {
  margin-right: 3px;
  line-height: 1.2;
}
html[data-placement=horizontal] .nav-container .language-switch-container,
html[data-placement=vertical] .nav-container .language-switch-container {
  order: 2;
  display: flex !important;
}
html[data-placement=horizontal] .nav-container .language-switch-container .dropdown-menu,
html[data-placement=vertical] .nav-container .language-switch-container .dropdown-menu {
  min-width: 65px;
}
html[data-placement=horizontal] .nav-container .language-switch-container .language-button,
html[data-placement=vertical] .nav-container .language-switch-container .language-button {
  padding: 0 8px;
  padding: 5px 14px;
  border-radius: var(--border-radius-md);
}
html[data-placement=horizontal] .nav-container .language-switch-container .language-button:hover,
html[data-placement=vertical] .nav-container .language-switch-container .language-button:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03) !important;
  background: rgba(255, 255, 255, 0.05);
}
html[data-placement=horizontal] .nav-container .logo img,
html[data-placement=horizontal] .nav-container .logo .img,
html[data-placement=vertical] .nav-container .logo img,
html[data-placement=vertical] .nav-container .logo .img {
  width: 200px;
  min-height: 60px;
  -o-object-position: left;
     object-position: left;
  -o-object-fit: cover;
     object-fit: cover;
  transition: width var(--transition-time), height var(--transition-time);
}
html[data-placement=horizontal] .nav-container .logo .img,
html[data-placement=vertical] .nav-container .logo .img {
  background-repeat: no-repeat;
}
html[data-placement=horizontal] .nav-container .dropdown-menu,
html[data-placement=vertical] .nav-container .dropdown-menu {
  border: initial;
  margin-top: initial;
  border-radius: var(--border-radius-md);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1) !important;
}
html[data-placement=horizontal] .nav-container .dropdown-menu a,
html[data-placement=vertical] .nav-container .dropdown-menu a {
  color: var(--alternate);
}
html[data-placement=horizontal] .nav-container .dropdown-menu.wide,
html[data-placement=vertical] .nav-container .dropdown-menu.wide {
  width: calc(var(--nav-size) - (var(--main-spacing-vertical)));
  padding: 20px 30px 20px 30px;
}

/*
* Horizontal
* Styles for horizontal menu
*/
html[data-placement=horizontal] .nav-container {
  height: var(--nav-size-slim);
  right: 0;
  left: 0;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  padding-left: var(--main-spacing-horizontal);
  padding-right: var(--main-spacing-horizontal);
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: var(--border-radius-lg);
  border-bottom-left-radius: var(--border-radius-lg);
}
html[data-placement=horizontal] .nav-container .nav-content {
  flex-direction: row;
  align-items: center;
  width: 100%;
}
html[data-placement=horizontal] .nav-container .nav-shadow {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: var(--border-radius-lg);
  border-bottom-left-radius: var(--border-radius-lg);
}
html[data-placement=horizontal] .nav-container .menu-container {
  margin: initial;
  height: 100%;
}
html[data-placement=horizontal] .nav-container .menu-container .menu {
  margin-left: 5rem;
  margin-right: 2rem;
  height: 100%;
  /* All li items - all of the items main and sub */
  /* Only top level li items - main menu items */
  /* Only sub level li items - sub menu items */
  /* All ul items except first one */
  /* Only second level of ul items */
}
@media (max-width: 1399.98px) {
  html[data-placement=horizontal] .nav-container .menu-container .menu {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
@media (max-width: 1199.98px) {
  html[data-placement=horizontal] .nav-container .menu-container .menu {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
}
html[data-placement=horizontal] .nav-container .menu-container .menu li a {
  white-space: nowrap;
}
html[data-placement=horizontal] .nav-container .menu-container .menu > li > a {
  padding: 0 1.5rem;
  height: 100%;
  display: inline-flex;
  align-items: center;
}
@media (max-width: 1399.98px) {
  html[data-placement=horizontal] .nav-container .menu-container .menu > li > a {
    padding: 0 1rem;
  }
}
@media (max-width: 1199.98px) {
  html[data-placement=horizontal] .nav-container .menu-container .menu > li > a {
    padding: 0 0.75rem;
  }
}
html[data-placement=horizontal] .nav-container .menu-container .menu > li > a.active, html[data-placement=horizontal] .nav-container .menu-container .menu > li > a:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03) !important;
  background: rgba(255, 255, 255, 0.05);
}
html[data-placement=horizontal] .nav-container .menu-container .menu > li > a {
  border-bottom-left-radius: var(--border-radius-md);
  border-bottom-right-radius: var(--border-radius-md);
}
html[data-placement=horizontal] .nav-container .menu-container .menu > li .label {
  max-width: 100%;
}
html[data-placement=horizontal] .nav-container .menu-container .menu ul li ul {
  left: calc(100% + 20px) !important;
}
html[data-placement=horizontal] .nav-container .menu-container .menu ul {
  left: calc(100% + 1rem);
  background: var(--foreground);
  position: absolute;
  top: 0;
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
  border-bottom-right-radius: var(--border-radius-md);
  border-bottom-left-radius: var(--border-radius-md);
  border: initial;
  padding: 1rem;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1) !important;
}
html[data-placement=horizontal] .nav-container .menu-container .menu ul a {
  color: var(--body);
  border-radius: var(--border-radius-sm);
}
html[data-placement=horizontal] .nav-container .menu-container .menu ul a.active .label::after {
  background: var(--primary);
}
html[data-placement=horizontal] .nav-container .menu-container .menu ul a.active {
  background: rgba(var(--separator-light-rgb), 0.5);
  color: var(--primary);
}
html[data-placement=horizontal] .nav-container .menu-container .menu ul a:hover {
  color: var(--primary);
  opacity: 1;
  background: rgba(var(--separator-light-rgb), 0.5);
}
html[data-placement=horizontal] .nav-container .menu-container .menu > li > ul {
  border-bottom-right-radius: var(--border-radius-md);
  border-bottom-left-radius: var(--border-radius-md);
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
}
html[data-placement=horizontal] .nav-container .logo {
  margin-top: 0;
  margin-bottom: 0;
  text-align: initial;
}
html[data-placement=horizontal] .nav-container .user-container {
  flex-direction: row;
  order: 3;
  align-items: center;
  height: 100%;
}
html[data-placement=horizontal] .nav-container .user-container .user {
  align-items: center;
  height: 100%;
  padding: 0 8px;
  margin-right: -8px;
  border-radius: initial;
  border-bottom-left-radius: var(--border-radius-md);
  border-bottom-right-radius: var(--border-radius-md);
}
html[data-placement=horizontal] .nav-container .user-container .user .profile {
  order: 1;
  width: var(--input-height);
  height: var(--input-height);
  margin-bottom: 0;
}
html[data-placement=horizontal] .nav-container .user-container .user .name {
  margin-right: 10px;
  margin-bottom: 0;
}
html[data-placement=horizontal] .nav-container .language-switch-container {
  order: 2;
  height: 100%;
  margin-right: 10px;
}
@media (max-width: 1199.98px) {
  html[data-placement=horizontal] .nav-container .language-switch-container {
    margin-right: 5px;
  }
}
html[data-placement=horizontal] .nav-container .language-switch-container .dropdown-menu {
  min-width: 65px;
}
html[data-placement=horizontal] .nav-container .language-switch-container .language-button {
  height: 100%;
  padding: 0 8px;
  border-radius: initial;
  border-bottom-left-radius: var(--border-radius-md);
  border-bottom-right-radius: var(--border-radius-md);
  padding-top: 2px;
}
html[data-placement=horizontal] .nav-container .language-switch-container .language-button:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03) !important;
  background: rgba(255, 255, 255, 0.05);
}
html[data-placement=horizontal] .nav-container .menu-icons {
  order: 1;
  margin: 0;
  margin-right: 10px;
  margin-left: auto;
  height: 100%;
}
html[data-placement=horizontal] .nav-container .menu-icons li {
  height: 100%;
}
html[data-placement=horizontal] .nav-container .menu-icons li > a {
  height: 100%;
  display: inline-flex;
  align-items: center;
  border-bottom-left-radius: var(--border-radius-md);
  border-bottom-right-radius: var(--border-radius-md);
}
@media (max-width: 1199.98px) {
  html[data-placement=horizontal] .nav-container .menu-icons {
    margin-right: 5px;
  }
}

/*
* Vertical
* Styles for vertical and mobile menu
*/
html[data-placement=vertical] .nav-container,
html[data-placement=horizontal] .nav-container.mobile-side-ready,
html[data-placement=vertical] .nav-container.mobile-side-ready {
  top: 0;
  width: var(--nav-size);
  height: 100%;
  padding-left: initial;
  padding-right: initial;
  border-top-left-radius: 0;
  border-top-right-radius: var(--border-radius-lg);
  border-bottom-right-radius: var(--border-radius-lg);
  border-bottom-left-radius: 0;
  flex-direction: column;
  justify-content: initial;
}
html[data-placement=vertical] .nav-container .nav-shadow,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-shadow,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-shadow {
  border-top-left-radius: 0;
  border-top-right-radius: var(--border-radius-lg);
  border-bottom-right-radius: var(--border-radius-lg);
  border-bottom-left-radius: 0;
}
html[data-placement=vertical] .nav-container .nav-content,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content {
  flex-direction: column;
  height: 100%;
  padding-top: var(--main-spacing-vertical);
  padding-bottom: var(--main-spacing-vertical);
  align-items: center;
  padding-right: initial !important;
}
html[data-placement=vertical] .nav-container .nav-content .mobile-buttons-container,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .mobile-buttons-container,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .mobile-buttons-container {
  display: none;
}
html[data-placement=vertical] .nav-container .nav-content .menu-icons,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .menu-icons,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .menu-icons {
  display: flex !important;
  justify-content: center;
  margin: 0;
  order: 3;
  height: auto;
}
html[data-placement=vertical] .nav-container .nav-content .menu-icons li > a,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .menu-icons li > a,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .menu-icons li > a {
  padding: 0.25rem 0.5rem;
  border-radius: var(--border-radius-md);
  height: 100%;
  display: inline-flex;
  align-items: center;
}
html[data-placement=vertical] .nav-container .nav-content .logo,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .logo,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .logo {
  margin-bottom: 20px;
  text-align: center;
}
html[data-placement=vertical] .nav-container .nav-content .logo a,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .logo a,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .logo a {
  overflow: hidden;
  display: inline-block;
  width: 200px;
}
html[data-placement=vertical] .nav-container .nav-content .language-switch-container,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .language-switch-container,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .language-switch-container {
  height: auto;
  margin-right: initial;
  display: flex !important;
}
@media (max-width: 1199.98px) {
  html[data-placement=vertical] .nav-container .nav-content .language-switch-container,
  html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .language-switch-container,
  html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .language-switch-container {
    margin-right: initial;
  }
}
html[data-placement=vertical] .nav-container .nav-content .language-switch-container .language-button,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .language-switch-container .language-button,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .language-switch-container .language-button {
  height: auto;
  padding: 5px 14px;
  border-radius: var(--border-radius-md);
}
html[data-placement=vertical] .nav-container .nav-content .user-container,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .user-container,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .user-container {
  order: 1;
  display: flex !important;
  flex-direction: column;
  height: auto;
  min-height: 100px;
}
html[data-placement=vertical] .nav-container .nav-content .user-container .user,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .user-container .user,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .user-container .user {
  flex-direction: column;
  margin-bottom: 5px;
  height: auto;
  padding: 10px;
  border-radius: var(--border-radius-md);
  margin-right: 0;
}
html[data-placement=vertical] .nav-container .nav-content .user-container .user .name,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .user-container .user .name,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .user-container .user .name {
  margin-right: 0;
}
html[data-placement=vertical] .nav-container .nav-content .user-container .user .profile,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .user-container .user .profile,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .user-container .user .profile {
  order: initial;
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}
html[data-placement=vertical] .nav-container .nav-content .menu-container,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .menu-container,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .menu-container {
  display: flex !important;
  align-self: flex-start;
  order: 3;
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 16rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
html[data-placement=vertical] .nav-container .nav-content .menu-container .menu,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .menu-container .menu,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .menu-container .menu {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
  /* All li items - all of the items main and sub */
  /* Only top level li items - main menu items */
  /* Only sub level li items - sub menu items */
}
html[data-placement=vertical] .nav-container .nav-content .menu-container .menu ul,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .menu-container .menu ul,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .menu-container .menu ul {
  box-shadow: initial !important;
  background: initial;
  position: initial;
  border-radius: initial;
  padding: initial;
  padding-left: 2rem;
}
html[data-placement=vertical] .nav-container .nav-content .menu-container .menu li a,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .menu-container .menu li a,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .menu-container .menu li a {
  border-radius: var(--border-radius-lg);
  width: 100%;
  color: var(--light-text);
  margin-bottom: 0.1rem;
  margin-top: 0.1rem;
}
html[data-placement=vertical] .nav-container .nav-content .menu-container .menu li a .icon,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .menu-container .menu li a .icon,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .menu-container .menu li a .icon {
  color: var(--light-text);
}
html[data-placement=vertical] .nav-container .nav-content .menu-container .menu li a.active,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .menu-container .menu li a.active,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .menu-container .menu li a.active {
  background: rgba(var(--light-text-rgb), 0.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03) !important;
}
html[data-placement=vertical] .nav-container .nav-content .menu-container .menu li a:hover,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .menu-container .menu li a:hover,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .menu-container .menu li a:hover {
  background: rgba(var(--light-text-rgb), 0.1);
  color: var(--light-text);
}
html[data-placement=vertical] .nav-container .nav-content .menu-container .menu > li,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .menu-container .menu > li,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .menu-container .menu > li {
  margin: 0;
  padding: 0;
}
html[data-placement=vertical] .nav-container .nav-content .menu-container .menu > li a,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .menu-container .menu > li a,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .menu-container .menu > li a {
  padding: 0.75rem 1rem;
}
html[data-placement=vertical] .nav-container .nav-content .menu-container .menu > li li a,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .menu-container .menu > li li a,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .menu-container .menu > li li a {
  padding: 0.5rem 1rem;
}
html[data-placement=vertical] .nav-container .nav-content .menu-container .menu > li li ul,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .menu-container .menu > li li ul,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .menu-container .menu > li li ul {
  padding-left: 1rem;
}
html[data-placement=vertical] .nav-container .nav-content .menu-container .menu.show,
html[data-placement=horizontal] .nav-container.mobile-side-ready .nav-content .menu-container .menu.show,
html[data-placement=vertical] .nav-container.mobile-side-ready .nav-content .menu-container .menu.show {
  display: inline-block;
}
html[data-placement=vertical] .nav-container .btn,
html[data-placement=horizontal] .nav-container.mobile-side-ready .btn,
html[data-placement=vertical] .nav-container.mobile-side-ready .btn {
  transition-property: background-color, background-image, background;
}

@media (max-width: 1199.98px) {
  html[data-placement=horizontal]:not([data-mobile=true]) .menu-icons .user .name,
  html[data-placement=horizontal]:not([data-mobile=true]) .user-container .user .name {
    display: none;
  }
}

html[data-placement=vertical][data-dimension=mobile] .nav-container .mobile-buttons-container,
html[data-placement=horizontal][data-dimension=mobile] .nav-container .mobile-buttons-container {
  margin-left: auto;
  height: 100%;
  align-items: center;
  display: flex;
}
html[data-placement=vertical][data-dimension=mobile] .nav-container .mobile-buttons-container > a, html[data-placement=vertical][data-dimension=mobile] .nav-container .mobile-buttons-container > .dropdown > a,
html[data-placement=horizontal][data-dimension=mobile] .nav-container .mobile-buttons-container > a,
html[data-placement=horizontal][data-dimension=mobile] .nav-container .mobile-buttons-container > .dropdown > a {
  color: var(--light-text);
  padding: 0.25rem 0.5rem;
  height: 100%;
  align-items: center;
  display: flex;
  border-bottom-left-radius: var(--border-radius-md);
  border-bottom-right-radius: var(--border-radius-md);
}
html[data-placement=vertical][data-dimension=mobile] .nav-container .mobile-buttons-container > a:hover, html[data-placement=vertical][data-dimension=mobile] .nav-container .mobile-buttons-container > .dropdown > a:hover,
html[data-placement=horizontal][data-dimension=mobile] .nav-container .mobile-buttons-container > a:hover,
html[data-placement=horizontal][data-dimension=mobile] .nav-container .mobile-buttons-container > .dropdown > a:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03) !important;
  background: rgba(255, 255, 255, 0.05);
}
html[data-placement=vertical][data-dimension=mobile] .nav-container .mobile-buttons-container > a i, html[data-placement=vertical][data-dimension=mobile] .nav-container .mobile-buttons-container > .dropdown > a i,
html[data-placement=horizontal][data-dimension=mobile] .nav-container .mobile-buttons-container > a i,
html[data-placement=horizontal][data-dimension=mobile] .nav-container .mobile-buttons-container > .dropdown > a i {
  font-size: 18px;
}
html[data-placement=vertical][data-dimension=mobile] .nav-container .mobile-buttons-container > .dropdown,
html[data-placement=horizontal][data-dimension=mobile] .nav-container .mobile-buttons-container > .dropdown {
  height: 100%;
}
html[data-placement=vertical][data-dimension=mobile] .nav-container .menu-icons,
html[data-placement=vertical][data-dimension=mobile] .nav-container .language-switch-container,
html[data-placement=vertical][data-dimension=mobile] .nav-container .user-container,
html[data-placement=vertical][data-dimension=mobile] .nav-container .menu-container,
html[data-placement=horizontal][data-dimension=mobile] .nav-container .menu-icons,
html[data-placement=horizontal][data-dimension=mobile] .nav-container .language-switch-container,
html[data-placement=horizontal][data-dimension=mobile] .nav-container .user-container,
html[data-placement=horizontal][data-dimension=mobile] .nav-container .menu-container {
  display: none !important;
}
html[data-placement=vertical][data-dimension=mobile] .nav-container.mobile-top-out,
html[data-placement=horizontal][data-dimension=mobile] .nav-container.mobile-top-out {
  transition-property: top;
  transition-duration: var(--transition-time-short);
  top: -100px;
}
html[data-placement=vertical][data-dimension=mobile] .nav-container.mobile-side-ready,
html[data-placement=horizontal][data-dimension=mobile] .nav-container.mobile-side-ready {
  transition-property: left;
  transition-duration: initial;
  left: calc(var(--nav-size) * -1);
}
html[data-placement=vertical][data-dimension=mobile] .nav-container.mobile-side-in,
html[data-placement=horizontal][data-dimension=mobile] .nav-container.mobile-side-in {
  transition-property: left;
  transition-duration: var(--transition-time);
  left: 0;
}
html[data-placement=vertical][data-dimension=mobile] .nav-container.mobile-side-out,
html[data-placement=horizontal][data-dimension=mobile] .nav-container.mobile-side-out {
  transition-property: left;
  transition-duration: var(--transition-time-short);
  left: calc(var(--nav-size) * -1);
}
html[data-placement=vertical][data-dimension=mobile] .nav-container.mobile-top-ready,
html[data-placement=horizontal][data-dimension=mobile] .nav-container.mobile-top-ready {
  transition-property: none;
  transition-duration: initial;
  top: -100px;
}
html[data-placement=vertical][data-dimension=mobile] .nav-container.mobile-top-in,
html[data-placement=horizontal][data-dimension=mobile] .nav-container.mobile-top-in {
  transition-property: top;
  transition-duration: var(--transition-time);
  top: 0;
}

html[data-placement=vertical][data-behaviour=unpinned]:not([data-menu-animate=show]) .nav-container {
  width: var(--nav-size-slim);
}
html[data-placement=vertical][data-behaviour=unpinned]:not([data-menu-animate=show]) .nav-container .nav-content .menu-container {
  align-self: center;
  overflow-x: hidden;
  width: 80px;
  margin-left: 0;
  margin-right: 0;
}
html[data-placement=vertical][data-behaviour=unpinned]:not([data-menu-animate=show]) .nav-container .nav-content .menu-container .menu a {
  white-space: nowrap;
}
html[data-placement=vertical][data-behaviour=unpinned]:not([data-menu-animate=show]) .nav-container .nav-content .menu-container .menu a:before,
html[data-placement=vertical][data-behaviour=unpinned]:not([data-menu-animate=show]) .nav-container .nav-content .menu-container .menu a .label {
  transition: initial;
  opacity: 0;
}
html[data-placement=vertical][data-behaviour=unpinned]:not([data-menu-animate=show]) .nav-container .nav-content .logo a {
  width: 50px;
}
html[data-placement=vertical][data-behaviour=unpinned]:not([data-menu-animate=show]) .nav-container .nav-content .logo a .img {
  width: 115px;
  height: 60px;
}
html[data-placement=vertical][data-behaviour=unpinned]:not([data-menu-animate=show]) .nav-container .nav-content .menu-icons,
html[data-placement=vertical][data-behaviour=unpinned]:not([data-menu-animate=show]) .nav-container .nav-content .language-switch-container {
  visibility: hidden;
  opacity: 0;
}
html[data-placement=vertical][data-behaviour=unpinned]:not([data-menu-animate=show]) .nav-container .nav-content .user .profile {
  width: 30px;
  height: 30px;
}
html[data-placement=vertical][data-behaviour=unpinned]:not([data-menu-animate=show]) .nav-container .nav-content .user .name {
  visibility: hidden;
  opacity: 0;
}

html[data-placement=vertical][data-behaviour=unpinned] .nav-container .nav-content .menu-container .menu ul {
  transition-duration: initial;
}

html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=hidden] .nav-container .nav-content .menu-container .menu ul,
html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=show] .nav-container .nav-content .menu-container .menu ul {
  transition-duration: var(--transition-time);
}

html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=hidden] .nav-container .nav-content .menu-container .menu a:before,
html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=hidden] .nav-container .nav-content .menu-container .menu a .label,
html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=show] .nav-container .nav-content .menu-container .menu a:before,
html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=show] .nav-container .nav-content .menu-container .menu a .label {
  transition: opacity var(--transition-time);
}

html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=hidden] .nav-container {
  transition-duration: var(--transition-time);
  transition-property: width;
}
html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=hidden] .nav-container .nav-content .menu-container {
  transition-duration: var(--transition-time);
  transition-property: opacity, width;
}
html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=hidden] .nav-container .nav-content .logo a {
  transition-duration: var(--transition-time);
  transition-property: width;
}
html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=hidden] .nav-container .nav-content .user .profile {
  transition-duration: var(--transition-time);
  transition-property: width, height;
}

html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=show] .nav-container {
  transition-duration: var(--transition-time);
  transition-property: width;
}
html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=show] .nav-container .nav-content .menu-container {
  width: 16rem;
  margin-left: 1rem;
  margin-right: 1rem;
  overflow-x: hidden;
  transition-duration: var(--transition-time);
  transition-property: opacity, width;
  align-self: center;
}
html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=show] .nav-container .nav-content .menu-container .menu a {
  white-space: nowrap;
  transition-delay: 0.1s;
}
html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=show] .nav-container .nav-content .menu-container .menu a:before,
html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=show] .nav-container .nav-content .menu-container .menu a .label {
  transition-delay: 0.1s;
}
html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=show] .nav-container .nav-content .menu-icons,
html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=show] .nav-container .nav-content .language-switch-container {
  visibility: visible;
  opacity: 1;
  transition-duration: var(--transition-time-short);
  transition-property: opacity;
  transition-delay: var(--transition-time-short);
}
html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=show] .nav-container .nav-content .user .profile {
  transition-duration: var(--transition-time);
  transition-property: width, height;
}
html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=show] .nav-container .nav-content .user .name {
  visibility: visible;
  opacity: 1;
  transition-duration: var(--transition-time-short);
  transition-property: opacity;
  transition-delay: var(--transition-time-short);
}
html[data-placement=vertical][data-behaviour=unpinned][data-menu-animate=show] .nav-container .nav-content .logo a {
  transition-duration: var(--transition-time);
  transition-property: width;
}

.opacityIn {
  animation-duration: var(--transition-time);
}

@keyframes opacityIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}
.opacityIn {
  animation-name: opacityIn;
}

html[data-placement=vertical] .nav-container .menu-container.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle,
html[data-placement=horizontal] .nav-container.mobile-side-ready .menu-container.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle,
html[data-placement=vertical] .nav-container.mobile-side-ready .menu-container.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background: rgba(var(--light-text-rgb), 0.3);
}
html[data-placement=vertical] .nav-container .menu-container.os-theme-dark > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle,
html[data-placement=horizontal] .nav-container.mobile-side-ready .menu-container.os-theme-dark > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle,
html[data-placement=vertical] .nav-container.mobile-side-ready .menu-container.os-theme-dark > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle {
  background: rgba(var(--light-text-rgb), 0.4);
}
html[data-placement=vertical] .nav-container .menu-container.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active,
html[data-placement=horizontal] .nav-container.mobile-side-ready .menu-container.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active,
html[data-placement=vertical] .nav-container.mobile-side-ready .menu-container.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active {
  background: rgba(var(--light-text-rgb), 0.4);
}
html[data-placement=vertical] .nav-container .menu-container.os-theme-dark > .os-scrollbar > .os-scrollbar-track,
html[data-placement=vertical] .nav-container .menu-container .os-theme-light > .os-scrollbar > .os-scrollbar-track,
html[data-placement=horizontal] .nav-container.mobile-side-ready .menu-container.os-theme-dark > .os-scrollbar > .os-scrollbar-track,
html[data-placement=horizontal] .nav-container.mobile-side-ready .menu-container .os-theme-light > .os-scrollbar > .os-scrollbar-track,
html[data-placement=vertical] .nav-container.mobile-side-ready .menu-container.os-theme-dark > .os-scrollbar > .os-scrollbar-track,
html[data-placement=vertical] .nav-container.mobile-side-ready .menu-container .os-theme-light > .os-scrollbar > .os-scrollbar-track {
  background: rgba(var(--light-text-rgb), 0.1);
}

/*
Arrows collapse for vertical menu
*/
.menu a[data-bs-toggle=collapse]:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  border-top: 1px solid var(--light-text);
  border-right: 1px solid var(--light-text);
  transform: rotate(45deg);
  bottom: initial;
  top: 14px;
  left: initial;
  right: 1rem;
  top: calc(50% - 3px);
}
.menu a[data-bs-toggle=collapse][aria-expanded=true]:before {
  transform: rotate(135deg);
  top: 13px;
  top: calc(50% - 4px);
}

/*
Arrows collapse for horizontal menu
*/
.menu a.dropdown-toggle:before {
  content: "";
  width: 5px;
  height: 5px;
  border-top: 1px solid var(--light-text);
  border-right: 1px solid var(--light-text);
  transform: rotate(45deg);
  order: 3;
  margin-left: 5px;
  margin-top: 3px;
}
.menu li.dropdown.show > a.dropdown-toggle:before {
  content: "";
  transform: rotate(135deg);
  margin-top: 1px;
}
.menu > li > a.show:before {
  content: "";
  transform: rotate(135deg);
  margin-top: 1px;
}
.menu > li li a.dropdown-toggle:before {
  position: absolute;
  top: calc(50% - 2px);
  border-top: 1px solid var(--alternate);
  border-right: 1px solid var(--alternate);
  margin-top: initial;
  right: 12px;
}
.menu > li li.dropdown.show > a.dropdown-toggle:before {
  content: "";
  top: calc(50% - 3px);
  margin-top: initial;
  right: 11px;
}

#colorButton .light {
  display: none;
}

#pinButton.disabled {
  cursor: default;
}
#pinButton.disabled i {
  opacity: 0.5;
}

.notification-dropdown .scroll {
  height: 185px;
}

html[data-color*=light] #colorButton .light {
  display: inline-block;
}
html[data-color*=light] #colorButton .dark {
  display: none;
}

html[data-color*=dark] #colorButton .light {
  display: none;
}
html[data-color*=dark] #colorButton .dark {
  display: inline-block;
}

html:not([data-scrollspy=true]) #scrollSpyButton,
html:not([data-scrollspy=true]) #scrollSpyDropdown {
  display: none !important;
}

html[data-behaviour=pinned] .pin-button .pin {
  display: none;
}
html[data-behaviour=pinned] .pin-button .unpin {
  display: inline-block;
}

html[data-behaviour=unpinned] .pin-button .pin {
  display: inline-block;
}
html[data-behaviour=unpinned] .pin-button .unpin {
  display: none;
}

html[data-placement=horizontal][data-behaviour=unpinned]:not([data-mobile=true]) .nav-container {
  transition-property: top;
  transition-duration: var(--transition-time-short);
}

html[data-placement=horizontal][data-behaviour=unpinned][data-menu-animate=hidden]:not([data-mobile=true]) .nav-container {
  top: -100px;
}

html[data-placement=horizontal]:not([data-mobile=true]) .nav-container .menu .mega > ul.show {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  width: calc(100% - var(--main-spacing-horizontal) * 2);
}
html[data-placement=horizontal]:not([data-mobile=true]) .nav-container .menu .mega > ul.show > li > a {
  color: var(--primary) !important;
  background: initial !important;
}
html[data-placement=horizontal]:not([data-mobile=true]) .nav-container .menu .mega > ul.show a {
  padding: 0.35rem 1rem !important;
}
html[data-placement=horizontal]:not([data-mobile=true]) .nav-container .menu .mega > ul.show a.dropdown-toggle {
  background: initial !important;
  pointer-events: none;
  cursor: default;
}
html[data-placement=horizontal]:not([data-mobile=true]) .nav-container .menu .mega > ul.show a.dropdown-toggle:before {
  border: initial !important;
}
html[data-placement=horizontal]:not([data-mobile=true]) .nav-container .menu .mega > ul ul {
  position: static !important;
  box-shadow: initial !important;
}
html[data-placement=horizontal]:not([data-mobile=true]) .nav-container .menu .mega > ul > li {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
html[data-placement=horizontal]:not([data-mobile=true]) .nav-container .menu .mega > ul > li > ul {
  padding: initial;
}
html[data-placement=horizontal]:not([data-mobile=true]) .nav-container .menu .mega > ul > li ul {
  padding-top: initial;
  padding-bottom: initial;
}

html[data-placement=horizontal][data-mobile=true] .nav-container .menu > li > ul {
  margin-top: 0;
}
html[data-placement=horizontal][data-mobile=true] .nav-container .menu > li > a {
  display: inline-block;
}

html[data-navcolor=light] .nav-shadow {
  box-shadow: var(--menu-shadow-navcolor) !important;
}
html[data-navcolor=light] .nav-container {
  background: var(--background-navcolor-light);
}
html[data-navcolor=light] .nav-container .menu-container .menu a {
  color: var(--alternate) !important;
}
html[data-navcolor=light] .nav-container .menu-container .menu a.active, html[data-navcolor=light] .nav-container .menu-container .menu a:hover {
  background: rgba(0, 0, 0, 0.05) !important;
}
html[data-navcolor=light] .nav-container .menu-container .menu a .icon {
  color: var(--alternate) !important;
}
html[data-navcolor=light] .nav-container .menu-icons > li > a {
  color: var(--alternate);
}
html[data-navcolor=light] .nav-container .menu-icons > li > a:hover {
  background: rgba(0, 0, 0, 0.05) !important;
}
html[data-navcolor=light] .nav-container .language-switch-container .language-button {
  color: var(--alternate) !important;
}
html[data-navcolor=light] .nav-container .language-switch-container .language-button:hover {
  background: rgba(0, 0, 0, 0.05) !important;
}
html[data-navcolor=light] .nav-container .mobile-buttons-container > a,
html[data-navcolor=light] .nav-container .mobile-buttons-container > .dropdown > a {
  color: var(--primary) !important;
}
html[data-navcolor=light] .nav-container .mobile-buttons-container > a:hover,
html[data-navcolor=light] .nav-container .mobile-buttons-container > .dropdown > a:hover {
  background: rgba(0, 0, 0, 0.05) !important;
}
html[data-navcolor=light] .nav-container .menu-container .menu > li > a .icon {
  color: var(--primary);
}
html[data-navcolor=light] .nav-container .user-container .user .name {
  color: var(--alternate);
}
html[data-navcolor=light] .nav-container .user-container .user:hover {
  background: rgba(0, 0, 0, 0.05) !important;
}
html[data-navcolor=light] .nav-container .nav-content .menu-container .menu ul a {
  color: var(--alternate);
}
html[data-navcolor=light] .nav-container .menu-container .menu a.active .label::after {
  background: var(--primary);
}
html[data-navcolor=light] .menu a[data-bs-toggle=collapse]:before {
  border-color: var(--alternate);
}
html[data-navcolor=light] .menu a.dropdown-toggle:before {
  border-top: 1px solid var(--alternate);
  border-right: 1px solid var(--alternate);
}
html[data-navcolor=light] .menu-container.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background: rgba(var(--alternate-rgb), 0.3) !important;
}
html[data-navcolor=light] .menu-container.os-theme-dark > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle {
  background: rgba(var(--alternate-rgb), 0.4) !important;
}
html[data-navcolor=light] .menu-container.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active {
  background: rgba(var(--alternate-rgb), 0.4) !important;
}
html[data-navcolor=light] .menu-container.os-theme-dark > .os-scrollbar > .os-scrollbar-track,
html[data-navcolor=light] .menu-container .os-theme-light > .os-scrollbar > .os-scrollbar-track {
  background: rgba(var(--alternate-rgb), 0.1) !important;
}

html[data-navcolor=dark] .nav-shadow {
  box-shadow: var(--menu-shadow-navcolor);
}
html[data-navcolor=dark] .nav-container {
  background: var(--background-navcolor-dark);
}
html[data-navcolor=dark] .nav-container .menu-container .menu > li > a {
  color: var(--light-text);
}
html[data-navcolor=dark] .nav-container .language-switch-container .language-button {
  color: var(--light-text) !important;
}
html[data-navcolor=dark] .nav-container .language-switch-container .language-button:hover {
  background: rgba(255, 255, 255, 0.05) !important;
}
html[data-navcolor=dark] .nav-container .mobile-buttons-container > a,
html[data-navcolor=dark] .nav-container .mobile-buttons-container > .dropdown > a {
  color: var(--light-text) !important;
}
html[data-navcolor=dark] .nav-container .mobile-buttons-container > a:hover,
html[data-navcolor=dark] .nav-container .mobile-buttons-container > .dropdown > a:hover {
  background: rgba(255, 255, 255, 0.05) !important;
}
html[data-navcolor=dark] .nav-container .menu-icons > li > a {
  color: var(--light-text);
}
html[data-navcolor=dark] .nav-container .menu-container .menu > li > a .icon {
  color: var(--light-text);
}
html[data-navcolor=dark] .nav-container .user-container .user .name {
  color: var(--light-text);
}
html[data-navcolor=dark] .nav-container .menu-container .menu a.active .label::after {
  background: var(--light-text);
}
html[data-navcolor=dark] .nav-container .menu-container .menu ul {
  background: var(--background-navcolor-dark) !important;
}
html[data-navcolor=dark] .nav-container .menu-container .menu a {
  color: var(--light-text) !important;
}
html[data-navcolor=dark] .nav-container .menu-container .menu a.active, html[data-navcolor=dark] .nav-container .menu-container .menu a:hover {
  background: rgba(255, 255, 255, 0.05) !important;
}

.collapsing {
  transition-duration: var(--transition-time);
}

.logo-default {
  width: 100px;
  min-height: 35px;
  -o-object-position: left;
     object-position: left;
  -o-object-fit: cover;
     object-fit: cover;
  background-repeat: no-repeat;
}

html[data-color=light-blue] .logo .img,
html[data-color=light-green] .logo .img,
html[data-color=light-red] .logo .img,
html[data-color=light-pink] .logo .img,
html[data-color=light-purple] .logo .img,
html[data-color=dark-blue] .logo .img,
html[data-color=dark-green] .logo .img,
html[data-color=dark-red] .logo .img,
html[data-color=dark-pink] .logo .img,
html[data-color=dark-purple] .logo .img {
  /* background-image: url(../img/logo/logo-light.svg); */
  background-image: url("../assets//img/logo-one.png");
}

html[data-color=light-blue] .logo-default {
  /* background-image: url(../img/logo/logo-blue-light.svg); */
}

html[data-color=light-green] .logo-default {
  /* background-image: url(../img/logo/logo-green-light.svg); */
}

html[data-color=light-red] .logo-default {
  /* background-image: url(../img/logo/logo-red-light.svg); */
}

html[data-color=light-pink] .logo-default {
  /* background-image: url(../img/logo/logo-pink-light.svg); */
}

html[data-color=light-purple] .logo-default {
  /* background-image: url(../img/logo/logo-purple-light.svg); */
}

html[data-color=dark-blue] .logo-default {
  /* background-image: url(../img/logo/logo-blue-dark.svg); */
}

html[data-color=dark-green] .logo-default {
  /* background-image: url(../img/logo/logo-green-dark.svg); */
}

html[data-color=dark-red] .logo-default {
  /* background-image: url(../img/logo/logo-red-dark.svg); */
}

html[data-color=dark-pink] .logo-default {
  /* background-image: url(../img/logo/logo-pink-dark.svg); */
}

html[data-color=dark-purple] .logo-default {
  /* background-image: url(../img/logo/logo-purple-dark.svg); */
}
/* 
html[data-color=light-blue][data-navcolor=light] .logo .img,
html[data-color=dark-blue][data-navcolor=light] .logo .img {
  background-image: url(../img/logo/logo-blue-light.svg);
}

html[data-color=light-green][data-navcolor=light] .logo .img,
html[data-color=dark-green][data-navcolor=light] .logo .img {
  background-image: url(../img/logo/logo-green-light.svg);
}

html[data-color=light-red][data-navcolor=light] .logo .img,
html[data-color=dark-red][data-navcolor=light] .logo .img {
  background-image: url(../img/logo/logo-red-light.svg);
}

html[data-color=light-pink][data-navcolor=light] .logo .img,
html[data-color=dark-pink][data-navcolor=light] .logo .img {
  background-image: url(../img/logo/logo-pink-light.svg);
}

html[data-color=light-purple][data-navcolor=light] .logo .img,
html[data-color=dark-purple][data-navcolor=light] .logo .img {
  background-image: url(../img/logo/logo-purple-light.svg);
}

html[data-color=light-blue][data-navcolor=dark] .logo .img,
html[data-color=dark-blue][data-navcolor=dark] .logo .img {
  background-image: url(../img/logo/logo-blue-dark.svg);
}

html[data-color=light-green][data-navcolor=dark] .logo .img,
html[data-color=dark-green][data-navcolor=dark] .logo .img {
  background-image: url(../img/logo/logo-green-dark.svg);
}

html[data-color=light-red][data-navcolor=dark] .logo .img,
html[data-color=dark-red][data-navcolor=dark] .logo .img {
  background-image: url(../img/logo/logo-red-dark.svg);
}

html[data-color=light-pink][data-navcolor=dark] .logo .img,
html[data-color=dark-pink][data-navcolor=dark] .logo .img {
  background-image: url(../img/logo/logo-pink-dark.svg);
}

html[data-color=light-purple][data-navcolor=dark] .logo .img,
html[data-color=dark-purple][data-navcolor=dark] .logo .img {
  background-image: url(../img/logo/logo-purple-dark.svg);
} */

.menu-container .label,
.user-container .name {
  font-family: var(--font-heading);
  font-size: 13px;
}

/*
*
* Nav Side
*
* Template styles for sidebar navigation.
*
*/
html[data-dimension=mobile] .side-menu-container {
  display: none;
}

.side-menu {
  list-style: none;
  padding-left: initial;
  margin-top: -0.5rem;
}
.side-menu ul {
  list-style: none;
  padding-left: initial;
  margin-bottom: 1rem;
}
.side-menu a {
  color: var(--body);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: block;
}
.side-menu a:hover, .side-menu a.active {
  color: var(--primary);
}
.side-menu a:hover .label, .side-menu a.active .label {
  color: var(--primary);
}
.side-menu a .icon {
  width: 17px;
  height: 17px;
  display: inline-block !important;
}
.side-menu a[data-bs-target] {
  color: var(--muted);
  font-size: 0.75rem !important;
  line-height: 1.1rem !important;
  font-weight: initial !important;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
  cursor: default !important;
  pointer-events: none !important;
}
.side-menu a[data-bs-target] .icon {
  display: none !important;
}
.side-menu .icon {
  width: 20px;
  vertical-align: middle;
  margin-right: 10px;
  color: var(--alternate);
}
.side-menu .label {
  vertical-align: middle;
  color: var(--body);
}

.side-menu.primary a {
  color: var(--alternate);
}
.side-menu.primary a:hover, .side-menu.primary a.active {
  color: var(--primary);
}
.side-menu.primary a:hover .icon,
.side-menu.primary a:hover .label, .side-menu.primary a.active .icon,
.side-menu.primary a.active .label {
  color: var(--primary);
}
.side-menu.primary a[data-bs-target] {
  color: var(--muted);
}
.side-menu.primary a[data-bs-target] .label {
  color: var(--muted);
}

/*
*
* Footer
*
* Template styles for footer.
*
*/
html[data-footer=true] body {
  min-height: 100%;
  position: relative;
  padding-bottom: var(--footer-size);
}
html[data-footer=true] #root > footer {
  border-top: 1px solid var(--separator);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: var(--footer-size);
  display: flex;
  padding-left: calc(var(--nav-size) + var(--main-spacing-horizontal));
  padding-right: var(--main-spacing-horizontal);
}
html[data-footer=true] #root > footer > .container-fluid, html[data-footer=true] #root > footer > .container-lg, html[data-footer=true] #root > footer > .container-md, html[data-footer=true] #root > footer > .container-sm, html[data-footer=true] #root > footer > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
html[data-footer=true] #root > footer .footer-content {
  display: flex;
  width: 100%;
  align-items: center;
}

html[data-footer=true][data-placement=horizontal] #root > footer {
  padding-left: var(--main-spacing-horizontal);
}

@media (max-width: 1199.98px) {
  html[data-footer=true][data-placement=vertical] #root > footer {
    padding-left: calc(var(--main-spacing-horizontal) + var(--footer-size));
  }
}

html[data-footer=true][data-placement=vertical][data-behaviour=unpinned] #root > footer {
  padding-left: calc(var(--main-spacing-horizontal) + var(--footer-size));
}

html[data-footer=true][data-layout=fluid] #root > footer .container {
  width: 100%;
  max-width: initial;
  padding-right: 0;
  padding-left: 0;
}

@media (max-width: 1199.98px) {
  html[data-footer=true][data-layout=boxed] #root > footer .container {
    width: 100%;
    max-width: initial;
    padding-right: 0;
    padding-left: 0;
  }
}

html:not([data-footer=true]) #root > footer {
  display: none;
}

/*
*
* Print
*
* Print styles.
*
*/
@media print {
  * {
    transition: initial !important;
  }
  body,
  html,
  main {
    padding: 0 !important;
    margin: 0 !important;
  }
  .settings-buttons-container,
  #settingsButton,
  #nichesButton,
  #purchaseButton,
  #nav,
  footer {
    display: none !important;
  }
  a {
    text-decoration: initial !important;
  }
  .card.card-print {
    margin: initial !important;
    box-shadow: initial !important;
  }
  .card.card-print .card-body {
    padding: 0 !important;
  }
  main.print-restricted .container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  main.print-restricted .container > *:not(.print-me) {
    display: none;
  }
  html[data-placement=horizontal] main,
  html[data-placement=vertical] main {
    padding: 0 !important;
    margin: 0 !important;
  }
}
/*
*
* Settings
*
* Settings modal styles.
*
*/
#settings .card {
  height: 56px;
  border: 1px solid var(--separator);
  display: block;
}
#settings .radius-rounded .figure {
  border-radius: 5px !important;
}
#settings .radius-regular .figure {
  border-radius: 2px !important;
}
#settings .radius-flat .figure {
  border-radius: 0 !important;
}
#settings .option.active .card, #settings .option:hover .card {
  border: 1px solid var(--primary);
}
#settings .option.active .text-part, #settings .option:hover .text-part {
  color: var(--primary) !important;
}
#settings .figure {
  border-radius: 5px;
  display: block;
}
#settings .figure.figure-primary {
  background: var(--primary);
}
#settings .figure.figure-muted {
  background: var(--muted);
}
#settings .figure.figure-secondary {
  background: rgba(var(--separator-rgb), 0.6);
}
#settings .figure.figure-dark {
  background: var(--dark);
}
#settings .figure.figure-light {
  background: var(--light);
}
#settings .figure.top {
  width: 100%;
  height: 6px;
  margin-bottom: 5px;
}
#settings .figure.bottom {
  width: 100%;
  height: 12px;
}
#settings .figure.bottom.small {
  width: 50%;
  margin-left: 25%;
}
#settings .figure.left {
  height: 100%;
  width: 6px;
  margin-right: 5px;
  float: left;
}
#settings .figure.left.large {
  width: 14px;
}
#settings .figure.right {
  height: 100%;
  float: right;
  width: 80px;
}
#settings .figure.right.small {
  width: 70px;
}
#settings .figure.right.small.top {
  height: 6px;
  margin-bottom: 5px;
}
#settings .figure.right.small.bottom {
  height: 12px;
  margin-left: initial;
}
#settings .color {
  height: 56px;
}
#settings .color div {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
}
#settings .blue-light {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 125 28'%3E%3Crect width='49' height='28' rx='5' style='fill:%231ea8e7'/%3E%3Crect x='57' width='30' height='28' rx='5' style='fill:%236cdbef'/%3E%3Crect x='95' width='30' height='11' rx='5' style='fill:%235de3c8'/%3E%3Crect x='95' y='17' width='30' height='11' rx='5' style='fill:%23ececec'/%3E%3C/svg%3E");
}
#settings .blue-dark {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 125 28'%3E%3Crect width='49' height='28' rx='5' style='fill:%231b98d0'/%3E%3Crect x='57' width='30' height='28' rx='5' style='fill:%2361c6d8'/%3E%3Crect x='95' width='30' height='11' rx='5' style='fill:%2354cdb4'/%3E%3Crect x='95' y='17' width='30' height='11' rx='5' style='fill:%233d3d3d'/%3E%3C/svg%3E");
}
#settings .red-light {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 125 28'%3E%3Crect width='49' height='28' rx='5' style='fill:%23eb5858'/%3E%3Crect x='57' width='30' height='28' rx='5' style='fill:%23ff9773'/%3E%3Crect x='95' width='30' height='11' rx='5' style='fill:%23f9a049'/%3E%3Crect x='95' y='17' width='30' height='11' rx='5' style='fill:%23ececec'/%3E%3C/svg%3E");
}
#settings .red-dark {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 125 28'%3E%3Crect width='49' height='28' rx='5' style='fill:%23d44f4f'/%3E%3Crect x='57' width='30' height='28' rx='5' style='fill:%23e68868'/%3E%3Crect x='95' width='30' height='11' rx='5' style='fill:%23e19042'/%3E%3Crect x='95' y='17' width='30' height='11' rx='5' style='fill:%233d3d3d'/%3E%3C/svg%3E");
}
#settings .green-light {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 125 28'%3E%3Crect width='49' height='28' rx='5' style='fill:%2375ab38'/%3E%3Crect x='57' width='30' height='28' rx='5' style='fill:%23b8d518'/%3E%3Crect x='95' width='30' height='11' rx='5' style='fill:%2334b92e'/%3E%3Crect x='95' y='17' width='30' height='11' rx='5' style='fill:%23ececec'/%3E%3C/svg%3E");
}
#settings .green-dark {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 125 28'%3E%3Crect width='49' height='28' rx='5' style='fill:%236a9a33'/%3E%3Crect x='57' width='30' height='28' rx='5' style='fill:%23a6c016'/%3E%3Crect x='95' width='30' height='11' rx='5' style='fill:%232fa729'/%3E%3Crect x='95' y='17' width='30' height='11' rx='5' style='fill:%233d3d3d'/%3E%3C/svg%3E");
}
#settings .pink-light {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 125 28'%3E%3Crect width='49' height='28' rx='5' style='fill:%23ed6789'/%3E%3Crect x='57' width='30' height='28' rx='5' style='fill:%23ffa7a0'/%3E%3Crect x='95' width='30' height='11' rx='5' style='fill:%23f74b63'/%3E%3Crect x='95' y='17' width='30' height='11' rx='5' style='fill:%23ececec'/%3E%3C/svg%3E");
}
#settings .pink-dark {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 125 28'%3E%3Crect width='49' height='28' rx='5' style='fill:%23d65d7c'/%3E%3Crect x='57' width='30' height='28' rx='5' style='fill:%23e69790'/%3E%3Crect x='95' width='30' height='11' rx='5' style='fill:%23df4459'/%3E%3Crect x='95' y='17' width='30' height='11' rx='5' style='fill:%233d3d3d'/%3E%3C/svg%3E");
}
#settings .purple-light {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 125 28'%3E%3Crect width='49' height='28' rx='5' style='fill:%23a772cb'/%3E%3Crect x='57' width='30' height='28' rx='5' style='fill:%23ecb0fd'/%3E%3Crect x='95' width='30' height='11' rx='5' style='fill:%23a95ca9'/%3E%3Crect x='95' y='17' width='30' height='11' rx='5' style='fill:%23ececec'/%3E%3C/svg%3E");
}
#settings .purple-dark {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 125 28'%3E%3Crect width='49' height='28' rx='5' style='fill:%239767b7'/%3E%3Crect x='57' width='30' height='28' rx='5' style='fill:%23d59fe4'/%3E%3Crect x='95' width='30' height='11' rx='5' style='fill:%23985398'/%3E%3Crect x='95' y='17' width='30' height='11' rx='5' style='fill:%233d3d3d'/%3E%3C/svg%3E");
}

html[data-radius=flat] #settings .figure {
  border-radius: 0;
}

html[data-radius=standard] #settings .figure {
  border-radius: 2px;
}

.settings-buttons-container {
  position: fixed !important;
  right: -1px;
  top: 50%;
  z-index: 1000 !important;
  margin-top: -70px;
}

.settings-button {
  display: block;
  opacity: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: var(--border-radius-md);
  border-bottom-left-radius: var(--border-radius-md);
  font-size: 18px;
  margin-bottom: 10px;
}
.settings-button span {
  padding: 0.4rem 0.85rem 0.4rem 0.85rem;
  height: 40px;
  display: inline-block;
}
.settings-button span svg {
  margin-top: 3px;
}
.settings-button::before {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: var(--border-radius-md) !important;
  border-bottom-left-radius: var(--border-radius-md) !important;
}

html[data-show=true] .settings-button {
  opacity: 1;
}

.nav-container .logo a img {
  display: none;
}
html[data-menu-animate=show] .nav-container .logo img {
  display: block!important;
}/*# sourceMappingURL=styles.css.map */