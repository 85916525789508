@media only screen and (max-width: 1280px) {}

@media only screen and (max-width: 1024px) {
    .fb-icon {
        font-size: 20px;
    }

    .tweet-i {
        width: 28px;
    }

    .ii-gram {
        width: 28px;
    }

    .newpost-sec h2 {
        font-size: 24px;
    }

    .flex-dis {
        display: flex;
    }

    .postimage {
        padding: 20px;
    }

    .preview {
        padding: 20px;
    }

    .fb-icon {
        font-size: 22px;
    }

    .profilelogo-time h4 {
        font-size: 16px;
    }
    .instagram-post-card {
        padding: 10px 15px ;
    }
    .tag-qoute {
        padding: 4px 0 0px 0;
    }
    .ul-card span {
        font-size: 14px;
        right: 9%;
    }
}

@media only screen and (max-width: 992px) {
    .newpost-sec h2 {
        font-size: 24px;
    }

    .profilelogo-time span {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 1.5;
    }

    .res-h6 {
        margin: 0;
        padding-top: 15px;
    }

    .flex-dis {
        display: flex;
    }

    .instagram-post-card {
        margin-top: 15px;
    }

    .shedulepost-card h5 {
        padding: 0 !important;
        margin: 0 !important;
    }
}

@media only screen and (max-width: 767px) {
    .preview {
        margin-top: 25px;
        height: auto;
    }

    .postimage {
        height: auto;
    }

    .selectcard-newpost {
        padding: 10px 0;
    }

    .selectprofile-newpost {
        padding: 10px 0;
    }

    .newpost-content {
        padding: 10px 0;
    }

    .preview {
        padding: 20px;
    }

    .postimage {
        padding: 20px;
    }

    .tag-qoute {
        padding: 5px 0;
    }

    .align-key {
        justify-content: start;
    }

    .tweet-i {
        width: 100%;
    }

    .insta-align {
        justify-content: start;
    }

    .ii-gram {
        width: 100%;
    }

    .icon-width {
        width: 12% !important;
    }

    .content-width {
        width: 50% !important;
    }

    .preview-img {
        margin: 10px 0 0 0;
    }
    .width-8{
        width: 8% !important;
    }
    .width-50{
        width: 50% !important;
    }
}
@media only screen and (max-width: 575px){
    .leftsidebar {
        top: 10%;
    }
    .width-86{
        width: 86% !important;
    }

}

@media only screen and (max-width: 480px) {
    .icon-width {
        width: 15% !important;
    }
}