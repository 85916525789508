/*
*
* Badge
*
* Bootstrap badge styles.
*
*/

.badge {
  padding: 0.3em 0.75em;
  font-size: 0.7em;
  font-weight: 400;
  border-radius: var(--border-radius-md);
  color: var(--light-text);
  text-indent: -1px;
  line-height: 1.25;
}

.btn {
  .badge {
    padding-top: 1px;
    padding-bottom: 1px;
  }
}

a.badge,
.btn.badge {
  box-shadow: initial !important;
}

.badge {
  background: unset;
  border: initial !important;
}
