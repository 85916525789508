/*
*
* React Select
*
* Select form control styles.
*
*/

.react-select__indicator-separator {
  display: none;
}

.react-select__input {
  color: var(--body);
}

.react-select__control {
  box-shadow: initial !important;
  background-color: var(--foreground) !important;
  border-color: var(--separator) !important;
  border-radius: var(--border-radius-md) !important;
  min-height: var(--input-height) !important;
}

.react-select__control--menu-is-open {
  border-color: var(--primary) !important;
}

.react-select__indicator {
  padding: 7px !important;
}

.react-select__value-container {
  padding: 0.15rem 0.75rem !important;
}

.react-select__indicator svg {
  width: 15px;
  height: 15px;
  color: var(--separator);
}

.react-select__menu {
  border-radius: var(--border-radius-md) !important;
  border: 1px solid var(--primary) !important;
  background: var(--foreground) !important;
  box-shadow: initial !important;
  z-index: 5 !important;
  margin-top: 3px !important;
}

.react-select__option {
  border-radius: var(--border-radius-sm) !important;
  padding: 0.5rem 0.75rem !important;
  line-height: 1.3 !important;
  background: var(--foreground) !important;
  color: var(--body) !important;
}
.react-select__option.react-select__option--is-focused {
  background: var(--separator-light) !important;
  color: var(--primary) !important;
}

.react-select__option--is-selected {
  color: var(--primary) !important;
}

.react-select__menu-list {
  padding: 0.25rem !important;
  margin: 0.5rem !important;
  max-height: 215px !important;

  ::-webkit-scrollbar,
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: transparent;
    border-radius: var(--border-radius-md);
    cursor: default !important;
  }

  ::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    border-radius: var(--border-radius-md);
    background: rgba(var(--muted-rgb), 0.4);
    cursor: default !important;
  }

  ::-webkit-scrollbar-button,
  &::-webkit-scrollbar-button {
    background: transparent;
    width: 4px;
    height: 3px;
    cursor: default !important;
  }

  ::-webkit-scrollbar-track-piece,
  &::-webkit-scrollbar-track-piece {
    background: rgba(var(--muted-rgb), 0.15);
    border-radius: var(--border-radius-md);
    cursor: default !important;
  }
}

.react-select__multi-value {
  background: initial !important;
  border: 1px solid var(--separator);
  border-radius: var(--border-radius-sm) !important;

  &:first-of-type {
    margin-left: -0.25rem;
  }
}

.react-select__multi-value__label,
.react-select__single-value {
  color: var(--body) !important;
}

.react-select__multi-value__remove {
  background: initial !important;
  color: var(--muted) !important;
  &:hover {
    color: var(--primary) !important;
  }
}

.option-circle {
  width: 15px;
  height: 15px;
}

.top-label {
  .react-select__control {
    min-height: 52px !important;
    padding-top: 1rem !important;
  }

  .react-select__value-container {
    padding-left: 0.6rem !important;
  }

  .react-select__multi-value__label {
    padding: 0 !important;
  }
}

.filled {
  .react-select__control {
    min-height: 44px !important;
    border: 1px solid transparent !important;
    background: var(--background-light) !important;
  }

  .react-select__value-container {
    padding-left: 43px !important;
  }

  .react-select__placeholder {
    color: var(--alternate) !important;
  }

  .react-select__control--menu-is-open {
    border: 1px solid var(--primary) !important;
    background: var(--foreground) !important;
  }
}

.form-floating.react-select__control {
  min-height: 52px !important;
  padding-top: 1rem !important;
  & > label {
    -webkit-transform: initial;
    transform: initial;
    color: var(--alternate);
    transition: initial;
  }

  &.react-select__control--menu-is-open > label,
  .react-select__value-container.react-select__value-container--has-value ~ label {
    -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    color: var(--muted);
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  }
}
