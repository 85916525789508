/*
*
* Autosuggest
*
* Autosuggest form component styles.
*
*/

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input--focused {
  border: 1px solid var(--primary);
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  z-index: 2;
  position: absolute;
  list-style: none;
  background: var(--foreground);
  width: 100%;
  border-radius: var(--border-radius-md);
  border: 1px solid rgba(var(--primary-rgb), 1) !important;
  padding: 0.75rem;
  margin-top: 4px;

  &.scroll .react-autosuggest__suggestions-list {
    max-height: 150px;
    padding-right: 0.5rem;
    margin-right: -0.5rem;
    overflow-y: auto;
  }
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0.5rem 0.75rem !important;
  border-radius: var(--border-radius-sm);
  line-height: 1.3;
  margin-top: 5px;

  &:first-of-type {
    margin-top: 0;
  }
}

.react-autosuggest__suggestion--highlighted {
  color: var(--primary);
  background: var(--separator-light) !important;
}

.autocomplete-scroll {
  .react-autosuggest__suggestions-container--open {
    .react-autosuggest__suggestions-list {
      max-height: 150px;
      padding-right: 0.5rem;
      margin-right: -0.5rem;
      overflow-y: auto;

      ::-webkit-scrollbar,
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background: transparent;
        border-radius: var(--border-radius-md);
        cursor: default !important;
      }

      ::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-thumb {
        border-radius: var(--border-radius-md);
        background: rgba(var(--muted-rgb), 0.4);
        cursor: default !important;
      }

      ::-webkit-scrollbar-button,
      &::-webkit-scrollbar-button {
        background: transparent;
        width: 4px;
        height: 3px;
        cursor: default !important;
      }

      ::-webkit-scrollbar-track-piece,
      &::-webkit-scrollbar-track-piece {
        background: rgba(var(--muted-rgb), 0.15);
        border-radius: var(--border-radius-md);
        cursor: default !important;
      }
    }
  }
}

.top-label {
  .react-autosuggest__input {
    height: auto;
    min-height: 52px;
    padding: 1.5rem 0.75rem 0.25rem 0.75rem;
  }
}

.filled {
  .react-autosuggest__input {
    min-height: 44px;
    border: 1px solid transparent;
    background: var(--background-light);
    padding-left: 45px;
  }

  .react-autosuggest__input--focused {
    border: 1px solid var(--primary);
  }
}
