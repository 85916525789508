/*
*
* Main
*
* Template styles for main content area.
*
*/

main {
  min-height: 100%;
  padding-left: calc(var(--nav-size) + var(--main-spacing-horizontal));
  padding-right: var(--main-spacing-horizontal);
  padding-top: var(--main-spacing-vertical);
  padding-bottom: var(--main-spacing-vertical);
}

main > .container-fluid,
main > .container-lg,
main > .container-md,
main > .container-sm,
main > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

html[data-placement='horizontal'] {
  main {
    padding-left: var(--main-spacing-horizontal);
    padding-top: calc(var(--nav-size-slim) + calc(var(--main-spacing-horizontal) / 2));
  }
}

html[data-placement='vertical'][data-dimension='mobile'] main {
  padding-left: calc(var(--main-spacing-horizontal) + var(--nav-size-slim));
}

html[data-placement='vertical'][data-behaviour='unpinned'] main {
  padding-left: calc(var(--main-spacing-horizontal) + var(--nav-size-slim));
}

html[data-layout='fluid'] {
  main .container {
    width: 100%;
    max-width: initial;
    padding-right: 0;
    padding-left: 0;
  }
}

html[data-layout='boxed'] {
  @include respond-below(lg) {
    main .container {
      width: 100%;
      max-width: initial;
      padding-right: 0;
      padding-left: 0;
    }
  }
}

html[data-placement='horizontal'][data-dimension='mobile'],
html[data-placement='vertical'][data-dimension='mobile'] {
  main {
    padding-left: var(--main-spacing-horizontal) !important;
    padding-top: calc(var(--nav-size-slim) + calc(var(--main-spacing-horizontal) / 2));
  }
}

html[data-fullpage='true'] {
  body,
  #root,
  main {
    height: 100%;
  }

  main .container {
    height: calc(100%);
  }
}

html[data-footer='true'][data-fullpage='true'] {
  body,
  #root,
  main {
    height: 100%;
  }

  main .container {
    height: 100%;
  }
}

html[data-placement='horizontal'] {
  section.scroll-section {
    scroll-margin-top: calc(var(--nav-size-slim) + calc(var(--main-spacing-horizontal) / 2));
  }
}

html[data-placement='vertical'] {
  section.scroll-section {
    scroll-margin-top: var(--main-spacing-vertical);
  }
}
