.description-guide span {
  color: red !important;
}

.timer_green {
  color: green !important;
}

.timer_red {
  color: red !important;
}

.timer_red,
.timer_green,
.space-h3 h3 {
  font-weight: 800 !important;
}

.header-page {
  background-color: lightgray !important;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#eod .modal-content {
  right: 100px !important;
  width: 120% !important;
}

#dashboardmodal .modal-content {
  right: 135px !important;
  width: 135% !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.button-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.audio-controls,
.video-controls {
  margin-bottom: 20px;
}

.audio-player,
.video-player {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.audio-player,
.video-player,
.recorded-player {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.live-player {
  height: 200px;
  width: 400px;
  border: 1px solid #646cff;
  margin-bottom: 30px;
}

.recorded-player video {
  height: 400px;
  width: 800px;
}

/* h1 {
  font-family: "Roboto";
  text-align: center;
  margin-bottom: 40px;
} */

.timer-wrapper {
  display: flex;
  justify-content: center;
}

.time-wrapper {
  position: relative;
  width: 20px;
  height: 15px;
  font-size: 24px;
  font-family: "Montserrat";
}

.time-wrapper .time {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(0);
  opacity: 1;
  transition: all 0.2s;
}

.time-wrapper .time.up {
  opacity: 0;
  transform: translateY(-100%);
}

.time-wrapper .time.down {
  opacity: 0;
  transform: translateY(100%);
}

.forInputStyle {
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 8px;
}

/* Keerthy CSS */

.date-overflow div:first-child {
  overflow: unset !important;
}

.border-shadow {
  border: none !important;
  box-shadow: 0px 5px 5px 0 #e0e0e0;
  background-color: rgb(245, 249, 252);
}

.filter-card-div {
  box-shadow: 0px 5px 5px 0 #e0e0e0;
  padding: 20px;
  margin: 10px 0;
  background-color: white;
  border-radius: 8px;
}

.filter-card-div h5 {
  padding-bottom: 10px;
}

/* .filter-card-div h6{
  margin: 0 !important;
  font-size: 12px;
} */
.shedulepost-card {
  box-shadow: 0px 5px 5px 0 #e0e0e0;
  background-color: rgb(240, 245, 249);
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
}

/* .list-group {
  --bs-list-group-border-width: 0px !important;
  --bs-list-group-bg:rgb(255, 255, 255) !important;
} */
.shedulepost-card h5 {
  font-size: 16px;
  font-weight: bold;
}

.ul-card {
  position: relative;
  box-shadow: 0px 5px 5px 0 #e0e0e0;
  background-color: rgb(240 240 241);
  padding: 10px 16px 1px 16px;
  margin: 14px 10px 0 10px;
  border-radius: 8px;
}

.ul-card ul li {
  list-style: none;
  padding: 10px 0;
}

.ul-card ul {
  padding: 0;
}

.ul-card span {
  position: absolute;
  right: 3%;
}

.post-filter-card {
  box-shadow: 0px 5px 5px 0 #e0e0e0;
  background-color: rgb(240, 245, 249);
  margin: 10px 0;
  padding: 20px;
}

.post-filter-card p {
  font-size: 14px;
  margin: 0 !important;
  color: rgb(86, 83, 83);
}

.para-padding {
  padding-left: 8px;
}

/* .filterdiv-parent {
  padding-top: 20px;
} */

.MuiMenu-root .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background: white;
  border-radius: 8px;
  padding: 10px 0;
  overflow-y: scroll;
}

.connect-profile h6,
span {
  font-weight: bold;
}

.connect-profile h6 {
  color: rgb(13, 12, 12);
  font-size: 20px;
  padding: 10px 0;
}

.connect-profile span {
  color: rgb(107, 99, 99);
}

.connect-profile h1 {
  font-weight: bolder;
  color: rgb(232, 139, 72);
  padding-bottom: 10px;
}

.select-card {
  padding: 30px 0 20px 0;
}

.select-card .form-select {
  margin-top: 13px !important;
}

.key-list ul li {
  list-style: none;
  display: inline;
}

.key-list ul {
  margin: 0;
  padding: 0;
}

.key-list {
  box-shadow: 0px 5px 5px 0 #e0e0e0;
  background-color: rgb(240, 245, 249);
  padding: 40px 20px;
  border-radius: 8px;
}

.align-key {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.fb-icon {
  font-size: 40px;
  color: #1877f2;
}

.align-center {
  align-items: center;
  display: flex;
}

.add-btn button {
  background-color: rgb(58, 143, 58);
  margin-right: 20px;
  border: none;
}

.btn-card {
  display: flex;
  flex-wrap: wrap;
}

.remove-btn button {
  background-color: rgb(240, 115, 115);
  border: none;
}

.padding-card {
  padding-top: 35px;
}

.insta-i {
  width: 45%;
  height: auto;
}

.insta-align {
  display: flex;
  /* justify-content: center; */
}

.mar-left {
  margin-left: 12px;
}

.selectprofile-newpost .form-check-input {
  margin-right: 8px;
}

.facebook-align {
  visibility: hidden;
}

.select-profile-grp {
  padding-bottom: 10px;
}

.select-profile-grp .form-select {
  padding: 8px 5px;
}

.date-width {
  width: 100% !important;
}

.filled .form-control {
  padding-left: 20px !important;
}

.postimage {
  padding: 30px;
  box-shadow: 0px 5px 5px 0 #e0e0e0;
  /* background-color: rgb(240, 245, 249); */
  border-radius: 8px;
  border: solid 1px #b5abab5c;
  height: 92vh;
  overflow: scroll;
}

.imageattach-card {
  /* background-color: white;
  padding: 10px;
  border-radius: 8%; */
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.newpost-sec h2 {
  font-size: 34px;
  font-weight: 600;
  color: #f26c18;
}

.selectcard-newpost {
  padding: 20px 0;
}

.selectprofile-newpost {
  padding: 20px 0;
}

.newpost-cheklist ul {
  padding: 0;
}

.newpost-sec h6 {
  color: rgb(100, 160, 234);
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: bold;
}

.preview {
  box-shadow: 0px 5px 5px 0 #e0e0e0;
  /* background-color: rgb(240, 245, 249); */
  border: solid 1px #b5abab5c;
  padding: 30px;
  border-radius: 8px;
  overflow-y: scroll;
  height: 92vh;
}

.newpost-content {
  padding: 20px 0;
}

.link-iconstyle {
  color: #1877f2;
  padding-right: 6px;
}

.addimage-btn button {
  background-color: #1a1d3a;
  border: none;
  padding: 9px 8px;
}

.imageattach-card {
  margin-bottom: 18px;
  padding: 10px 5px;
}

.btn-iconstyle {
  color: white;
  padding-right: 6px;
}

.post-btn button {
  border: none;
  margin-right: 10px;
}

.blue-btn {
  background-color: #2b2872 !important;
}

.red-btn {
  background-color: #ee642b !important;
}

.post-img {
  width: 100% !important;
  margin: 20px 0;
}

.preview-img {
  width: 100%;
  height: auto;
  margin: 10px 0;
  border-radius: 4px;
}

.socialicons-preview {
  font-size: 20px;
}

.postimg-card {
  padding-bottom: 20px;
}

.selectprofile-newpost .form-check-input[type="checkbox"] {
  border-radius: 50%;
}

.tags-c {
  color: #1877f2;
  font-weight: 400;
}

.date-time {
  font-size: 14px;
  color: rgb(105, 98, 98);
  font-weight: 200;
}

.postimg-card h4 {
  margin: 0 !important;
}

.tag-qoute {
  padding: 10px 0;
}

.profilelogo-time {
  padding-top: 10px;
}

.tweet-i {
  width: 70%;
  height: auto;
}

.insta-align-center {
  display: flex;
  align-items: center;
}

.preview::-webkit-scrollbar {
  display: none;
}

.postimage::-webkit-scrollbar {
  display: none;
}

.selectprofile-newpost .form-check-input:checked {
  background-color: #17910d !important;
}

.img-insights ul li {
  display: inline;
  margin-right: 20px;
}

.img-insights ul {
  padding: 0;
  margin: 0;
}

/* .insta-card-container {
  padding-bottom: 30px;
} */

.instagram-post-card {
  box-shadow: 0px 5px 5px 0 #e0e0e0;
  /* background-color: rgb(240, 245, 249); */
  padding: 10px 20px;
  border-radius: 8px;
  border: solid 1px #b5abab5c;
}

.overflow-card {
  overflow-y: scroll;
  /* height: 45vh; */
}

.overflow-card::-webkit-scrollbar {
  display: none;
}

.post-page-img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 10px;
}

.postpage-card {
  box-shadow: 0px 5px 5px 0 #e0e0e0;
  padding: 10px 20px;
  border-radius: 8px;
  border: solid 1px #b5abab5c;
  background-color: white;
}

.post-page-row {
  box-shadow: 0px 5px 5px 0 #e0e0e0;
  background-color: rgb(240, 245, 249);
  padding: 30px;
  border-radius: 8px;
  margin: 20px 0;
}

.post-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0 0 0;
}

.posted-icon {
  width: 30%;
  height: auto;
  margin-right: 6px;
}

.icon-width button {
  font-size: 14px;
  padding: 0px 5px;
  border-radius: 3px;
}

.postpage-tag {
  padding-bottom: 14px;
}

.posted-btn {
  background-color: #17910d !important;
  border: none !important;
}

.fail-btn {
  background-color: rgb(193 14 14) !important;
  border: none !important;
}

.tag-qoute p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.5;
  margin: 0;
}
.scroll-x {
  overflow-x: scroll;
  flex-wrap: nowrap !important;
  scrollbar-width: thin;
}
.twitter-icon {
  width: 18%;
}
/* .scroll-x::-webkit-scrollbar{
  width: 10px;
} */
.btn-center {
  display: flex;
  align-items: center;
}
.post-page-row h4 {
  font-weight: 510;
  margin-bottom: 15px !important;
  color: #ee642b;
}
.instagram-i {
  width: 22%;
}
.wp-50 {
  width: 50%;
}
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito Sans", sans-serif !important;
  font-weight: 700 !important;
}
.profilelogo-time h4 {
  margin: 0;
}
.align-center span {
  display: flex;
  font-weight: 400;
}
.ii-gram {
  width: 80%;
}
.check-list-card ul li {
  display: inline;
}
.select-card .form-check-input {
  margin-right: 8px;
}
.leftsidebar {
  background-color: white;
  box-shadow: 0px 5px 5px 0 #e0e0e0;
  height: 100vh;
  position: absolute;
  right: 0;
}
/* Keerthy CSS */
/* Keerthy CSS */

.pointer-cursor {
  cursor: pointer;
}

.scroll-container {
  /* display: flex; */
  overflow-x: auto;
  /* padding: 20px; */
}

.scroll-wrapper {
  /* display: flex; */
  /* gap: 10px; */
}

.item {
  /* min-width: 150px;
  height: 150px; */
  /* background-color: lightgray; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* border: 1px solid #ddd; */
}
.iconscls{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D3FFE7;
  height: 70px;
  width: 70px;
}
.paradiv{
display: inline;
margin-left: 20px;
}
.paras{
  font-family: 'Poppins';
  font-size:28px;
  font-weight: 600;
  line-height: 28px;

}
.paras1{
  font-family: 'Poppins';
  font-size:14px;
  font-weight: 400;
  line-height: 18px;

}
.vi{
  border-left: 4px solid rgba(119, 136, 153, 0.164);
  height: 100px;
  position: absolute;
  left: 28%;
}
.vi2{
  border-left: 4px solid rgba(119, 136, 153, 0.164);
  height: 100px;
  position: absolute;
  left: 65%;
}
.styleform{
  padding: 10px;
}


.html[data-menu-animate=hidden] .nav-container .logo .img {
  display: none;
}