/*
*
* Tags
*
* Tags plugin styles.
*
*/
.react-tags {
  position: relative;
  border: 1px solid var(--separator);
  font-size: 1em;
  height: auto;
  min-height: var(--input-height);
  color: var(--body);
  box-shadow: initial !important;
  background-color: initial;
  border-color: var(--separator);
  border-radius: var(--border-radius-md);
  width: 100%;
  padding: 0.375rem 0 0 0.75rem;
  cursor: text;
}

.react-tags.is-focused {
  border-color: var(--primary);
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--separator) !important;
  background: initial;
  margin: 0 0.75rem 0.375rem 0;
  box-shadow: initial !important;
  line-height: 1.3;
  margin-left: -0.375rem;

  &:hover:after {
    color: var(--primary);
  }
}

.react-tags__selected-tag:after {
  content: "\2715";
  color: var(--muted);
  margin-left: 0.5rem;
  font-size: 0.75rem;
}

.react-tags__search {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 0.375rem;
  margin-right: 0.75rem;
}

.react-tags__search-input {
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: initial;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: var(--foreground);
  border: 1px solid var(--primary);
  border-radius: var(--border-radius-md);
  padding: 0.75rem;
}

.react-tags__suggestions li {
  padding: 0.5rem 0.75rem !important;
  line-height: 1.3;
  border-radius: var(--border-radius-sm);
  color: var(--body);
}

.react-tags__suggestions li mark {
  text-decoration: initial;
  background: initial;
  font-weight: initial;
  color: var(--body);
  padding: initial;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: var(--separator-light) !important;
  color: var(--primary) !important;

  mark {
    color: var(--primary) !important;
  }
}

.react-tags__suggestions li.is-active {
  color: var(--primary);
  cursor: pointer;
  background: var(--separator-light) !important;
  color: var(--primary) !important;
  mark {
    color: var(--primary) !important;
  }
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.top-label {
  .react-tags {
    min-height: 52px !important;
    padding-top: 1.5rem !important;
  }

  .react-tags__selected-tag {
    margin-left: initial;
  }

  .react-tags__selected-tag,
  .react-tags__search {
    margin-bottom: 0.15rem;
    margin-right: 0.25rem;
  }
}

.filled {
  .react-tags {
    min-height: 44px;
    border: 1px solid transparent;
    background: var(--background-light);
    padding-left: 45px;
    padding-top: 10px;
  }

  .react-tags.is-focused {
    border-color: var(--primary);
    background: var(--foreground);
  }
}

.form-floating {
  .react-tags {
    min-height: 52px !important;
    padding-top: 1.5rem !important;
    & label {
      -webkit-transform: initial;
      transform: initial;
      color: var(--alternate);
      transition: initial;
    }
  }

  .react-tags.is-focused, &.tag-added .react-tags {
    ~ label {
      -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
      transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
      color: var(--muted);
      transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
    }
  }
}
