.rtl .phone{
    margin-left: 0;
    margin-right: 8px;
}
.rtl .card-ads .ad-1 {
    padding: 50px 20px;
}
.rtl .card-ads .ad-2 {
    padding: 52px 20px;
}
.rtl .ad-2 .free {
    font-size: 25px;
    position: absolute;
    bottom: 20%;
    right: 24%;
}
.rtl .ad-2 button {
    text-align: center;
}
.rtl .loc{
    flex-direction: row-reverse;
  }  
.rtl .loc .form-control{
    border-radius: 7px 0 0 7px !important;
}
.rtl .loc .input-group-text{
    border-radius: 0 7px 7px 0 !important;
}