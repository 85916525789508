/*
*
* Editor
*
* Quill editor form control styles.
*
*/

.html-editor {
  display: flex;
  flex-direction: column;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: var(--separator) !important;
  background-color: var(--foreground) !important;
}

.ql-toolbar.ql-snow {
  border-color: var(--separator) !important;
}

.ql-container.ql-snow {
  border-color: var(--separator) !important;
}

.html-editor-bubble {
  border: 1px solid var(--separator) !important;
  border-radius: var(--border-radius-md) !important;

  &.active {
    border-color: rgba(var(--primary-rgb), 1) !important;
  }
}

.ql-tooltip {
  z-index: 1010 !important;
}

.ql-bubble .ql-tooltip {
  border-radius: var(--border-radius-md) !important;
  border: 1px solid var(--separator) !important;
  background-color: var(--foreground) !important;
  color: var(--body) !important;
}

.ql-bubble .ql-tooltip,
.ql-snow .ql-tooltip {
  color: var(--body) !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--primary) !important;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--primary) !important;
}

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: var(--primary) !important;
}

.ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
  border-bottom: 6px solid var(--separator) !important;

  &:after {
    position: absolute !important;
    display: block !important;
    content: "" !important;
    border-color: transparent !important;
    border-style: solid !important;
    border-bottom-color: var(--foreground) !important;
    border-width: 0 5px 5px 5px !important;
    width: 5px !important;
    height: 5px !important;
    left: -5px !important;
    top: 1px !important;
  }
}

.ql-editor {
  padding: 0.5rem 0.75rem 0.5rem 0.75rem !important;
  font-size: 1em !important;
  line-height: 1.5 !important;
}

.ql-bubble .ql-picker-options {
  background-color: var(--foreground) !important;
  border: 1px solid var(--separator) !important;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow,
.ql-bubble .ql-editor {
  border-radius: var(--border-radius-md) !important;
}

.ql-toolbar.ql-snow {
  border-bottom-left-radius: initial !important;
  border-bottom-right-radius: initial !important;
}

.ql-container.ql-snow {
  border-top-left-radius: initial !important;
  border-top-right-radius: initial !important;
}

.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid transparent !important;
  outline: initial !important;
  box-shadow: initial !important;
}

.ql-editor.ql-blank::before {
  padding: initial !important;
  font-style: initial !important;
  color: var(--alternate) !important;
  left: initial !important;
  right: initial !important;
}

.ql-container.active {
  .ql-editor {
    border-color: rgba(var(--primary-rgb), 1) !important;
  }
}

.editor-container.active {
  .ql-toolbar {
    border-color: rgba(var(--primary-rgb), 1) !important;
    border-bottom: 1px solid var(--separator) !important;
  }

  .html-editor {
    border-color: rgba(var(--primary-rgb), 1) !important;
  }
}

.filled.custom-control-container.editor-container {
  padding-top: initial !important;
  padding-bottom: initial !important;
  padding-right: initial !important;

  .ql-editor {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    padding-left: initial !important;
    padding-right: initial !important;
  }

  & > i {
    margin-top: 0 !important;
    top: 14px !important;
  }

  .ql-editor.ql-blank::before {
    font-family: var(--font) !important;
    color: var(--muted) !important;
    top: 0.75rem !important;
    font-size: 1em !important;
  }

  &.active {
    border-color: rgba(var(--primary-rgb), 1) !important;
    background: initial !important;
  }
}

.filled.custom-control-container .ql-editor {
  padding-right: 0.75rem !important;
}

.top-label.custom-control-container.editor-container {
  padding: initial !important;

  .ql-editor {
    padding: 0 0.75rem 0.5rem 0.75rem !important;
    color: var(--body) !important;
    margin-top: 1.65rem !important;
  }

  .ql-editor.ql-blank::before {
    font-family: var(--font) !important;
    color: var(--muted) !important;
    top: 1.65rem !important;
    font-size: 1em !important;
  }

  &.active {
    border-color: rgba(var(--primary-rgb), 1) !important;
  }
}

.form-floating {
  .html-editor {
    border: 1px solid var(--separator) !important;
    border-radius: var(--border-radius-md) !important;

    .ql-editor {
      padding-top: 1.65rem !important;
    }

    &.active {
      border-color: rgba(var(--primary-rgb), 1) !important;
    }

    ~ label {
      transition: transform 0.1s ease-in-out, padding 0.1s ease-in-out, background-color 0s ease-in-out,
        height 0.1s ease-in-out !important;
    }

    &.active ~ label,
    &.full ~ label {
      color: var(--muted) !important;
      background: var(--foreground) !important;
      padding-top: 0.25rem !important;
      padding-bottom: 0.05rem !important;
      border-top-left-radius: var(--border-radius-md) !important;
      height: auto !important;
      -webkit-transform: scale(0.85) translateY(1px) translateX(0.15rem) !important;
      transform: scale(0.85) translateY(1px) translateX(0.15rem) !important;
      transition: transform 0.1s ease-in-out, padding 0.1s ease-in-out, background-color 0.1s ease-in-out 0.1s,
        height 0.1s ease-in-out !important;
    }
  }
}

.editor-container.active .ql-bubble .ql-tooltip .ql-toolbar,
.editor-container .ql-bubble .ql-tooltip .ql-toolbar {
  border-bottom: initial !important;
}

.ql-bubble .ql-stroke,
.ql-snow .ql-stroke {
  stroke: var(--body) !important;
}

.ql-bubble .ql-fill,
.ql-bubble .ql-stroke.ql-fill,
.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: var(--body) !important;
}

.ql-bubble.ql-toolbar button:hover,
.ql-bubble .ql-toolbar button:hover,
.ql-bubble.ql-toolbar button:focus,
.ql-bubble .ql-toolbar button:focus,
.ql-bubble.ql-toolbar button.ql-active,
.ql-bubble .ql-toolbar button.ql-active,
.ql-bubble.ql-toolbar .ql-picker-label:hover,
.ql-bubble .ql-toolbar .ql-picker-label:hover,
.ql-bubble.ql-toolbar .ql-picker-label.ql-active,
.ql-bubble .ql-toolbar .ql-picker-label.ql-active,
.ql-bubble.ql-toolbar .ql-picker-item:hover,
.ql-bubble .ql-toolbar .ql-picker-item:hover,
.ql-bubble.ql-toolbar .ql-picker-item.ql-selected,
.ql-bubble .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--primary) !important;
}

.ql-bubble.ql-toolbar button:hover .ql-stroke,
.ql-bubble .ql-toolbar button:hover .ql-stroke,
.ql-bubble.ql-toolbar button:focus .ql-stroke,
.ql-bubble .ql-toolbar button:focus .ql-stroke,
.ql-bubble.ql-toolbar button.ql-active .ql-stroke,
.ql-bubble .ql-toolbar button.ql-active .ql-stroke,
.ql-bubble.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-bubble.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-bubble.ql-toolbar button:hover .ql-stroke-miter,
.ql-bubble .ql-toolbar button:hover .ql-stroke-miter,
.ql-bubble.ql-toolbar button:focus .ql-stroke-miter,
.ql-bubble .ql-toolbar button:focus .ql-stroke-miter,
.ql-bubble.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-bubble .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-bubble.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-bubble .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-bubble.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-bubble .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--primary) !important;
}

.ql-bubble.ql-toolbar button:hover .ql-fill,
.ql-bubble .ql-toolbar button:hover .ql-fill,
.ql-bubble.ql-toolbar button:focus .ql-fill,
.ql-bubble .ql-toolbar button:focus .ql-fill,
.ql-bubble.ql-toolbar button.ql-active .ql-fill,
.ql-bubble .ql-toolbar button.ql-active .ql-fill,
.ql-bubble.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-bubble .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-bubble.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-bubble .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-bubble.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-bubble.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-bubble.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-bubble.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-bubble.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: var(--primary) !important;
}

.ql-bubble .ql-picker,
.ql-snow .ql-picker {
  color: var(--body) !important;
}

.ql-bubble .ql-toolbar .ql-formats,
.ql-bubble .ql-picker-label,
.ql-bubble .ql-picker {
  outline: initial !important;
}

.ql-bubble .ql-picker.ql-expanded .ql-picker-options {
  border-radius: var(--border-radius-md) !important;
  padding: 0.5rem 1.5rem !important;
}

.ql-bubble .ql-color-picker .ql-picker-options {
  width: 145px !important;
}

.ql-bubble .ql-color-picker .ql-picker-item {
  border-radius: var(--border-radius-sm) !important;
}

.ql-bubble .ql-editor,
.ql-snow .ql-editor {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: var(--font-heading) !important;
    color: var(--body) !important;
    font-weight: 400 !important;
    line-height: 1.25 !important;
    margin-bottom: 0.75rem !important;
  }

  h1,
  .h1 {
    font-size: 1.8em !important;
    @include respond-below(sm) {
      font-size: 1.5em !important;
    }
  }

  h2,
  .h2 {
    font-size: 1.65em !important;
    @include respond-below(sm) {
      font-size: 1.3em !important;
    }
  }

  h3,
  .h3 {
    font-size: 1.5em !important;
    @include respond-below(sm) {
      font-size: 1.25em !important;
    }
  }

  h4,
  .h4 {
    font-size: 1.35em !important;
    @include respond-below(sm) {
      font-size: 1.15em !important;
    }
  }

  h5,
  .h5 {
    font-size: 1.1em !important;
    @include respond-below(sm) {
      font-size: 1em !important;
    }
  }

  h6,
  .h6 {
    font-size: 1em !important;
    @include respond-below(sm) {
      font-size: 1em !important;
    }
  }
}
