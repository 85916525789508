:root {
    --orange-color: #F05A28;
    --blue-color: #1C1D39;
    --white-color: #fff;
}

.container {
    max-width: 1440px;
}

.d-flex {
    display: flex;
    flex-wrap: wrap;
}

.search_section {
    background: var(--orange-color);
    padding: 20px 0px;
    .search_area {
        text-align: center;
        h1 {
            margin: 0;
            color: var(--white-color);
            font-weight: 700;
        }
        .search_form {
            width: 60%;
            margin: 30px auto 0 auto;
            .input-group {
                height: 50px;
            }
        }
    }
}

.categories_section {
    padding: 40px 0px;
    .category {
        .list {
            width: 20%;
            text-align: center;
            margin: 0px 0px 30px 0;
            .cat_img {
                background: #f9f9f9;
                width: 60px;
                height: 60px;
                border-radius: 50px;
                margin: 0 auto;
                img {
                    width: 40px;
                    height: 40px;
                    margin: 8px auto;
                }
            }
            .cat_info {
                h3 {
                    font-size: 16px;
                    text-align: center;
                    margin: 10px auto 0 auto;
                }
            }
        }
    }
    .banner_area {
        .banners_bottom {
            margin-top: 15px;
        }
    }
}