/*
*
* Positions
*
* Top, bottom, left and right position utils.
*
*/

$positions: (
  '0': 0,
  '1': 0.25rem,
  '2': 0.5rem,
  '3': 1rem,
  '4': 1.5rem,
  '5': 2rem,
);

@each $name, $position in $positions {
  .t-#{$name} {
    top: $position !important;
    z-index: 1;
  }
  .b-#{$name} {
    bottom: $position !important;
    z-index: 1;
  }
  .s-#{$name} {
    left: $position !important;
    z-index: 1;
  }
  .e-#{$name} {
    right: $position !important;
    z-index: 1;
  }

  .t-n#{$name} {
    top: -$position !important;
    z-index: 1;
  }
  .b-n#{$name} {
    bottom: -$position !important;
    z-index: 1;
  }
  .s-n#{$name} {
    left: -$position !important;
    z-index: 1;
  }
  .e-n#{$name} {
    right: -$position !important;
    z-index: 1;
  }
}
