/*
*
* Grid
*
* Bootstrap auto column fix.
*
*/

.col,
.col-sm,
.col-md,
.col-lg,
.col-xl {
  min-width: 0;
}
