/*
*
* Input Spinner
*
* Input spinner form control styles.
*
*/

.input-group.spinner {
    z-index: 0;
}
.input-group.spinner .input-group-text {
    flex-direction: column;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
    padding: 0;
}
.input-group.spinner .input-group-text .spin-up,
.input-group.spinner .input-group-text .spin-down {
    display: flex;
    background: none;
    border: none;
    padding: 0;
    height: 40%;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    &.single {
        height: 100%;
        color: var(--alternate);
    }

    &:hover .arrow {
        border-color: var(--primary);
    }

    &:hover {
        color: var(--primary);
    }

    .arrow {
        border: initial;
        border-top: 1px solid var(--alternate);
        border-right: 1px solid var(--alternate);
        width: 5px;
        height: 5px;
        margin: 0 auto;
    }
}

.input-group.spinner .input-group-text .spin-up .arrow {
    transform: rotate(-45deg);
}

.input-group.spinner .input-group-text .spin-down .arrow {
    transform: rotate(135deg);
}

.filled {
    .input-group-text {
        border: initial;
    }
}
