/*
*
* Dropzone
*
* Dropzone form control styles.
*
*/

.dzu-dropzone {
  overflow: initial !important;
  min-height: 112px !important;
  border: 1px solid var(--separator) !important;
  background: var(--foreground) !important;
  padding: var(--card-spacing-sm) !important;
  border-radius: var(--border-radius-md) !important;
  align-items: flex-start !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  padding-bottom: initial !important;

  &.dzu-dropzoneActive {
    border-color: var(--primary) !important;
  }
}

.dzu-inputLabel {
  color: var(--body) !important;
  font-size: 1em !important;
  font-weight: initial !important;
  font-family: var(--font) !important;
}

.dzu-previewContainer {
  progress {
    -webkit-appearance: none;
    appearance: none;
    height: 3px;
  }
  progress::-webkit-progress-value {
    background: var(--primary);
  }
}

.dzu-preview-file-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dzu-preview-success,
.dzu-preview-error,
.dzu-preview-spinner {
  position: absolute;
  background: var(--foreground);
  color: var(--primary);
  border-radius: var(--border-radius-xl);
  width: 20px;
  height: 20px;
  text-align: center;
  top: 3px;
  left: 3px;
  padding-top: 1px;
  i {
    font-size: 14px;
  }
}

.dzu-preview-spinner {
  padding-left: 2px;
}

.dzu-preview-icon {
  font-size: 24px !important;
  color: var(--primary);
}

.dzu-inputLabel {
  width: 100%;
}

.dzu-dropzone.row {
  border: initial !important;
  padding: initial !important;
  min-height: 226px;
  .dzu-inputLabel { 
    padding-top: 4px !important;
  }
}

.filled {
  .dzu-dropzone {
    border-color: var(--background-light) !important;
    background: var(--background-light) !important;
    padding-left: 45px !important;

    &.dzu-dropzoneActive {
      border-color: var(--primary) !important;
    }
  }

  .dzu-inputLabel {
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 45px !important;
    padding-top: 12px !important;
    color: var(--alternate) !important;
  }
}

.top-label {
  .dzu-dropzone {
    padding: 2rem 0.5rem 0 1rem !important;
    min-height: 124px !important;
  }
  .dzu-inputLabel {
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    font-size: 1em !important;
    z-index: initial !important;
  }
}

.top-label label:not(.form-check-label).dzu-inputLabel {
  font-size: 1em !important;
  background: initial !important;
  cursor: pointer !important;
}

.form-floating {
  .dzu-dropzone {
    padding: 1rem !important;
    padding-bottom: initial !important;
    min-height: 124px !important;
  }

  .dzu-inputLabel {
    pointer-events: initial !important;
  }

  .dzu-dropzone.full {
    padding-top: 2rem !important;
    & ~ label {
      transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
      color: var(--muted);
    }
  }
}
