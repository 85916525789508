.rtl .search_area h1{
    text-align: right;
}
.rtl .input-group{
    direction: ltr;
}
.rtl .footer-sec ul li button{
    margin-right:16px;
}
.rtl .carousel-content{
    left:-10px;
}
.rtl input,.rtl select{
    direction: rtl;
}
.rtl .navbar-list{
    margin: 0 auto auto 0;
}
.rtl .book-icon-1{
    left: 45%;
}