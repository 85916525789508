/*
*
* Validation
*
* Validation plugin styles.
*
*/

.invalid-tooltip,
.valid-tooltip,
div.error {
  border-radius: var(--border-radius-md);
  font-size: 0.875em;
  color: var(--light-text);
  background: var(--primary);
  border: initial;
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 4;
  margin-top: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  line-height: 1.5;
  padding: calc(var(--card-spacing-xs) / 3 * 2) var(--card-spacing-xs);
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    top: -4px;
    left: -2.5px;
    margin-left: 50%;
    width: 10px;
    height: 5px;
    border-bottom: solid 5px var(--primary);
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
  }
}

.tooltip-end-bottom .invalid-tooltip,
.tooltip-end-bottom .valid-tooltip,
.tooltip-end-bottom div.error {
  left: initial;
  right: 0;
  transform: translateX(0);
  top: calc(100% + 0.5rem);
  &::before {
    left: initial;
    margin-top: initial;
    right: 25px;
    margin-left: 0;
    top: -4px;
  }
}

.tooltip-center-bottom .invalid-tooltip,
.tooltip-center-bottom .valid-tooltip,
.tooltip-center-bottom div.error {
  left: 50%;
  right: initial;
  transform: translateX(-50%) translateY(0);
  top: calc(100% + 0.5rem);
  &::before {
    margin-top: initial;
    top: -4px;
  }
}

.tooltip-start-bottom .invalid-tooltip,
.tooltip-start-bottom .valid-tooltip,
.tooltip-start-bottom div.error {
  left: 0;
  right: initial;
  transform: translateX(0);
  top: calc(100% + 0.5rem);
  &::before {
    margin-top: initial;
    left: 25px;
    right: initial;
    margin-left: 0;
    top: -4px;
  }
}

.tooltip-center-top .invalid-tooltip,
.tooltip-center-top .valid-tooltip,
.tooltip-center-top div.error {
  bottom: initial;
  transform: translateX(-50%) translateY(50%);
  top: -0.75rem;

  &::before {
    margin-top: initial;
    content: "";
    position: absolute;
    top: initial;
    bottom: -4px;
    border-top: solid 5px var(--primary);
    border-bottom: initial;
  }
}

.tooltip-end-top .invalid-tooltip,
.tooltip-end-top .valid-tooltip,
.tooltip-end-top div.error {
  bottom: initial;
  top: -0.75rem;
  transform: translateX(0) translateY(50%);
  left: initial;
  right: 0;

  &::before {
    content: "";
    margin-top: initial;
    position: absolute;
    top: initial;
    bottom: -4px;
    border-top: solid 5px var(--primary);
    border-bottom: initial;
    left: initial;
    right: 25px;
    margin-left: 0;
  }
}

.tooltip-start-top .invalid-tooltip,
.tooltip-start-top .valid-tooltip,
.tooltip-start-top div.error {
  bottom: initial;
  transform: translateX(0) translateY(50%);
  top: -0.75rem;
  left: 0;
  right: initial;

  &::before {
    content: "";
    margin-top: initial;
    position: absolute;
    top: initial;
    bottom: -4px;
    border-top: solid 5px var(--primary);
    border-bottom: initial;
    left: 25px;
    right: initial;
    margin-left: 0;
  }
}

.tooltip-label-end .invalid-tooltip,
.tooltip-label-end .valid-tooltip,
.tooltip-label-end div.error {
  transform: translateX(0) translateY(-50%);
  top: 18px;
  bottom: initial;
  right: initial;
  &::before {
    content: "";
    position: absolute;
    left: -5px;
    right: initial;
    margin-left: 0;
    border: initial;
    border-top: solid 5px transparent;
    border-bottom: solid 5px transparent;
    border-right: solid 5px var(--primary);
    bottom: initial;
    top: 50%;
    margin-top: -5px;
    width: 5px;
  }
}

.is-invalid {
  .invalid-tooltip {
    display: block;
  }
}

.valid-icon {
  position: absolute;
  bottom: 8px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.76rem;
  line-height: 1;
  color: var(--foreground);
  border-radius: 0.2rem;
  right: 4px;
  color: var(--success);
}

.invalid-icon {
  position: absolute;
  bottom: 2px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.875em;
  line-height: 1;
  color: var(--foreground);
  border-radius: 0.2rem;
  right: 4px;
  color: var(danger);
}

// .was-validated .form-control:invalid,
// .form-control.is-invalid,
// .was-validated .custom-select:invalid,
// .custom-select.is-invalid {
//   border-color: var(--separator) !important;
// }

// .was-validated .form-control:valid,
// .form-control.is-valid,
// .was-validated .custom-select:valid,
// .custom-select.is-valid {
//   border-color: var(--separator) !important;
// }

// .was-validated .form-control:valid:focus,
// .form-control.is-valid:focus,
// .was-validated .custom-select:valid:focus,
// .custom-select.is-valid:focus {
//   border-color: rgba(var(--primary), 0.6);
// }

// .was-validated .form-control:invalid:focus,
// .form-control.is-invalid:focus,
// .was-validated .custom-select:invalid:focus,
// .custom-select.is-invalid:focus {
//   border-color: rgba(var(--primary), 0.6);
// }

// .was-validated .form-control:invalid,
// .form-control.is-invalid,
// .was-validated .form-control:valid,
// .form-control.is-valid,
// .was-validated .custom-select:invalid,
// .custom-select.is-invalid,
// .was-validated .custom-select:valid,
// .custom-select.is-valid {
//   background-image: initial;
// }

.tooltip-end-top,
.tooltip-center-top,
.tooltip-start-top {
  .filled {
    .invalid-tooltip,
    .valid-tooltip,
    div.error {
      top: -35px;
      bottom: initial;
    }
  }

  &.filled {
    .invalid-tooltip,
    .valid-tooltip,
    div.error {
      top: -35px;
      bottom: initial;
    }
  }
}

.tooltip-end-top,
.tooltip-center-top,
.tooltip-start-top {
  .top-label,
  .form-floating {
    .invalid-tooltip,
    .valid-tooltip,
    div.error {
      top: -35px;
      bottom: initial;
    }
  }

  &.top-label,
  &.form-floating {
    .invalid-tooltip,
    .valid-tooltip,
    div.error {
      top: -35px;
      bottom: initial;
    }
  }
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: var(--body);
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: var(--primary);
}

.valid-tooltip {
  display: none !important;
}

.form-check.tooltip-end-top .invalid-tooltip,
.form-check.tooltip-end-top .valid-tooltip,
.form-check.tooltip-end-top div.error,
.form-check.tooltip-center-top .invalid-tooltip,
.form-check.tooltip-center-top .valid-tooltip,
.form-check.tooltip-center-top div.error,
.form-check.tooltip-start-top .invalid-tooltip,
.form-check.tooltip-start-top .valid-tooltip,
.form-check.tooltip-start-top div.error {
  top: -2.5rem;
}
